import React from 'react';
import { bg_checked, checked, dot } from '../../../../../components/imgExport';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { Radio, type RadioChangeEvent, } from 'antd';
import './style.css'
interface DayTimeProps {
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
    dateFilterSoccer?: string
    keyGameTab?: "gameOnline" | "gameOffline"
    totalGame?: number
    totalGameOnLine?: number
    totalAllGame?: number
    changeDateFilter?: (date: string) => void
    onRadioChange?: (value: "gameOnline" | "gameOffline") => void
}
const DayTime_V2: React.FC<DayTimeProps> = ({
    listDateFilterSoccer, dateFilterSoccer, keyGameTab, totalGame, totalGameOnLine, totalAllGame,
    changeDateFilter, onRadioChange
}) => {
    const onChange = (e: RadioChangeEvent) => {
        onRadioChange?.(e.target.value);
    };
    return (
        <div className='flex justify-between w-full gap-2 xl:py-3 xs:pt-3  xs:overflow-x-scroll xl:overflow-hidden'>
            <Radio.Group className={`${totalGameOnLine && totalGameOnLine > 0 ? "" : "!hidden"} xs:w-[80px] flex-shrink-0 xl:w-[11.5%] !flex !gap-2 items-center !mb-0`} value={keyGameTab} onChange={onChange} style={{ marginBottom: 16 }}>
                <Radio.Button
                    className={`!rounded-[6px] !bg-[#131620] !flex !flex-col xs:w-[80px]  xs:h-[50px] xl:w-full !h-[50px] !items-center !border !justify-center !relative custom-radio-btn  hover:border-[#D4B962] ${keyGameTab === "gameOnline" ? "!border-[#D4B962]" : "!border-[#202531]"}
                    `}
                    value="gameOnline"
                >
                    <div className='flex items-center gap-1'>
                        <img src={dot} alt="" />
                        <span className='xs:text-sm xl:text-base uppercase  text-[#E4E7F1] font-bold-GT-America'>live</span>
                    </div>
                    <span className='text-[#D4B962] xs:text-[10px] lg:text-[10px] xl:text-[10px] xl2:text-xs xl3:text-xs fw-[400] whitespace-nowrap'>{totalGameOnLine ? totalGameOnLine : 0} Match</span>
                    <div className={`${keyGameTab === "gameOnline" ? "block" : "hidden"}`}>
                        <img src={bg_checked} alt="" className='absolute top-0 right-0' />
                        <img src={checked} alt="" className='absolute top-[6px] right-1' />
                    </div>
                </Radio.Button>
            </Radio.Group>

            <div
                onClick={() => changeDateFilter?.("All")}
                key={"All"}
                className={`rounded-[6px] bg-[#131620] flex flex-col gap-1 xs:w-[80px] flex-shrink-0 xl:w-[11.5%] h-[50px] items-center justify-center !relative cursor-pointer border hover:border-[#D4B962] ${dateFilterSoccer && dateFilterSoccer === "All" && keyGameTab !== 'gameOnline' ? "border border-[#D4B962]" : "border border-[#202531]"}`}
            >
                <span className='xs:text-sm lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base uppercase  text-[#E4E7F1] font-bold-GT-America whitespace-nowrap'>
                    All game
                </span>
                <span className='text-[#D4B962] xs:text-[10px] lg:text-[10px] xl:text-[10px] xl2:text-xs xl3:text-xs fw-[400] whitespace-nowrap'>{totalAllGame ? totalAllGame : 0} Match</span>
                <div className={`${dateFilterSoccer && dateFilterSoccer === "All" && keyGameTab !== 'gameOnline' ? "block" : "hidden"}`}>
                    <img src={bg_checked} alt="" className='absolute top-0 right-0' />
                    <img src={checked} alt="" className='absolute top-[6px] right-1' />
                </div>
            </div>
            {
                listDateFilterSoccer && listDateFilterSoccer.map((item, index) => (
                    <div
                        onClick={() => changeDateFilter?.(item.value || "")}
                        key={item.value}
                        className={`rounded-[6px] bg-[#131620] flex flex-col gap-1 xs:w-[80px] flex-shrink-0 xl:w-[11.5%] h-[50px] items-center justify-center !relative cursor-pointer border hover:border-[#D4B962] ${dateFilterSoccer && item.value && dateFilterSoccer.slice(0, 10) === item.value.slice(0, 10) && keyGameTab !== 'gameOnline' ? "border border-[#D4B962]" : "border border-[#202531]"}`}
                    >
                        <span className='uppercase xs:text-sm lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base text-[#E4E7F1] font-bold-GT-America whitespace-nowrap'>
                            {index === 0 ? "today" : item.date}
                        </span>
                        <span className='text-[#D4B962] xs:text-[10px] lg:text-[10px] xl:text-[10px] xl2:text-xs xl3:text-xs fw-[400] whitespace-nowrap'>{item.totalMatch ? item.totalMatch : 0} Match</span>
                        <div className={`${dateFilterSoccer && item.value && dateFilterSoccer.slice(0, 10) === item.value.slice(0, 10) && keyGameTab !== 'gameOnline' ? "block" : "hidden"}`}>
                            <img src={bg_checked} alt="" className='absolute top-0 right-0' />
                            <img src={checked} alt="" className='absolute top-[6px] right-1' />
                        </div>
                    </div>
                ))
            }

        </div>
    );
};

export default DayTime_V2;
