/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import { Drawer, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import BetSlipTab from '../../BetSlipTab/BetSlipTab';
import { InfoBetTeaserTMP, InfoParlayBetsSlipTMP, InfoStrightBetsSlipTMP } from '../../../../../api/betting/bettingApi';
import './styles.css'
import { Dollar, Trash, start } from '../../../../../components/imgExport';
import HeadRight from '../../MainNavi/Head/HeadRight';
import EmtyBetSlip from '../../../../../components/EmtyBetSlip';
import TotalStakeComponent from '../../TotalStake/TotalStake';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import { infoBettings } from '../../../../../api/homePage/homePageApi';
import useToast from '../../../../../hook/useToast';
import ItemStraight from './Component/ItemStraight';
import IconSlider from './Component/Teaser';
import ItemTeaser from './Component/ItemTeaser';
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ItemParlay from './Component/ItemParlay';
interface ModalBetSlip_V2Props {
    openDrawer: boolean
    closeDrawer: (value: boolean) => void

    tab?: "homepage" | "MLB" | "NBA" | "NFL" | "NCAAF"
    menu?: any
    keyGameTab?: "gameOnline" | "gameOffline"
    keyBetTabs: string
    totalStake: number
    pointTeaser?: number
    listAddStrightBetsSlip?: InfoStrightBetsSlipTMP[]
    listParlayBetsSlip?: InfoParlayBetsSlipTMP[]
    listTeaserBetsSlip?: InfoBetTeaserTMP[]
    listChooseBet?: bettingApi.ChooseBet[]

    setKeyBetTabs: (value: string) => void
    rStrightBetsSlipByIndex: (value: number) => void
    clearAll: () => void;
    setListaddStrightBetsSlip: (value: InfoStrightBetsSlipTMP[]) => void
    setListParlayBetsSlip?: (value: InfoParlayBetsSlipTMP[]) => void
    setListTeaserBetsSlip?: (value: InfoBetTeaserTMP[]) => void
    setTotalStake: (index: number) => void
    setPointTeaser?: (value: number) => void


    chooseElement: string
    arrayActiveItem: string[]
    setArrayActiveItem: (id: string[]) => void
    decimal?: string
    betting?: infoBettings

    numberStake: number,

    textWarningBetParlay?: string
    isWarningBetParlay?: boolean
    isWarningBetTeaser?: boolean
    showTotalStake: boolean,
    showTotalStake_V2: boolean,
    showTotalBet: boolean,
    totalOddsParlay?: number
    totalTParlay?: number
    totalAmountParlay?: number
    totalOddsTeaser?: number
    totalAmountTeaser?: number
    handleClick: (total: number) => void
    handleNumberClick: (total: number) => void
    showHideTotalStake: () => void,
    showHideTotalStake_V2: () => void,
    handleDeleteClick: (index: number) => void
    handleCheckBet: () => void
    handleCheckBetParlay?: () => void
    handleCheckBetTeaser?: () => void
    handleCheckQuantityMoneyStraight?: (keyBetTabs: number, total: number) => void
    handleChangeInput?: (total: number) => void
    hiddenBetPlace?: boolean,
    minInputStake?: () => void,
    maxInputStake?: () => void,
    minBet?: number
    maxBet?: number
}
const ModalBetSlip_V2: React.FC<ModalBetSlip_V2Props> = ({
    tab, menu, keyGameTab, keyBetTabs, totalStake, pointTeaser,
    listAddStrightBetsSlip, listParlayBetsSlip, listTeaserBetsSlip, listChooseBet, openDrawer,
    showHideTotalStake_V2,
    setKeyBetTabs, rStrightBetsSlipByIndex, clearAll, setListaddStrightBetsSlip, setListParlayBetsSlip, setListTeaserBetsSlip, setTotalStake, setPointTeaser, closeDrawer,
    chooseElement,
    arrayActiveItem,
    setArrayActiveItem,
    decimal,
    betting,
    numberStake, textWarningBetParlay, isWarningBetParlay, isWarningBetTeaser,
    showTotalBet, showTotalStake, showTotalStake_V2,
    totalOddsParlay, totalTParlay, totalAmountParlay, totalAmountTeaser, totalOddsTeaser,
    handleClick, showHideTotalStake, handleNumberClick, handleDeleteClick, handleChangeInput, handleCheckBet, handleCheckBetParlay, handleCheckBetTeaser, handleCheckQuantityMoneyStraight,
    hiddenBetPlace,
    minInputStake,
    maxInputStake,
    minBet,
    maxBet
}) => {

    const pushToast = useToast();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [settingClicked, setSettingClicked] = useState(false);
    const settingbet = menu?.map((item: any) => item.key)

    const items: TabsProps['items'] = keyGameTab === "gameOffline" ?
        ([
            {
                key: '1',
                label: settingbet.includes('1') ? 'Straight' : '',
                children:
                    <div className={`betTabs text-base pl-2 flex flex-col gap-2 ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                        {
                            listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
                                <ItemStraight
                                    tab={tab}
                                    key={i}
                                    index={i}
                                    item={item}
                                    listAddStrightBetsSlip={listAddStrightBetsSlip}
                                    rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                                    setListaddStrightBetsSlip={setListaddStrightBetsSlip}
                                    setTotalStake={setTotalStake}
                                    decimal={decimal}
                                />
                            ))
                        }

                        {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
            <Checkbox
              checked={isAllChecked}
              onChange={(e) => handleCheckAll(e.target.checked)}
            ></Checkbox>
            <div className='flex flex-row-reverse'>
              <Button
                className='!flex items-center pl-2 pr-1'
                type="text"
                onClick={clearAll}
              >
                <DeleteOutlined
                  className='text-[#898989]'
                />
                <span className='text-black font-[500]'
                >Clear All
                </span>
              </Button>
            </div>
          </div> */}
                        <BetSlipTab showTabs={false} />
                    </div>,
            },
            {
                disabled: !settingbet.includes('2'),
                key: '2',
                label: settingbet.includes('2') ? 'Parlay' : '',
                children: <div className={`betTabs text-base pl-2 flex flex-col gap-2 ? "hidden" : ""} ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                    {
                        listParlayBetsSlip && listParlayBetsSlip.map((item, i) => (
                            <ItemParlay
                                tab={tab}
                                key={i}
                                index={i}
                                item={item}
                                listParlayBetsSlip={listParlayBetsSlip}
                                rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                                setListParlayBetsSlip={setListParlayBetsSlip}
                                decimal={decimal}
                            // setTotalStake={setTotalStake}

                            />
                        ))}

                    {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
          <Checkbox
            checked={isAllCheckedParlay}
            onChange={(e) => handleCheckAllParlay(e.target.checked)}
          ></Checkbox>
          <div className='flex flex-row-reverse'>
            <Button
              className='!flex items-center pl-2 pr-1'
              type="text"
              onClick={clearAll}
            >
              <DeleteOutlined
                className='text-[#898989]'
              />
              <span className='text-black font-[500]'
              >Clear All
              </span>
            </Button>
          </div>
        </div> */}
                    <BetSlipTab showTabs={false} />
                </div>,
            },
            {
                disabled: !settingbet.includes('3'),
                key: settingbet.includes('3') ? '3' : '',
                label: settingbet.includes('3') ? 'Teaser' : '',
                children: <div className={`betTabs text-base ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                    <div className='flex flex-col gap-2'>
                        <div className='flex gap-1'>
                            {/* <span className='text-[#D5D5D5]'>Speard</span> */}
                            <span className='font-bold-GT-America'>
                                {pointTeaser} - Points</span>
                            <ExclamationCircleOutlined />
                        </div>
                        <div className='pb-2'>
                            <IconSlider
                                min={4}
                                max={7}
                                pointTeaser={pointTeaser}
                                setPointTeaser={setPointTeaser}
                            />
                        </div>
                        <div className='flex'>

                        </div>
                    </div>
                    {
                        listTeaserBetsSlip && listTeaserBetsSlip.map((item, i) => (
                            <ItemTeaser
                                key={i}
                                index={i}
                                item={item}
                                listTeaserBetsSlip={listTeaserBetsSlip}
                                setListTeaserBetsSlip={setListTeaserBetsSlip}
                                rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                            />
                        ))
                    }


                    {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
          <div>
            <Checkbox
              checked={isAllCheckedTeaser}
              onChange={(e) => handleCheckAllTeaser(e.target.checked)}
            ></Checkbox>
          </div>
          <div className='flex'>
            <Button className='!flex items-center pl-2' type="text" onClick={clearAll}><DeleteOutlined className='text-[#898989]' /> <span className='text-black font-[500]'>Clear All</span></Button>
          </div>
        </div> */}
                    <BetSlipTab showTabs={false} />
                </div>,
            },
            // {
            //   key: '4',
            //   label: 'If Bet',
            //   children: <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
            //     {arrayActiveItem.map((item, index) => (
            //       <div
            //         key={index}
            //       >
            //         <div className='flex justify-between pb-4 pt-4' key={`IfBet-${item}`}>
            //           <div>
            //             <Checkbox ></Checkbox>
            //             <span className='font-[500] text-black pl-2'>Brooklyn Nets +3.0</span>
            //           </div>
            //           <div className='flex'>
            //             <span className='pr-2 font-[500] text-black'>2.10</span>
            //             <CloseOutlined className='text-[#898989]' onClick={() => handleHiddenBox(item)} />
            //           </div>
            //         </div>
            //         <div className='flex justify-start pl-2 pb-4'>
            //           <span className='text-[#898989]'>Speard</span>
            //         </div>
            //         <div className='flex justify-start pl-2 betClippers'>
            //           <span className='font-[500] text-black'>Brooklyn Nets & Boston Celtics Clippers</span>
            //         </div>
            //       </div>
            //     ))}

            //     <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
            //       <Button className='flex items-center pl-0' type="text" onClick={clearAll}><DeleteOutlined className='text-[#898989]' /> <span className='text-black font-[500]'>Clear All</span></Button>
            //       {/* <SettingOutlined className={`${showTabs ? 'text-[#7EA2FF]' : 'text-[#898989]'}`} onClick={() => { setShowTabs(!showTabs) }} /> */}
            //     </div>
            //     <BetSlipTab showTabs={showTabs} />
            //   </div>,
            // },
            // {
            //   key: '5',
            //   label: 'Reverse',
            //   children: <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
            //     {arrayActiveItem.map((item, index) => (
            //       <div
            //         key={index}
            //       >
            //         <div className='flex justify-between pb-4 pt-4' key={`Reverse-${item}`}>
            //           <div>
            //             <Checkbox></Checkbox>
            //             <span className='font-[500] text-black pl-2'>Phoenix Suns +2.5</span>
            //           </div>
            //           <div className='flex'>
            //             <span className='pr-2 font-[500] text-black'>0.95</span>
            //             <CloseOutlined className='text-[#898989]' onClick={() => handleHiddenBox(item)} />
            //           </div>
            //         </div>
            //         <div className='flex justify-start pl-2 pb-4'>
            //           <span className='text-[#898989]'>Speard</span>
            //         </div>
            //         <div className='flex justify-start pl-6 betClippers'>
            //           <span className='font-[500] text-black'>Phoenix Suns & Brooklyn Nets Clippers</span>
            //         </div>
            //       </div>
            //     ))}


            //     <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
            //       <Button className='flex items-center pl-0' type="text" onClick={clearAll}><DeleteOutlined className='text-[#898989]' /> <span className='text-black font-[500]'>Clear All</span></Button>
            //       {/* <SettingOutlined className={`${showTabs ? 'text-[#7EA2FF]' : 'text-[#898989]'}`} onClick={() => { setShowTabs(!showTabs) }} /> */}
            //     </div>
            //     <BetSlipTab showTabs={showTabs} />
            //   </div>,
            // },
        ]) : ([
            {
                key: '1',
                label: settingbet.includes('1') ? 'Straight' : '',
                children:
                    <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                        {
                            listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
                                <ItemStraight
                                    tab={tab}
                                    key={i}
                                    index={i}
                                    item={item}
                                    listAddStrightBetsSlip={listAddStrightBetsSlip}
                                    rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                                    setListaddStrightBetsSlip={setListaddStrightBetsSlip}
                                    setTotalStake={setTotalStake}
                                    decimal={decimal}
                                />
                            ))
                        }

                        {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
              <Checkbox
                checked={isAllChecked}
                onChange={(e) => handleCheckAll(e.target.checked)}
              ></Checkbox>
              <div className='flex flex-row-reverse'>
                <Button
                  className='!flex items-center pl-2 pr-1'
                  type="text"
                  onClick={clearAll}
                >
                  <DeleteOutlined
                    className='text-[#898989]'
                  />
                  <span className='text-white font-[500]'
                  >Clear All
                  </span>
                </Button>
              </div>
            </div> */}
                        <BetSlipTab showTabs={false} />
                    </div>,
            },
            // {
            //   key: '2',
            //   label: 'Parlay',
            //   children: <div className={`betTabs ? "hidden" : ""} ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
            //     {
            //       listParlayBetsSlip && listParlayBetsSlip.map((item, i) => (
            //         <ItemParlay
            //           key={i}
            //           index={i}
            //           item={item}
            //           listParlayBetsSlip={listParlayBetsSlip}
            //           rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
            //           setListParlayBetsSlip={setListParlayBetsSlip}
            //           decimal={decimal}
            //         // setTotalStake={setTotalStake}

            //         />
            //       ))}

            //     <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
            //       <Checkbox
            //         checked={isAllCheckedParlay}
            //         onChange={(e) => handleCheckAllParlay(e.target.checked)}
            //       ></Checkbox>
            //       <div className='flex flex-row-reverse'>
            //         <Button
            //           className='!flex items-center pl-2 pr-1'
            //           type="text"
            //           onClick={clearAll}
            //         >
            //           <DeleteOutlined
            //             className='text-[#898989]'
            //           />
            //           <span className='text-black font-[500]'
            //           >Clear All
            //           </span>
            //         </Button>
            //         {/* <SettingOutlined className={`${showTabs ? 'text-[#7EA2FF]' : 'text-[#898989]'}`} onClick={() => { setShowTabs(!showTabs) }} /> */}
            //       </div>
            //     </div>
            //     <BetSlipTab showTabs={showTabs} />
            //   </div>,
            // },
        ])
    // const [items, setItems] = useState<TabsProps['items']>()
    // useEffect(() => {
    //   if (keyGameTab == "gameOnline") {
    //     setItems([
    //       {
    //         key: '1',
    //         label: 'Straight',
    //         children:
    //           <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
    //             {
    //               listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
    //                 <ItemStraight
    //                   key={i}
    //                   index={i}
    //                   item={item}
    //                   listAddStrightBetsSlip={listAddStrightBetsSlip}
    //                   rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
    //                   setListaddStrightBetsSlip={setListaddStrightBetsSlip}
    //                   setTotalStake={setTotalStake}

    //                 />
    //               ))
    //             }

    //             <div className='flex justify-between pt-3'>
    //               <Checkbox
    //                 checked={isAllChecked}
    //                 onChange={(e) => handleCheckAll(e.target.checked)}
    //               ></Checkbox>
    //               <div className='flex flex-row-reverse'>
    //                 <Button
    //                   className='!flex items-center pl-2 pr-1'
    //                   type="text"
    //                   onClick={clearAll}
    //                 >
    //                   <DeleteOutlined
    //                     className='text-[#898989]'
    //                   />
    //                   <span className='text-black font-[500]'
    //                   >Clear All
    //                   </span>
    //                 </Button>
    //                 <SettingOutlined className={`${showTabs ? 'text-[#7EA2FF]' : 'text-[#898989]'}`} onClick={() => { setShowTabs(!showTabs) }} />
    //               </div>
    //             </div>
    //             <BetSlipTab showTabs={showTabs} />
    //           </div>,
    //       }])
    //   } else {
    //     setItems(itemsDefault)
    //   }
    // }, [keyGameTab])

    return (
        <Drawer
            open={openDrawer}
            onClose={() => closeDrawer(false)}
            closeIcon={false}
            placement="bottom"
            className="!bg-[#131620] p-none-drawer xs:!block  xl:!hidden !h-full !overflow-hidden"
            maskClassName='xs:!block xl:!hidden'
        >
            <div className="overflow-hidden h-full ">
                <div className={`flex flex-col  border border-[#202531] bg-[#131620] w-full  ${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? ' justify-between h-full' : 'justify-start '}`}>
                    <div className="flex flex-col w-full">
                        <div className='bg-gradient-A98F34 flex justify-between h-[38px] px-3 w-full'>
                            <div className='flex items-center gap-2 w-full'>
                                <img src={Dollar} alt="" className="w-[30px] h-[30px]" />
                                <span className='font-bold-GT-America text-sm text-white uppercase'>Balance</span>
                                <HeadRight />
                                <CloseCircleOutlined
                                    className={`text-red-600 cursor-pointer text-[24px]`}
                                    onClick={() => closeDrawer(false)}
                                />
                            </div>

                        </div>
                        <span className='flex justify-between p-3'>
                            <div className="flex items-center gap-2">
                                <img src={start} alt="Crown" className='w-[35px] h-[35px]' />
                                <p className="font-bold-GT-America text-sm text-white uppercase">Bet Slip</p>
                            </div>
                            <div className='flex items-center gap-1 '>
                                <div className={`${listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0 || listParlayBetsSlip && listParlayBetsSlip.length > 0 ? 'flex items-center gap-1' : 'hidden '}`}
                                    onClick={clearAll}
                                >
                                    <img src={Trash} alt="" />
                                    <span className='text-sm text-white uppercase fw-[500]'>Remove All</span>
                                </div>

                                <span className={`h-5 w-5 rounded-[50%] xs:text-xs xl2:text-base flex items-center justify-center ${listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0 ? 'bg-[#D4B962] text-[#131620]' : 'bg-[#202531] text-white'}`}>
                                    {keyBetTabs === '1' ? listAddStrightBetsSlip && listAddStrightBetsSlip.length.toString() : listAddStrightBetsSlip && listAddStrightBetsSlip.length.toString()}
                                </span>
                            </div>
                        </span>
                        <div className={`tab-bet-soccer ${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 && listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? '' : 'tab-bet-visible'} parent-height parent-height-${window.innerHeight - window.innerHeight / 10 - 500}`}>
                            {/* <Tabs
                                items={items}
                                activeKey={keyBetTabs}
                                onChange={(e) => {
                                    setKeyBetTabs(e.toString());
                                }}
                                className={`${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 && listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? 'tab-bet-home-none' : 'tab-bet-visible'}`}
                            /> */}
                            <Tabs items={items} activeKey={keyBetTabs} onChange={(e) => {
                                if (e === '3') {
                                    if (listChooseBet && listChooseBet?.filter(item => item.lineBetting === "Money").length > 0) {
                                        pushToast(`Teaser does not allow Money Line betting`, "warn");
                                    } else {
                                        setKeyBetTabs(e)
                                    }
                                } else {
                                    setKeyBetTabs(e)
                                }
                            }}
                                className={`${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 && listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? 'tab-bet-home-none' : 'tab-bet-visible'}`} />
                            <div className={`${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 && listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? 'block' : 'hidden'}`}>
                                <EmtyBetSlip activeKey={keyBetTabs} />
                            </div>

                        </div>
                    </div>
                    <div className={`${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? '' : 'hidden'} border-t border-[#202531] flex flex-col gap-3 px-6 pb-5`}>
                        <div className='flex justify-between pt-3'>
                            <div className='flex flex-col gap-4'>
                                <span className='text-base fw-[400] text-[#E4E7F1]'>
                                    Total stake
                                </span>
                                <span className='text-base fw-[400] text-[#E4E7F1]'>-</span>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <span className='text-base fw-[400] text-[#E4E7F1]'>
                                    Total win
                                </span>
                                <span className='text-base fw-[400] text-[#E4E7F1]'>-</span>
                            </div>
                        </div>
                        <button className={`font-bold-GT-America rounded-[2px]  uppercase w-full  py-[10px] ${listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0 ? 'bg-[#D4B962] text-[#20273D]' : 'bg-[#E4E7F1] text-[#666E97]'}`}>
                            Place bet
                        </button>
                    </div>
                </div>
                <TotalStakeComponent
                    keyBetTabs={keyBetTabs}
                    showTotalBet={showTotalBet}
                    showTotalStake={showTotalStake}
                    showTotalStake_V2={showTotalStake_V2}

                    isWarningBetParlay={isWarningBetParlay}
                    totalOddsParlay={totalOddsParlay}
                    totalTParlay={totalTParlay}
                    totalAmountParlay={totalAmountParlay}
                    isWarningBetTeaser={isWarningBetTeaser}
                    totalOddsTeaser={totalOddsTeaser}
                    totalAmountTeaser={totalAmountTeaser}
                    numberStake={totalStake}
                    listAddStrightBetsSlip={listAddStrightBetsSlip ?? []}
                    listParlayBetsSlip={listParlayBetsSlip}
                    listTeaserBetsSlip={listTeaserBetsSlip}

                    handleClick={handleClick}
                    handleNumberClick={handleNumberClick}
                    handleChangeInput={handleChangeInput}
                    minInputStake={minInputStake}
                    maxInputStake={maxInputStake}
                    handleDeleteClick={handleDeleteClick}
                    showHideTotalStake={showHideTotalStake}
                    showHideTotalStake_V2={showHideTotalStake_V2}
                    handleCheckQuantityMoneyStraight={handleCheckQuantityMoneyStraight}
                    handleCheckBet={handleCheckBet}
                    handleCheckBetParlay={handleCheckBetParlay}
                    handleCheckBetTeaser={handleCheckBetTeaser}
                    hiddenBetPlace={hiddenBetPlace}
                    minBet={minBet}
                    maxBet={maxBet}
                />
            </div>
        </Drawer>


    );
};

export default ModalBetSlip_V2;