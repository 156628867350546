import React, { useState } from 'react';
import '../GameList/gameList.css'
import { bg_checked, checked, ElipseRed, LogoWithoutText } from '../../../../../components/imgExport';
import { Tooltip } from 'antd';
import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import { ChooseBet } from '../../../../../api/betting/bettingApi';
import { InfoBetMGM } from '../../../../../api/homePage/homePage_V2Api';
import { ICON_TEAM } from '../../../../../env';
import { covertDataGames, covertDataGamesOld, covertDataOptionMarkets, covertDataOptionMarketsOld, getChangeClass_V3, getChangeColorText, getChangeColor_V3, getCheckSelect_V3 } from '../../../../../until';
interface GameDetailProps {
    sport?: string
    tournament: string
    item: InfoBetMGM
    listChooseBet: ChooseBet[]
    competitionsLiveOld: { [key: string]: InfoBetMGM[] }
    chooseBet?: (
        tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", dateEvent: string, live: boolean, id?: number | string, idGame?: number | string, optionId?: number | string, homeScore?: number, awayScore?: number, dateEventServer?: string, order?: number
    ) => void
    checkUpdateStrightBetsSlip: (teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", color: string, homeScore?: number, awayScore?: number, id?: number | string) => void
    decimal: string
    inputFilter?: string
}
const GameLiveDeTail_V2: React.FC<GameDetailProps> = ({
    sport, tournament, item, listChooseBet, competitionsLiveOld, decimal, inputFilter,
    chooseBet, checkUpdateStrightBetsSlip

}) => {
    const [timeGame, setTimeGame] = useState<number>(1)
    const { games, startDate, participants, optionMarkets } = item
    const { spread, totals, moneyLine } = covertDataGames(games)
    var spreadOption: any
    var totalsOption: any
    var moneyLineOption: any

    var FHspread: any
    var FHtotals: any
    var FHmoneyLine: any
    var spread5Innings: any
    var totals5Innings: any
    var moneyLine5Innings: any
    if (games.length === 0) {
        spreadOption = covertDataOptionMarkets(optionMarkets).spread
        totalsOption = covertDataOptionMarkets(optionMarkets).totals
        moneyLineOption = covertDataOptionMarkets(optionMarkets).moneyLine

        FHspread = covertDataOptionMarkets(optionMarkets).FHspread
        FHtotals = covertDataOptionMarkets(optionMarkets).FHtotals
        FHmoneyLine = covertDataOptionMarkets(optionMarkets).FHmoneyLine
        spread5Innings = covertDataOptionMarkets(optionMarkets).spread5Innings
        totals5Innings = covertDataOptionMarkets(optionMarkets).totals5Innings
        moneyLine5Innings = covertDataOptionMarkets(optionMarkets).moneyLine5Innings
        if (!spreadOption) {
            spreadOption = optionMarkets.filter((market) => market.name.value === "Run Line Spread")[0]
        }
    } else {
        FHspread = games.filter((game) => game.name.value === "First half spread")[0]
        FHtotals = games.filter((game) => game.name.value === "1st Half Totals")[0]
        FHmoneyLine = games.filter((game) => game.name.value === "First half money line")[0]
        spread5Innings = games.filter((game) => game.name.value === "First 5 innings: Spread")[0]
        totals5Innings = games.filter((game) => game.name.value === "First 5 innings: Total runs")[0]
        moneyLine5Innings = games.filter((game) => game.name.value === "First 5 innings: Money line")[0]
    }
    var spreadRunLine: any
    if (!spread) {
        spreadRunLine = optionMarkets.filter((game) => game.name.value === "Run Line Spread")[0]
    }

    var xx: any
    var spreadOld: any
    var totalsOld: any
    var moneyLineOld: any

    var FHspreadOld: any
    var FHtotalsOld: any
    var FHmoneyLineOld: any
    var spread5InningsOld: any
    var totals5InningsOld: any
    var moneyLine5InningsOld: any
    if (competitionsLiveOld && competitionsLiveOld[tournament] && competitionsLiveOld[tournament].length > 0) {
        xx = competitionsLiveOld[tournament].filter((x) => x.id === item.id)[0]
        if (xx) {
            if (games.length === 0) {
                if (covertDataOptionMarketsOld(xx.optionMarkets)) {
                    spreadOld = covertDataOptionMarketsOld(xx.optionMarkets).spreadOld
                    if (!spreadOld) {
                        spreadOld = xx.optionMarkets.filter((market: any) => market.name.value === "Run Line Spread")[0]
                    }
                    totalsOld = covertDataOptionMarketsOld(xx.optionMarkets).totalsOld
                    moneyLineOld = covertDataOptionMarketsOld(xx.optionMarkets).moneyLineOld


                    FHspreadOld = covertDataOptionMarketsOld(xx.optionMarkets).FHspreadOld
                    FHtotalsOld = covertDataOptionMarketsOld(xx.optionMarkets).FHtotalsOld
                    FHmoneyLineOld = covertDataOptionMarketsOld(xx.optionMarkets).FHmoneyLineOld

                    spread5InningsOld = covertDataOptionMarketsOld(xx.optionMarkets).spread5InningsOld
                    totals5InningsOld = covertDataOptionMarketsOld(xx.optionMarkets).totals5InningsOld
                    moneyLine5InningsOld = covertDataOptionMarketsOld(xx.optionMarkets).moneyLine5InningsOld
                }
            } else {
                if (covertDataGamesOld(xx.games)) {
                    spreadOld = covertDataGamesOld(xx.games).spreadOld
                    if (!spreadOld) {
                        spreadOld = xx.games.filter((games: any) => games.name.value === "Run Line Spread")[0]
                    }
                    totalsOld = covertDataGamesOld(xx.games).totalsOld
                    moneyLineOld = covertDataGamesOld(xx.games).moneyLineOld

                    FHspreadOld = covertDataGamesOld(xx.games).FHspreadOld
                    FHtotalsOld = covertDataGamesOld(xx.games).FHtotalsOld
                    FHmoneyLineOld = covertDataGamesOld(xx.games).FHmoneyLineOld

                    spread5InningsOld = covertDataGamesOld(xx.games).spread5InningsOld
                    totals5InningsOld = covertDataGamesOld(xx.games).totals5InningsOld
                    moneyLine5InningsOld = covertDataGamesOld(xx.games).moneyLine5InningsOld
                }
            }

        }
        // { spreadOld, totalsOld, moneyLineOld } =  covertDataGamesOld(xx.games)
    }
    // console.log(FHspread, participants[1].name.value + " / " + participants[0].name.value);

    // const { FHspread, FHtotals, FHmoneyLine, spread5Innings, totals5Innings, moneyLine5Innings } = covertDataOptionMarkets(optionMarkets)
    // const { spreadOld, totalsOld, moneyLineOld } = (xx) ? covertDataGamesOld(xx.games) : {}
    const handelBet = (teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", id?: number | string, order?: number) => {
        const game = participants[1].name.value + " / " + participants[0].name.value
        chooseBet?.(tournament, game, teamChoose, scoreChoose, lineBetting, startDate, true, item.id, item.id, id,
            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
            startDate, order
        )
        //Thiếu truyền tỉ số hiện tại ở kèo MGM vào đây
    }


    return (
        <div className="flex flex-col gap-3">
            <div className="flex justify-between gap-2 w-full">
                <div className={`xl:w-[160px] xl2:w-[220px] flex justify-start items-center px-2 ${window.outerWidth < 1365 ? "rounded-tl-[6px] rounded-tr-[6px]  bg-header" : ""}`}>
                    <div className='flex items-center gap-2'>
                        <img className="pr-1" src={ElipseRed} alt="ElipseRed" />
                        <span className="fw-[500] xs:text-[10px] md:text-sm text-[#FFFFFF]">{item.scoreboard?.period ? item.scoreboard?.period + (item.scoreboard?.indicator ? item.scoreboard?.indicator : "") : startDate}</span>
                    </div>
                </div>

                {(sport === "MLB") && (spread5Innings || totals5Innings || moneyLine5Innings) && (
                    <div className="rounded-tl-[6px] rounded-tr-[6px]  bg-header xs:flex xl:hidden gap-2 items-center justify-between px-1">
                        <span
                            className={`xs:text-[10px] md:text-sm whitespace-nowrap ${timeGame === 1 ? 'text-[#D4B962]' : 'text-[#FFFFFF]'}`}
                            onClick={() => setTimeGame(1)}
                        >
                            Full Game
                        </span>
                        <span
                            className={`xs:text-[10px] md:text-sm whitespace-nowrap ${timeGame === 2 ? 'text-[#D4B962]' : 'text-[#FFFFFF]'}`}
                            onClick={() => setTimeGame(2)}
                        >
                            First 5 Innings
                        </span>
                    </div>
                )}
                {(sport === "NFL" || sport === "NBA") && (FHspread || FHtotals || FHmoneyLine) && (
                    <div className="rounded-tl-[6px] rounded-tr-[6px]  bg-header xs:flex xl:hidden gap-2 items-center justify-between px-1">
                        <span
                            className={`xs:text-[10px] md:text-sm whitespace-nowrap ${timeGame === 1 ? 'text-[#D4B962]' : 'text-[#FFFFFF]'}`}
                            onClick={() => setTimeGame(1)}
                        >
                            Full Game
                        </span>
                        <span
                            className={`xs:text-[10px] md:text-sm whitespace-nowrap ${timeGame === 2 ? 'text-[#D4B962]' : 'text-[#FFFFFF]'}`}
                            onClick={() => setTimeGame(2)}
                        >
                            1st Half
                        </span>
                    </div>
                )}
                <div className='bg-header rounded-tl-[6px] rounded-tr-[6px] xs:w-[50%] xl:hidden  xs:grid grid-cols-3 gap-2 py-2'>
                    <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>SPREAD</span>
                    <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>TOTAL</span>
                    <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>MONEY LINE</span>
                </div>
            </div>


            <div className={` flex items-center w-full justify-between border-b border-b-[#1D212D] ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
            >
                {
                    games.length !== 0 && (
                        <div className={`!pl-0 mb-2 bg-[#131620] rounded-2xl flex w-full justify-between ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
                        >
                            <Tooltip placement="right">
                                <div className="flex flex-col w-[30%]">

                                    <div className="gameTime bg-[#131620] flex  items-center h-full py-1"
                                    // onClick={() => navigator("/detailPage")}
                                    >
                                        <div className='flex flex-col justify-between items-start gap-3 h-full w-full'>
                                            <div className=' flex items-center  justify-between xs:gap-2 md:gap-0 xs:w-full md:w-[160px]  xl2:w-[190px] '>
                                                <div className='flex items-center gap-2'>
                                                    <img
                                                        className='w-[30px] h-[30px]'
                                                        // src={LogoWithoutText}
                                                        src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[0].image?.logo) : (ICON_TEAM + `7/` + participants[0].image?.logo)}
                                                        alt="team1"
                                                        onError={(e) => {
                                                            e.currentTarget.src = LogoWithoutText;
                                                        }} />
                                                    <span className='xs:text-[10px] xl:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[0].name.short}</span>
                                                </div>

                                                <b className='xs:text-[10px] xl:text-sm  text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
                                            </div>
                                            {/* <span className='text-[#D4B962] text-base'>VS</span> */}
                                            <div className=' flex items-center  justify-between xs:gap-2 md:gap-0 xs:w-full md:w-[160px]  xl2:w-[190px] '>
                                                <div className='flex items-center gap-2'>
                                                    <img
                                                        className=' w-[30px] h-[30px]'
                                                        // src={LogoWithoutText}
                                                        src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[1].image?.logo) : (ICON_TEAM + `7/` + participants[1].image?.logo)}
                                                        alt="team1"
                                                        onError={(e) => {
                                                            e.currentTarget.src = LogoWithoutText;
                                                        }} />
                                                    <span className='xs:text-[10px] xl:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[1].name.short}</span>
                                                </div>

                                                <b className='xs:text-[10px] xl:text-sm  text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Tooltip>
                            {item.scoreboard?.period === '9th Inning' || item.scoreboard?.period === '10th Inning' || item.scoreboard?.period === '11th Inning' || item.scoreboard?.period === '12th Inning' || item.scoreboard?.period === '13th Inning' || item.scoreboard?.period === '14th Inning' || item.scoreboard?.period === '15th Inning' ?
                                <div className="gameRatio xs:w-[50%]  xl:w-[500px]  xl3:w-[800px] flex flex-col gap-3 justify-center">
                                    <div className="homeRaito flex gap-2">
                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="homeRaito flex  gap-2">

                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                :
                                <div className="gameRatio xs:w-[50%]  xl:w-[500px]  xl3:w-[800px] flex flex-col gap-3 justify-center">
                                    <div className="homeRaito flex gap-2">

                                        {(sport === "NFL" || sport === "NBA" || sport !== "") && (item.scoreboard?.period === "Halftime" || item.scoreboard?.period !== "Halftime") ? (
                                            <>
                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                                    {spread?.visibility === "Visible" ? (
                                                        <div
                                                            className={
                                                                `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                            }
                                                        >
                                                            {
                                                                spread?.results?.[0]?.visibility === "Visible" && spread?.results[0]?.attr ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[0]?.odds : spreadOld?.results[0]?.americanOdds) : 0,
                                                                        participants[0].name.value, "Spread", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, spread?.results[0]?.attr + " " + (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? spreadOld?.results[0]?.odds : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            spread?.results[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, spread?.results[0]?.attr + " " + (decimal !== 'American' ? spread?.results[0]?.odds : (Number(spread?.results[0]?.americanOdds) > 0 ? '+' + spread?.results[0]?.americanOdds : spread?.results[0]?.americanOdds)), "Spread", spread?.results[0]?.id, 0)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spread?.results[0]?.attr}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? spread?.results[0]?.odds.toFixed(2) : spread?.results[0]?.americanOdds)}`}</b></p>

                                                                    </div> :
                                                                    spreadRunLine?.results[0]?.attr ?
                                                                        <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[0]?.odds : spreadOld?.results[0]?.americanOdds) : 0,
                                                                            participants[0].name.value, "Spread", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, spread?.results[0]?.attr + " " + (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? spreadOld?.results[0]?.odds : 0),
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                spread?.results[0]?.id
                                                                            )
                                                                        )}`}
                                                                            onClick={() => handelBet?.(participants[0].name.value, spreadRunLine?.results[0]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.results[0]?.price?.odds : (Number(spreadRunLine?.results[0]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.results[0]?.price?.americanOdds}` : spreadRunLine?.results[0]?.price?.americanOdds)), "Spread", spreadRunLine?.results[0]?.id, 0)}
                                                                        >
                                                                            <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spreadRunLine?.results[0]?.attr}`}</p>
                                                                            <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? spreadRunLine?.results[0]?.price?.odds.toFixed(2) : spreadRunLine?.results[0]?.price?.americanOdds)}`}</b></p>
                                                                        </div> :
                                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[0]?.odds : spreadOld?.results[0]?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {totals?.visibility === "Visible" ? (
                                                        <div
                                                            className={
                                                                ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962] `}
                                                        >
                                                            {
                                                                totals?.results?.[0]?.visibility === "Visible" && totals?.results[0]?.name.value ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[0]?.odds : totalsOld?.results[0]?.americanOdds) : 0,
                                                                        participants[0].name.value, "Total", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, totals?.results[0]?.name.value + " " + (decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), "Total", getChangeColorText((decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[0]?.odds : totalsOld?.results[0]?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            totals?.results[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, totals?.results[0]?.name.value + " " + (decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), "Total", totals?.results[0]?.id, 0)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totals?.results[0]?.name.value}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:xs:text-xs md:text-sm'><b className="">{`${(decimal !== 'American' ? totals?.results[0]?.odds.toFixed(2) : (Number(totals?.results[0]?.americanOdds) > 0 ? `+${totals?.results[0]?.americanOdds}` : totals?.results[0]?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[0]?.odds : totalsOld?.results[0]?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {moneyLine?.visibility === "Visible" ? (
                                                        <div
                                                            className={
                                                                ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`}
                                                        >
                                                            {
                                                                moneyLine?.results[0]?.visibility === "Visible" && moneyLine?.results[0]?.odds ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? moneyLine?.results[0]?.odds : moneyLine?.results[0]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[0]?.odds : moneyLineOld?.results[0]?.americanOdds) : 0,
                                                                        participants[0].name.value, "Money", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? moneyLine?.results[0].odds?.toString() : moneyLine?.results[0].americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLine?.results[0]?.odds : moneyLine?.results[0]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[0]?.odds : moneyLineOld?.results[0]?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            moneyLine?.results[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLine?.results[0].odds?.toString() : moneyLine?.results[0].americanOdds?.toString()), "Money", moneyLine?.results[0]?.id, 0)}
                                                                    >
                                                                        <b className='text-[#D4B962] xs:text-xs md:xs:text-xs md:text-sm'>{(decimal !== 'American' ? moneyLine?.results[0]?.odds.toFixed(2) : (Number(moneyLine?.results[0]?.americanOdds) > 0 ? `+${moneyLine?.results[0]?.americanOdds}` : moneyLine?.results[0]?.americanOdds))}</b>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine?.results[0]?.odds : moneyLine?.results[0]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[0]?.odds : moneyLineOld?.results[0]?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  pl-2 flex items-center flex-col  h-[40px] rounded-md `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}
                                                </div>
                                                {(sport === "NFL" || sport === "NBA") ?
                                                    <>
                                                        {/* Hiệp 1 của NFL và NBA */}
                                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 2 ? 'w-full' : 'hidden')}`}>
                                                            {FHspread?.visibility === 'Visible' ? (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                                        }
                                                                    >
                                                                        {
                                                                            FHspread?.results?.[0]?.visibility === "Visible" && FHspread?.results[0]?.attr ?
                                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                    (decimal !== 'American' ? FHspread?.results[0]?.odds : FHspread?.results[0]?.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.results[0]?.odds : FHspreadOld?.results[0]?.americanOdds) : 0,
                                                                                    participants[0].name.value, "First Half Spread", startDate, listChooseBet,
                                                                                    () => checkUpdateStrightBetsSlip(participants[0].name.value, FHspread?.results[0]?.attr + " " + (decimal !== 'American' ? FHspread?.results[0]?.odds : FHspread?.results[0]?.americanOdds), "First Half Spread", getChangeColorText((decimal !== 'American' ? FHspread?.results[0]?.odds : FHspread?.results[0]?.americanOdds), FHspreadOld ? FHspreadOld?.results[0]?.odds : 0),
                                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                        FHspread?.results[0]?.id
                                                                                    )
                                                                                )}`}
                                                                                    onClick={() => handelBet?.(participants[0].name.value, FHspread?.results[0]?.attr + " " + (decimal !== 'American' ? FHspread?.results[0]?.odds : (Number(FHspread?.results[0]?.americanOdds) > 0 ? '+' + FHspread?.results[0]?.americanOdds : FHspread?.results[0]?.americanOdds)), "First Half Spread", FHspread?.results[0]?.id, 0)}
                                                                                >
                                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${FHspread?.results[0]?.attr}`}</p>
                                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? FHspread?.results[0]?.odds.toFixed(2) : FHspread?.results[0]?.americanOdds)}`}</b></p>

                                                                                </div> :
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHspread?.results[0]?.odds : FHspread?.results[0]?.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.results[0]?.odds : FHspreadOld?.results[0]?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                </>



                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet)}`}
                                                                >
                                                                    <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}


                                                            {FHtotals?.visibility === 'Visible' ? (
                                                                <div
                                                                    className={
                                                                        ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962] `}
                                                                >
                                                                    {
                                                                        FHtotals?.results?.[0]?.visibility === "Visible" && FHtotals?.results[0]?.name.value ?
                                                                            <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                (decimal !== 'American' ? FHtotals?.results[0]?.odds : FHtotals?.results[0]?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.results[0]?.odds : FHtotalsOld?.results[0]?.americanOdds) : 0,
                                                                                participants[0].name.value, "First Half Total", startDate, listChooseBet,
                                                                                () => checkUpdateStrightBetsSlip(participants[0].name.value, FHtotals?.results[0]?.name.value + " " + (decimal !== 'American' ? FHtotals?.results[0]?.odds : FHtotals?.results[0]?.americanOdds), "First Half Total", getChangeColorText((decimal !== 'American' ? FHtotals?.results[0]?.odds : FHtotals?.results[0]?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.results[0]?.odds : FHtotalsOld?.results[0]?.americanOdds) : 0),
                                                                                    item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                    item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                    FHtotals?.results[0]?.id
                                                                                )
                                                                            )}`}
                                                                                onClick={() => handelBet?.(participants[0].name.value, FHtotals?.results[0]?.name.value + " " + (decimal !== 'American' ? FHtotals?.results[0]?.odds : FHtotals?.results[0]?.americanOdds), "First Half Total", FHtotals?.results[0]?.id, 0)}
                                                                            >
                                                                                <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${FHtotals?.results[0]?.name.value}`}</p>
                                                                                <p className='text-[#D4B962] xs:text-xs md:xs:text-xs md:text-sm'><b className="">{`${(decimal !== 'American' ? FHtotals?.results[0]?.odds.toFixed(2) : (Number(FHtotals?.results[0]?.americanOdds) > 0 ? `+${FHtotals?.results[0]?.americanOdds}` : FHtotals?.results[0]?.americanOdds))}`}</b></p>
                                                                            </div> :
                                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                    }
                                                                    <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHtotals?.results[0]?.odds : FHtotals?.results[0]?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.results[0]?.odds : FHtotalsOld?.results[0]?.americanOdds) : 0)}`} />
                                                                    {getCheckSelect_V3(participants[0].name.value, "First Half Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md ${getCheckSelect_V3(participants[0].name.value, "First Half Total")}`}
                                                                >
                                                                    <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}


                                                            {FHmoneyLine?.visibility === 'Visible' ? (
                                                                <div
                                                                    className={
                                                                        ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`}
                                                                >
                                                                    {
                                                                        FHmoneyLine?.results[0]?.visibility === "Visible" && FHmoneyLine?.results[0]?.odds ?
                                                                            <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                (decimal !== 'American' ? FHmoneyLine?.results[0]?.odds : FHmoneyLine?.results[0]?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.results[0]?.odds : FHmoneyLineOld?.results[0]?.americanOdds) : 0,
                                                                                participants[0].name.value, "First Half Money", startDate, listChooseBet,
                                                                                () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? FHmoneyLine?.results[0].odds?.toString() : FHmoneyLine?.results[0].americanOdds?.toString()), "First Half Money", getChangeColorText((decimal !== 'American' ? FHmoneyLine?.results[0]?.odds : FHmoneyLine?.results[0]?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.results[0]?.odds : FHmoneyLineOld?.results[0]?.americanOdds) : 0),
                                                                                    item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                    item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                    FHmoneyLine?.results[0]?.id
                                                                                )
                                                                            )}`}
                                                                                onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? FHmoneyLine?.results[0].odds?.toString() : FHmoneyLine?.results[0].americanOdds?.toString()), "First Half Money", FHmoneyLine?.results[0]?.id, 0)}
                                                                            >
                                                                                <b className='text-[#D4B962] xs:text-xs md:xs:text-xs md:text-sm'>{(decimal !== 'American' ? FHmoneyLine?.results[0]?.odds.toFixed(2) : (Number(FHmoneyLine?.results[0]?.americanOdds) > 0 ? `+${FHmoneyLine?.results[0]?.americanOdds}` : FHmoneyLine?.results[0]?.americanOdds))}</b>
                                                                            </div> :
                                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                    }
                                                                    <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHmoneyLine?.results[0]?.odds : FHmoneyLine?.results[0]?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.results[0]?.odds : FHmoneyLineOld?.results[0]?.americanOdds) : 0)}`} />
                                                                    {getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet)}`}
                                                                >
                                                                    <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {/* 5 hiệp đầu của MLB */}
                                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 2 ? 'w-full' : 'hidden')}`}>
                                                            {spread5Innings?.visibility === 'Visible' ? (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                                        }
                                                                    >
                                                                        {
                                                                            spread5Innings?.results?.[0]?.visibility === "Visible" && spread5Innings?.results[0]?.attr ?
                                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                    (decimal !== 'American' ? spread5Innings?.results[0]?.odds : spread5Innings?.results[0]?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.results[0]?.odds : spread5InningsOld?.results[0]?.americanOdds) : 0,
                                                                                    participants[0].name.value, "First Half Spread", startDate, listChooseBet,
                                                                                    () => checkUpdateStrightBetsSlip(participants[0].name.value, spread5Innings?.results[0]?.attr + " " + (decimal !== 'American' ? spread5Innings?.results[0]?.odds : spread5Innings?.results[0]?.americanOdds), "First Half Spread", getChangeColorText((decimal !== 'American' ? spread5Innings?.results[0]?.odds : spread5Innings?.results[0]?.americanOdds), spread5InningsOld ? spread5InningsOld?.results[0]?.odds : 0),
                                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                        spread5Innings?.results[0]?.id
                                                                                    )
                                                                                )}`}
                                                                                    onClick={() => handelBet?.(participants[0].name.value, spread5Innings?.results[0]?.attr + " " + (decimal !== 'American' ? spread5Innings?.results[0]?.odds : (Number(spread5Innings?.results[0]?.americanOdds) > 0 ? '+' + spread5Innings?.results[0]?.americanOdds : spread5Innings?.results[0]?.americanOdds)), "First Half Spread", spread5Innings?.results[0]?.id, 0)}
                                                                                >
                                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spread5Innings?.results[0]?.attr}`}</p>
                                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? spread5Innings?.results[0]?.odds.toFixed(2) : spread5Innings?.results[0]?.americanOdds)}`}</b></p>

                                                                                </div> :
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread5Innings?.results[0]?.odds : spread5Innings?.results[0]?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.results[0]?.odds : spread5InningsOld?.results[0]?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                </>



                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet)}`}
                                                                >
                                                                    <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}

                                                            {totals5Innings?.visibility === 'Visible' ? (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                                        }
                                                                    >
                                                                        {
                                                                            totals5Innings?.results?.[0]?.visibility === "Visible" && totals5Innings?.results[0]?.attr ?
                                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                    (decimal !== 'American' ? totals5Innings?.results[0]?.odds : totals5Innings?.results[0]?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.results[0]?.odds : totals5InningsOld?.results[0]?.americanOdds) : 0,
                                                                                    participants[0].name.value, "First Half Total", startDate, listChooseBet,
                                                                                    () => checkUpdateStrightBetsSlip(participants[0].name.value, totals5Innings?.results[0]?.attr + " " + (decimal !== 'American' ? totals5Innings?.results[0]?.odds : totals5Innings?.results[0]?.americanOdds), "First Half Total", getChangeColorText((decimal !== 'American' ? totals5Innings?.results[0]?.odds : totals5Innings?.results[0]?.americanOdds), totals5InningsOld ? totals5InningsOld?.results[0]?.odds : 0),
                                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                        totals5Innings?.results[0]?.id
                                                                                    )
                                                                                )}`}
                                                                                    onClick={() => handelBet?.(participants[0].name.value, totals5Innings?.results[0]?.attr + " " + (decimal !== 'American' ? totals5Innings?.results[0]?.odds : (Number(totals5Innings?.results[0]?.americanOdds) > 0 ? '+' + totals5Innings?.results[0]?.americanOdds : totals5Innings?.results[0]?.americanOdds)), "First Half Total", totals5Innings?.results[0]?.id, 0)}
                                                                                >
                                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totals5Innings?.results[0]?.attr}`}</p>
                                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? totals5Innings?.results[0]?.odds.toFixed(2) : totals5Innings?.results[0]?.americanOdds)}`}</b></p>

                                                                                </div> :
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals5Innings?.results[0]?.odds : totals5Innings?.results[0]?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.results[0]?.odds : totals5InningsOld?.results[0]?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                </>



                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[0].name.value, "First Half Total", listChooseBet)}`}
                                                                >
                                                                    <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}


                                                            {moneyLine5Innings?.visibility === 'Visible' ? (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                                        }
                                                                    >
                                                                        {
                                                                            moneyLine5Innings?.results?.[0]?.visibility === "Visible" && moneyLine5Innings?.results[0]?.attr ?
                                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                    (decimal !== 'American' ? moneyLine5Innings?.results[0]?.odds : moneyLine5Innings?.results[0]?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.results[0]?.odds : moneyLine5InningsOld?.results[0]?.americanOdds) : 0,
                                                                                    participants[0].name.value, "First Half Money", startDate, listChooseBet,
                                                                                    () => checkUpdateStrightBetsSlip(participants[0].name.value, moneyLine5Innings?.results[0]?.attr + " " + (decimal !== 'American' ? moneyLine5Innings?.results[0]?.odds : moneyLine5Innings?.results[0]?.americanOdds), "First Half Money", getChangeColorText((decimal !== 'American' ? moneyLine5Innings?.results[0]?.odds : moneyLine5Innings?.results[0]?.americanOdds), moneyLine5InningsOld ? moneyLine5InningsOld?.results[0]?.odds : 0),
                                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                        moneyLine5Innings?.results[0]?.id
                                                                                    )
                                                                                )}`}
                                                                                    onClick={() => handelBet?.(participants[0].name.value, moneyLine5Innings?.results[0]?.attr + " " + (decimal !== 'American' ? moneyLine5Innings?.results[0]?.odds : (Number(moneyLine5Innings?.results[0]?.americanOdds) > 0 ? '+' + moneyLine5Innings?.results[0]?.americanOdds : moneyLine5Innings?.results[0]?.americanOdds)), "First Half Money", moneyLine5Innings?.results[0]?.id, 0)}
                                                                                >
                                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${moneyLine5Innings?.results[0]?.attr}`}</p>
                                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? moneyLine5Innings?.results[0]?.odds.toFixed(2) : moneyLine5Innings?.results[0]?.americanOdds)}`}</b></p>

                                                                                </div> :
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine5Innings?.results[0]?.odds : moneyLine5Innings?.results[0]?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.results[0]?.odds : moneyLine5InningsOld?.results[0]?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                </>



                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet)}`}
                                                                >
                                                                    <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </>
                                                }

                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>

                                                <div
                                                    className={
                                                        `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                            </>

                                        )}

                                        {/* Nếu mở live thì mở ở đây */}


                                    </div>

                                    <div className="awayRaito flex gap-2">

                                        {(sport === "NFL" || sport === "NBA" || sport !== "") && (item.scoreboard?.period === "Halftime" || item.scoreboard?.period !== "Halftime") ? (
                                            <>
                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                                    {spread?.visibility === "Visible" ? (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                spread?.results?.[1]?.visibility === "Visible" && spread?.results[1]?.attr ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0,
                                                                        participants[1].name.value, "Spread", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, spread?.results[1]?.attr + " " + (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            spread?.results[1]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, spread?.results[1]?.attr + " " + (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), "Spread", spread?.results[1]?.id, 1)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spread?.results[1]?.attr}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="">{`${(decimal !== 'American' ? spread?.results[1]?.odds.toFixed(2) : (Number(spread?.results[1]?.americanOdds) > 0 ? '+' + spread?.results[1]?.americanOdds : spread?.results[1]?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    spreadRunLine?.results[1]?.attr ?
                                                                        <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0,
                                                                            participants[1].name.value, "Spread", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, spread?.results[1]?.attr + " " + (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0),
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                spread?.results[1]?.id
                                                                            )
                                                                        )}`}
                                                                            onClick={() => handelBet?.(participants[1].name.value, spreadRunLine?.results[1]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.results[1]?.price?.odds : spreadRunLine?.results[1]?.price?.americanOdds), "Spread", spreadRunLine?.results[1]?.id, 1)}
                                                                        >
                                                                            <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spreadRunLine?.results[1]?.attr}`}</p>
                                                                            <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? spreadRunLine?.results[1]?.price?.odds.toFixed(2) : (Number(spreadRunLine?.results[1]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.results[1]?.price?.americanOdds}` : spreadRunLine?.results[1]?.price?.americanOdds))}`}</b></p>
                                                                        </div> :
                                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[1].name.value, "Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {totals?.visibility === "Visible" ? (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                totals?.results?.[1]?.visibility === "Visible" && totals?.results[1]?.name.value ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[1]?.odds : totalsOld?.results[1]?.americanOdds) : 0,
                                                                        participants[1].name.value, "Total", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, totals?.results[1]?.name.value + " " + (decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), "Total", getChangeColorText((decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[1]?.odds : totalsOld?.results[1]?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            totals?.results[1]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, totals?.results[1]?.name.value + " " + (decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), "Total", totals?.results[1]?.id, 1)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totals?.results[1]?.name.value}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="">{`${(decimal !== 'American' ? totals?.results[1]?.odds.toFixed(2) : (Number(totals?.results[1]?.americanOdds) > 0 ? `+${totals?.results[1]?.americanOdds}` : totals?.results[1]?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[1]?.odds : totalsOld?.results[1]?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[1].name.value, "Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>

                                                        </div>
                                                    )}

                                                    {moneyLine?.visibility === "Visible" ? (
                                                        <div
                                                            className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                moneyLine?.results?.[1]?.visibility === "Visible" && moneyLine?.results[1]?.odds ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? moneyLine?.results[1]?.odds : moneyLine?.results[1]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[1]?.odds : moneyLineOld?.results[1]?.americanOdds) : 0,
                                                                        participants[1].name.value, "Money", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? moneyLine?.results[1]?.odds?.toString() : moneyLine?.results[1]?.americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLine?.results[1]?.odds : moneyLine?.results[1]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[1]?.odds : moneyLineOld?.results[1]?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            moneyLine?.results[1]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLine?.results[1]?.odds?.toString() : moneyLine?.results[1]?.americanOdds?.toString()), "Money", moneyLine?.results[1]?.id, 1)}
                                                                    >
                                                                        <b className='text-[#D4B962] xs:text-xs md:xs:text-xs md:text-sm'>{(decimal !== 'American' ? moneyLine?.results[1]?.odds.toFixed(2) : (Number(moneyLine?.results[1]?.americanOdds) > 0 ? `+${moneyLine?.results[1]?.americanOdds}` : moneyLine?.results[1]?.americanOdds))}</b>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine?.results[1]?.odds : moneyLine?.results[1]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[1]?.odds : moneyLineOld?.results[1]?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[1].name.value, "Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}
                                                </div>
                                                {(sport === "NFL" || sport === "NBA") ?
                                                    <>
                                                        {/* Hiệp 1 của NFL và NBA */}
                                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 2 ? 'w-full' : 'hidden')}`}>
                                                            {FHspread?.visibility === 'Visible' ? (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                                        }
                                                                    >
                                                                        {
                                                                            FHspread?.results?.[1]?.visibility === "Visible" && FHspread?.results[1]?.attr ?
                                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                    (decimal !== 'American' ? FHspread?.results[1]?.odds : FHspread?.results[1]?.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.results[1]?.odds : FHspreadOld?.results[1]?.americanOdds) : 0,
                                                                                    participants[1].name.value, "First Half Spread", startDate, listChooseBet,
                                                                                    () => checkUpdateStrightBetsSlip(participants[1].name.value, FHspread?.results[1]?.attr + " " + (decimal !== 'American' ? FHspread?.results[1]?.odds : FHspread?.results[1]?.americanOdds), "First Half Spread", getChangeColorText((decimal !== 'American' ? FHspread?.results[1]?.odds : FHspread?.results[1]?.americanOdds), FHspreadOld ? FHspreadOld?.results[1]?.odds : 0),
                                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                        FHspread?.results[1]?.id
                                                                                    )
                                                                                )}`}
                                                                                    onClick={() => handelBet?.(participants[1].name.value, FHspread?.results[1]?.attr + " " + (decimal !== 'American' ? FHspread?.results[1]?.odds : (Number(FHspread?.results[1]?.americanOdds) > 0 ? '+' + FHspread?.results[1]?.americanOdds : FHspread?.results[1]?.americanOdds)), "First Half Spread", FHspread?.results[1]?.id, 0)}
                                                                                >
                                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${FHspread?.results[1]?.attr}`}</p>
                                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? FHspread?.results[1]?.odds.toFixed(2) : FHspread?.results[1]?.americanOdds)}`}</b></p>

                                                                                </div> :
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHspread?.results[1]?.odds : FHspread?.results[1]?.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.results[1]?.odds : FHspreadOld?.results[1]?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                </>



                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet)}`}
                                                                >
                                                                    <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}


                                                            {FHtotals?.visibility === 'Visible' ? (
                                                                <div
                                                                    className={
                                                                        ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962] `}
                                                                >
                                                                    {
                                                                        FHtotals?.results?.[1]?.visibility === "Visible" && FHtotals?.results[1]?.name.value ?
                                                                            <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                (decimal !== 'American' ? FHtotals?.results[1]?.odds : FHtotals?.results[1]?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.results[1]?.odds : FHtotalsOld?.results[1]?.americanOdds) : 0,
                                                                                participants[1].name.value, "First Half Total", startDate, listChooseBet,
                                                                                () => checkUpdateStrightBetsSlip(participants[1].name.value, FHtotals?.results[1]?.name.value + " " + (decimal !== 'American' ? FHtotals?.results[1]?.odds : FHtotals?.results[1]?.americanOdds), "First Half Total", getChangeColorText((decimal !== 'American' ? FHtotals?.results[1]?.odds : FHtotals?.results[1]?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.results[1]?.odds : FHtotalsOld?.results[1]?.americanOdds) : 0),
                                                                                    item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                    item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                    FHtotals?.results[1]?.id
                                                                                )
                                                                            )}`}
                                                                                onClick={() => handelBet?.(participants[1].name.value, FHtotals?.results[1]?.name.value + " " + (decimal !== 'American' ? FHtotals?.results[1]?.odds : FHtotals?.results[1]?.americanOdds), "First Half Total", FHtotals?.results[1]?.id, 0)}
                                                                            >
                                                                                <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${FHtotals?.results[1]?.name.value}`}</p>
                                                                                <p className='text-[#D4B962] xs:text-xs md:xs:text-xs md:text-sm'><b className="">{`${(decimal !== 'American' ? FHtotals?.results[1]?.odds.toFixed(2) : (Number(FHtotals?.results[1]?.americanOdds) > 0 ? `+${FHtotals?.results[1]?.americanOdds}` : FHtotals?.results[1]?.americanOdds))}`}</b></p>
                                                                            </div> :
                                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                    }
                                                                    <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHtotals?.results[1]?.odds : FHtotals?.results[1]?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.results[1]?.odds : FHtotalsOld?.results[1]?.americanOdds) : 0)}`} />
                                                                    {getCheckSelect_V3(participants[1].name.value, "First Half Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md ${getCheckSelect_V3(participants[1].name.value, "First Half Total")}`}
                                                                >
                                                                    <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}

                                                            {FHmoneyLine?.visibility === 'Visible' ? (
                                                                <div
                                                                    className={
                                                                        ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`}
                                                                >
                                                                    {
                                                                        FHmoneyLine?.results[1]?.visibility === "Visible" && FHmoneyLine?.results[1]?.odds ?
                                                                            <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                (decimal !== 'American' ? FHmoneyLine?.results[1]?.odds : FHmoneyLine?.results[1]?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.results[1]?.odds : FHmoneyLineOld?.results[1]?.americanOdds) : 0,
                                                                                participants[1].name.value, "First Half Money", startDate, listChooseBet,
                                                                                () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? FHmoneyLine?.results[1].odds?.toString() : FHmoneyLine?.results[1].americanOdds?.toString()), "First Half Money", getChangeColorText((decimal !== 'American' ? FHmoneyLine?.results[1]?.odds : FHmoneyLine?.results[1]?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.results[1]?.odds : FHmoneyLineOld?.results[1]?.americanOdds) : 0),
                                                                                    item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                    item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                    FHmoneyLine?.results[1]?.id
                                                                                )
                                                                            )}`}
                                                                                onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? FHmoneyLine?.results[1].odds?.toString() : FHmoneyLine?.results[1].americanOdds?.toString()), "First Half Money", FHmoneyLine?.results[1]?.id, 0)}
                                                                            >
                                                                                <b className='text-[#D4B962] xs:text-xs md:xs:text-xs md:text-sm'>{(decimal !== 'American' ? FHmoneyLine?.results[1]?.odds.toFixed(2) : (Number(FHmoneyLine?.results[1]?.americanOdds) > 0 ? `+${FHmoneyLine?.results[1]?.americanOdds}` : FHmoneyLine?.results[1]?.americanOdds))}</b>
                                                                            </div> :
                                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                    }
                                                                    <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHmoneyLine?.results[1]?.odds : FHmoneyLine?.results[1]?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.results[1]?.odds : FHmoneyLineOld?.results[1]?.americanOdds) : 0)}`} />
                                                                    {getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet)}`}
                                                                >
                                                                    <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {/* 5 Hiệp đầu của MLB */}
                                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 2 ? 'w-full' : 'hidden')}`}>
                                                            {spread5Innings?.visibility === 'Visible' ? (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                                        }
                                                                    >
                                                                        {
                                                                            spread5Innings?.results?.[1]?.visibility === "Visible" && spread5Innings?.results[1]?.attr ?
                                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                    (decimal !== 'American' ? spread5Innings?.results[1]?.odds : spread5Innings?.results[1]?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.results[1]?.odds : spread5InningsOld?.results[1]?.americanOdds) : 0,
                                                                                    participants[1].name.value, "First Half Spread", startDate, listChooseBet,
                                                                                    () => checkUpdateStrightBetsSlip(participants[1].name.value, spread5Innings?.results[1]?.attr + " " + (decimal !== 'American' ? spread5Innings?.results[1]?.odds : spread5Innings?.results[1]?.americanOdds), "First Half Spread", getChangeColorText((decimal !== 'American' ? spread5Innings?.results[1]?.odds : spread5Innings?.results[1]?.americanOdds), spread5InningsOld ? spread5InningsOld?.results[1]?.odds : 0),
                                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                        spread5Innings?.results[1]?.id
                                                                                    )
                                                                                )}`}
                                                                                    onClick={() => handelBet?.(participants[1].name.value, spread5Innings?.results[1]?.attr + " " + (decimal !== 'American' ? spread5Innings?.results[1]?.odds : (Number(spread5Innings?.results[1]?.americanOdds) > 0 ? '+' + spread5Innings?.results[1]?.americanOdds : spread5Innings?.results[1]?.americanOdds)), "First Half Spread", spread5Innings?.results[1]?.id, 0)}
                                                                                >
                                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spread5Innings?.results[1]?.attr}`}</p>
                                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? spread5Innings?.results[1]?.odds.toFixed(2) : spread5Innings?.results[1]?.americanOdds)}`}</b></p>

                                                                                </div> :
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread5Innings?.results[1]?.odds : spread5Innings?.results[1]?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.results[1]?.odds : spread5InningsOld?.results[1]?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                </>



                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet)}`}
                                                                >
                                                                    <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}

                                                            {totals5Innings?.visibility === 'Visible' ? (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                                        }
                                                                    >
                                                                        {
                                                                            totals5Innings?.results?.[1]?.visibility === "Visible" && totals5Innings?.results[1]?.attr ?
                                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                    (decimal !== 'American' ? totals5Innings?.results[1]?.odds : totals5Innings?.results[1]?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.results[1]?.odds : totals5InningsOld?.results[1]?.americanOdds) : 0,
                                                                                    participants[1].name.value, "First Half Total", startDate, listChooseBet,
                                                                                    () => checkUpdateStrightBetsSlip(participants[1].name.value, totals5Innings?.results[1]?.attr + " " + (decimal !== 'American' ? totals5Innings?.results[1]?.odds : totals5Innings?.results[1]?.americanOdds), "First Half Total", getChangeColorText((decimal !== 'American' ? totals5Innings?.results[1]?.odds : totals5Innings?.results[1]?.americanOdds), totals5InningsOld ? totals5InningsOld?.results[1]?.odds : 0),
                                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                        totals5Innings?.results[1]?.id
                                                                                    )
                                                                                )}`}
                                                                                    onClick={() => handelBet?.(participants[1].name.value, totals5Innings?.results[1]?.attr + " " + (decimal !== 'American' ? totals5Innings?.results[1]?.odds : (Number(totals5Innings?.results[1]?.americanOdds) > 0 ? '+' + totals5Innings?.results[1]?.americanOdds : totals5Innings?.results[1]?.americanOdds)), "First Half Total", totals5Innings?.results[1]?.id, 0)}
                                                                                >
                                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totals5Innings?.results[1]?.attr}`}</p>
                                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? totals5Innings?.results[1]?.odds.toFixed(2) : totals5Innings?.results[1]?.americanOdds)}`}</b></p>

                                                                                </div> :
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals5Innings?.results[1]?.odds : totals5Innings?.results[1]?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.results[1]?.odds : totals5InningsOld?.results[1]?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                </>



                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Total", listChooseBet)}`}
                                                                >
                                                                    <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}


                                                            {moneyLine5Innings?.visibility === 'Visible' ? (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                                        }
                                                                    >
                                                                        {
                                                                            moneyLine5Innings?.results?.[1]?.visibility === "Visible" && moneyLine5Innings?.results[1]?.attr ?
                                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                                    (decimal !== 'American' ? moneyLine5Innings?.results[1]?.odds : moneyLine5Innings?.results[1]?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.results[1]?.odds : moneyLine5InningsOld?.results[1]?.americanOdds) : 0,
                                                                                    participants[1].name.value, "First Half Money", startDate, listChooseBet,
                                                                                    () => checkUpdateStrightBetsSlip(participants[1].name.value, moneyLine5Innings?.results[1]?.attr + " " + (decimal !== 'American' ? moneyLine5Innings?.results[1]?.odds : moneyLine5Innings?.results[1]?.americanOdds), "First Half Money", getChangeColorText((decimal !== 'American' ? moneyLine5Innings?.results[1]?.odds : moneyLine5Innings?.results[1]?.americanOdds), moneyLine5InningsOld ? moneyLine5InningsOld?.results[1]?.odds : 0),
                                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                        moneyLine5Innings?.results[1]?.id
                                                                                    )
                                                                                )}`}
                                                                                    onClick={() => handelBet?.(participants[1].name.value, moneyLine5Innings?.results[1]?.attr + " " + (decimal !== 'American' ? moneyLine5Innings?.results[1]?.odds : (Number(moneyLine5Innings?.results[1]?.americanOdds) > 0 ? '+' + moneyLine5Innings?.results[1]?.americanOdds : moneyLine5Innings?.results[1]?.americanOdds)), "First Half Money", moneyLine5Innings?.results[1]?.id, 0)}
                                                                                >
                                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${moneyLine5Innings?.results[1]?.attr}`}</p>
                                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="">{`${(decimal !== 'American' ? moneyLine5Innings?.results[1]?.odds.toFixed(2) : moneyLine5Innings?.results[1]?.americanOdds)}`}</b></p>

                                                                                </div> :
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine5Innings?.results[1]?.odds : moneyLine5Innings?.results[1]?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.results[1]?.odds : moneyLine5InningsOld?.results[1]?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                </>



                                                            ) : (
                                                                <div
                                                                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet)}`}
                                                                >
                                                                    <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </>
                                                }


                                            </>
                                        ) : (
                                            <>
                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                                    <div
                                                        className={
                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div
                                                        className={
                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div
                                                        className={
                                                            `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>

                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>

                                            </>
                                        )}

                                        {/* Nếu mở live thì mở ở đây */}

                                    </div>


                                </div>
                            }

                        </div >
                    )}

                {
                    games.length === 0 && (
                        <div className={`!pl-0 mb-2 bg-[#131620] rounded-2xl flex  w-full justify-between ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
                        >
                            <Tooltip placement="right">
                                <div className='flex flex-col w-[30%]'>
                                    <div className="gameTime bg-[#131620] flex  items-center h-full py-1">
                                        <div className='flex flex-col justify-between items-start gap-3 h-full w-full'>
                                            <div className='flex items-center  justify-between xs:gap-2 md:gap-0 xs:w-full md:w-[160px]  xl2:w-[190px]'>
                                                <div className='flex items-center gap-2'>
                                                    <img
                                                        className='w-[30px] h-[30px]'
                                                        // src={LogoWithoutText}
                                                        src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[0].image?.logo) : (ICON_TEAM + `7/` + participants[0].image?.logo)}
                                                        alt="team1"
                                                        onError={(e) => {
                                                            e.currentTarget.src = LogoWithoutText;
                                                        }} />
                                                    <span className='xs:text-[10px] xl:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[0].name.short}</span>
                                                </div>

                                                <b className='xs:text-[10px] xl:text-sm text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
                                            </div>
                                            {/* <span className='text-[#D4B962] text-base'>VS</span> */}
                                            <div className='flex items-center  justify-between xs:gap-2 md:gap-0 xs:w-full md:w-[160px]  xl2:w-[190px]'>
                                                <div className='flex items-center gap-2'>
                                                    <img
                                                        className=' w-[30px] h-[30px]'
                                                        // src={LogoWithoutText}
                                                        src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[1].image?.logo) : (ICON_TEAM + `7/` + participants[1].image?.logo)}
                                                        alt="team1"
                                                        onError={(e) => {
                                                            e.currentTarget.src = LogoWithoutText;
                                                        }} />
                                                    <span className='xs:text-[10px] xl:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[1].name.short}</span>
                                                </div>

                                                <b className='xs:text-[10px] xl:text-sm  text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Tooltip>
                            {item.scoreboard?.period === '9th Inning' || item.scoreboard?.period === '10th Inning' || item.scoreboard?.period === '11th Inning' || item.scoreboard?.period === '12th Inning' || item.scoreboard?.period === '13th Inning' || item.scoreboard?.period === '14th Inning' || item.scoreboard?.period === '15th Inning' ?
                                <div className="gameRatio xs:w-[50%]  xl:w-[500px]  xl3:w-[800px] flex flex-col gap-3 justify-center">
                                    <div className="homeRaito flex gap-2">

                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="homeRaito flex  gap-2">

                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                        <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                :
                                <div className="gameRatio xs:w-[50%]  xl:w-[500px]  xl3:w-[800px] flex flex-col gap-3 justify-center">
                                    <div className="homeRaito flex gap-2 ">

                                        {(sport === "NFL" || sport === "NBA" || sport !== "") && (item.scoreboard?.period === "Halftime" || item.scoreboard?.period !== "Halftime") ? (
                                            <>
                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                                    {spreadOption?.status === 'Visible' ? (
                                                        <div
                                                            className={
                                                                `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]
                            `
                                                            }
                                                        >
                                                            {
                                                                spreadOption?.options[0]?.status === 'Visible' && spreadOption?.options[0]?.attr ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[0]?.price?.odds : spreadOld?.options[0]?.price?.americanOdds) : 0,
                                                                        participants[0].name.value, "Spread", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, spreadOption?.options[0]?.attr + " " + (decimal !== 'American' ? spreadOption?.options[0]?.price?.odds?.toString() : spreadOption?.options[0]?.price?.americanOdds?.toString()), "Spread", getChangeColorText((decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[0]?.price?.odds : spreadOld?.options[0]?.price?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            spreadOption?.options[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, spreadOption?.options[0]?.attr + " " + (decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), "Spread", spreadOption?.options[0]?.id, 0)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spreadOption?.options[0]?.attr}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="">{`${(decimal !== 'American' ? spreadOption?.options[0]?.price?.odds.toFixed(2) : (Number(spreadOption?.options[0]?.price?.americanOdds) > 0 ? `+${spreadOption?.options[0]?.price?.americanOdds}` : spreadOption?.options[0]?.price?.americanOdds))}`}</b></p>

                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[0]?.price?.odds : spreadOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {totalsOption?.status === 'Visible' ? (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                totalsOption?.options[0]?.status === 'Visible' && totalsOption?.options[0]?.name.value ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3((decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[0]?.price?.odds : totalsOld?.options[0]?.price?.americanOdds) : 0,
                                                                        participants[0].name.value, "Total", startDate, listChooseBet,
                                                                        // totals?.results[0]?.name.value
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, totalsOption?.options[0]?.name.value + " " + (decimal !== 'American' ? totalsOption?.options[0]?.price?.odds?.toString() : totalsOption?.options[0]?.price?.americanOdds?.toString()), "Total", getChangeColorText((decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[0]?.price?.odds : totalsOld?.options[0]?.price?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            totalsOption?.options[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, totalsOption?.options[0]?.name.value + " " + (decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), "Total", totalsOption?.options[0]?.id, 0)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totalsOption?.options[0]?.name.value}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="">{`${(decimal !== 'American' ? totalsOption?.options[0]?.price?.odds.toFixed(2) : (Number(totalsOption?.options[0]?.price?.americanOdds) > 0 ? `+${totalsOption?.options[0]?.price?.americanOdds}` : totalsOption?.options[0]?.price?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[0]?.price?.odds : totalsOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}

                                                    {moneyLineOption?.status === 'Visible' ? (
                                                        <div
                                                            className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                moneyLineOption?.options[0]?.status === 'Visible' && moneyLineOption?.options[0]?.price?.odds ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds : moneyLineOption?.options[0]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[0]?.price?.odds : moneyLineOld?.options[0]?.price?.americanOdds) : 0,
                                                                        participants[0].name.value, "Money", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds?.toString() : moneyLineOption?.options[0]?.price?.americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds : moneyLineOption?.options[0]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[0]?.price?.odds : moneyLineOld?.options[0]?.price?.americanOdds,
                                                                            moneyLineOld?.options[0]?.id) : 0)
                                                                            ,
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds?.toString() : moneyLineOption?.options[0]?.price?.americanOdds?.toString()), "Money", moneyLineOption?.options[0]?.id, 0)}
                                                                    >
                                                                        <b className='text-[#D4B962] xs:text-xs md:xs:text-xs md:text-sm'>{(decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds?.toFixed(2) : (Number(moneyLineOption?.options[0]?.price?.americanOdds) > 0 ? `+${moneyLineOption?.options[0]?.price?.americanOdds}` : moneyLineOption?.options[0]?.price?.americanOdds))}</b>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds : moneyLineOption?.options[0]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[0]?.price?.odds : moneyLineOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className={`grid xs:grid-cols-1 xl:grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                                    {(sport === "NFL" || sport === "NBA") ?
                                                        <>
                                                            {/* Hiệp 1 của NFL và NBA */}
                                                            <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 2 ? 'w-full' : 'hidden')}`}>
                                                                {FHspread?.status === 'Visible' ? (
                                                                    <div
                                                                        className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10  ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? FHspread?.options[0]?.price?.odds : FHspread?.options[0]?.price?.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.options[0]?.price?.odds : FHspreadOld?.options[0]?.price?.americanOdds) : 0,
                                                                            participants[0].name.value, "First Half Spread", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? FHspread?.options[0]?.price?.odds?.toString() : FHspread?.options[0]?.price?.americanOdds?.toString()), "First Half Spread", getChangeColorText((decimal !== 'American' ? FHspread?.options[0]?.price?.odds : FHspread?.options[0]?.price?.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.options[0]?.price?.odds : FHspreadOld?.options[0]?.price?.americanOdds,
                                                                                FHspreadOld?.options[0]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )}
                                                                         ${getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            FHspread?.options[0]?.status === 'Visible' && FHspread?.options[0]?.attr ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[0].name.value, FHspread?.options[0]?.attr + " " + (decimal !== 'American' ? FHspread?.options[0]?.price?.odds : FHspread?.options[0]?.price?.americanOdds), "First Half Spread", FHspread?.options[0]?.id, 0)}
                                                                                >
                                                                                    <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${FHspread?.options[0]?.attr}`}</span>
                                                                                    <span><b className='!text-[#D4B962] xs:text-xs md:text-sm '>{`${(decimal !== 'American' ? FHspread?.options[0]?.price?.odds.toFixed(2) : (Number(FHspread?.options[0]?.price?.americanOdds) > 0 ? `+${FHspread?.options[0]?.price?.americanOdds}` : FHspread?.options[0]?.price?.americanOdds))}`}</b></span>
                                                                                </div> :
                                                                                <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }

                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHspread?.options[0]?.price.odds : FHspread?.options[0]?.price.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.options[0]?.price.odds : FHspreadOld?.options[0]?.price.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet)}`}
                                                                    >
                                                                        <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}


                                                                {FHtotals?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? FHtotals?.options[0]?.price?.odds : FHtotals?.options[0]?.price?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.options[0]?.price?.odds : FHtotalsOld?.options[0]?.price?.americanOdds) : 0,
                                                                            participants[0].name.value, "First Half Total", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? FHtotals?.options[0]?.price?.odds?.toString() : FHtotals?.options[0]?.price?.americanOdds?.toString()), "First Half Total", getChangeColorText((decimal !== 'American' ? FHtotals?.options[0]?.price?.odds : FHtotals?.options[0]?.price?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.options[0]?.price?.odds : FHtotalsOld?.options[0]?.price?.americanOdds,
                                                                                FHtotalsOld?.options[0]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )}
                                                                        ${getCheckSelect_V3(participants[0].name.value, "First Half Total", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            FHtotals?.options[0]?.status === 'Visible' && FHtotals?.options[0]?.name.value ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[0].name.value, FHtotals?.options[0]?.name.value + " " + (decimal !== 'American' ? FHtotals?.options[0]?.price.odds : FHtotals?.options[0]?.price.americanOdds), "First Half Total", FHtotals?.options[0]?.id, 0)}
                                                                                >
                                                                                    <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${FHtotals?.options[0]?.name.value}`}</span>
                                                                                    <span><b className='text-[#D4B962] xs:text-xs md:text-sm'>{`${(decimal !== 'American' ? FHtotals?.options[0]?.price.odds.toFixed(2) : (Number(FHtotals?.options[0]?.price.americanOdds) > 0 ? `+${FHtotals?.options[0]?.price.americanOdds}` : FHtotals?.options[0]?.price.americanOdds))}`}</b></span>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHtotals?.options[0]?.price.odds : FHtotals?.options[0]?.price.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.options[0]?.price.odds : FHtotalsOld?.options[0]?.price.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md ${getCheckSelect_V3(participants[0].name.value, "First Half Total"), listChooseBet}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}


                                                                {FHmoneyLine?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? FHmoneyLine?.options[0]?.price?.odds : FHmoneyLine?.options[0]?.price?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.options[0]?.price?.odds : FHmoneyLineOld?.options[0]?.price?.americanOdds) : 0,
                                                                            participants[0].name.value, "First Half Money", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? FHmoneyLine?.options[0]?.price?.odds?.toString() : FHmoneyLine?.options[0]?.price?.americanOdds?.toString()), "First Half Money", getChangeColorText((decimal !== 'American' ? FHmoneyLine?.options[0]?.price?.odds : FHmoneyLine?.options[0]?.price?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.options[0]?.price?.odds : FHmoneyLineOld?.options[0]?.price?.americanOdds,
                                                                                FHmoneyLineOld?.options[0]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )}
                                                                        ${getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            FHmoneyLine?.options[0]?.status === 'Visible' && FHmoneyLine?.options[0]?.price?.odds ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? FHmoneyLine?.options[0]?.price.odds?.toString() : FHmoneyLine?.options[0]?.price.americanOdds?.toString()), "First Half Money", FHmoneyLine?.options[0]?.id, 0)}
                                                                                >
                                                                                    <b className='text-[#D4B962] xs:text-xs md:text-sm'>{(decimal !== 'American' ? FHmoneyLine?.options[0]?.price.odds.toFixed(2) : (Number(FHmoneyLine?.options[0]?.price.americanOdds) > 0 ? `+${FHmoneyLine?.options[0]?.price.americanOdds}` : FHmoneyLine?.options[0]?.price.americanOdds))}</b>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }

                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHmoneyLine?.options[0]?.price.odds : FHmoneyLine?.options[0]?.price.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.options[0]?.price.odds : FHmoneyLineOld?.options[0]?.price.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet)}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {/* 5 hiệp đầu của MLB */}
                                                            <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 2 ? 'w-full' : 'hidden')}`}>
                                                                {spread5Innings?.status === 'Visible' ? (
                                                                    <div
                                                                        className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds : spread5Innings?.options[0]?.price?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.options[0]?.price?.odds : spread5InningsOld?.options[0]?.price?.americanOdds) : 0,
                                                                            participants[0].name.value, "First Half Spread", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds?.toString() : spread5Innings?.options[0]?.price?.americanOdds?.toString()), "First Half Total", getChangeColorText((decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds : spread5Innings?.options[0]?.price?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.options[0]?.price?.odds : spread5InningsOld?.options[0]?.price?.americanOdds,
                                                                                spread5InningsOld?.options[0]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )} ${getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            spread5Innings?.options[0]?.status === 'Visible' && spread5Innings?.options[0]?.attr ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[0].name.value, spread5Innings?.options[0]?.attr + " " + (decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds : spread5Innings?.options[0]?.price?.americanOdds), "First Half Spread", spread5Innings?.options[0]?.id, 0)}
                                                                                >
                                                                                    <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${spread5Innings?.options[0]?.attr}`}</span>
                                                                                    <span><b className='!text-[#D4B962] xs:text-xs md:text-sm '>{`${(decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds.toFixed(2) : (Number(spread5Innings?.options[0]?.price?.americanOdds) > 0 ? `+${spread5Innings?.options[0]?.price?.americanOdds}` : spread5Innings?.options[0]?.price?.americanOdds))}`}</b></span>
                                                                                </div> :
                                                                                <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }

                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds : spread5Innings?.options[0]?.price?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.options[0]?.price?.odds : spread5InningsOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[0].name.value, "First Half Spread", listChooseBet)}`}
                                                                    >
                                                                        <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}


                                                                {totals5Innings?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? totals5Innings?.options[0]?.price?.odds : totals5Innings?.options[0]?.price?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.options[0]?.price?.odds : totals5InningsOld?.options[0]?.price?.americanOdds) : 0,
                                                                            participants[0].name.value, "First Half Total", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? totals5Innings?.options[0]?.price?.odds?.toString() : totals5Innings?.options[0]?.price?.americanOdds?.toString()), "First Half Total", getChangeColorText((decimal !== 'American' ? totals5Innings?.options[0]?.price?.odds : totals5Innings?.options[0]?.price?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.options[0]?.price?.odds : totals5InningsOld?.options[0]?.price?.americanOdds,
                                                                                totals5InningsOld?.options[0]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )} ${getCheckSelect_V3(participants[0].name.value, "First Half Total", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            totals5Innings?.options[0]?.status === 'Visible' && totals5Innings?.options[0]?.name.value ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[0].name.value, totals5Innings?.options[0]?.name.value + " " + (decimal !== 'American' ? totals5Innings?.options[0]?.price.odds : totals5Innings?.options[0]?.price.americanOdds), "First Half Total", totals5Innings?.options[0]?.id, 0)}
                                                                                >
                                                                                    <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${totals5Innings?.options[0]?.name.value}`}</span>
                                                                                    <span><b className='text-[#D4B962] xs:text-xs md:text-sm'>{`${(decimal !== 'American' ? totals5Innings?.options[0]?.price.odds.toFixed(2) : (Number(totals5Innings?.options[0]?.price.americanOdds) > 0 ? `+${totals5Innings?.options[0]?.price.americanOdds}` : totals5Innings?.options[0]?.price.americanOdds))}`}</b></span>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals5Innings?.options[0]?.price?.odds : totals5Innings?.options[0]?.price?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.options[0]?.price?.odds : totals5InningsOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md ${getCheckSelect_V3(participants[0].name.value, "First Half Total")}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}


                                                                {moneyLine5Innings?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? moneyLine5Innings?.options[0]?.price?.odds : moneyLine5Innings?.options[0]?.price?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.options[0]?.price?.odds : moneyLine5InningsOld?.options[0]?.price?.americanOdds) : 0,
                                                                            participants[0].name.value, "First Half Money", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? moneyLine5Innings?.options[0]?.price?.odds?.toString() : moneyLine5Innings?.options[0]?.price?.americanOdds?.toString()), "First Half Money", getChangeColorText((decimal !== 'American' ? moneyLine5Innings?.options[0]?.price?.odds : moneyLine5Innings?.options[0]?.price?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.options[0]?.price?.odds : moneyLine5InningsOld?.options[0]?.price?.americanOdds,
                                                                                moneyLine5InningsOld?.options[0]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )} ${getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            moneyLine5Innings?.options[0]?.status === 'Visible' && moneyLine5Innings?.options[0]?.price?.odds ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLine5Innings?.options[0]?.price.odds?.toString() : moneyLine5Innings?.options[0]?.price.americanOdds?.toString()), "First Half Money", moneyLine5Innings?.options[0]?.id, 0)}
                                                                                >
                                                                                    <b className='text-[#D4B962] xs:text-xs md:text-sm'>{(decimal !== 'American' ? moneyLine5Innings?.options[0]?.price.odds.toFixed(2) : (Number(moneyLine5Innings?.options[0]?.price.americanOdds) > 0 ? `+${moneyLine5Innings?.options[0]?.price.americanOdds}` : moneyLine5Innings?.options[0]?.price.americanOdds))}</b>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine5Innings?.options[0]?.price?.odds : moneyLine5Innings?.options[0]?.price?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.options[0]?.price?.odds : moneyLine5InningsOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[0].name.value, "First Half Money", listChooseBet)}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                            </>
                                        ) : (
                                            <>
                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>
                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {/* Nếu mở live thì mở ở đây */}
                                    </div>

                                    <div className="awayRaito flex gap-2">

                                        {(sport === "NFL" || sport === "NBA" || sport !== "") && (item.scoreboard?.period === "Halftime" || item.scoreboard?.period !== "Halftime") ? (
                                            <>
                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                                    {spreadOption?.status === 'Visible' ? (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                spreadOption?.options[1]?.status === 'Visible' && spreadOption?.options[1]?.attr ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[1]?.price?.odds : spreadOld?.options[1]?.price?.americanOdds) : 0,
                                                                        participants[1].name.value, "Spread", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? spreadOption?.options[1]?.attr + " " + spreadOption?.options[1]?.price?.odds?.toString() : spreadOption?.options[1]?.attr + " " + spreadOption?.options[1]?.price?.americanOdds?.toString()), "Spread", getChangeColorText((decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[1]?.price?.odds : spreadOld?.options[1]?.price?.americanOdds,
                                                                            spreadOld?.options[1]?.id) : 0)
                                                                            ,
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, spreadOption?.options[1]?.attr + " " + (decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), "Spread", spreadOption?.options[1]?.id, 1)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spreadOption?.options[1]?.attr}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="">{`${(decimal !== 'American' ? spreadOption?.options[1]?.price?.odds.toFixed(2) : (Number(spreadOption?.options[1]?.price?.americanOdds) > 0 ? `+${spreadOption?.options[1]?.price?.americanOdds}` : spreadOption?.options[1]?.price?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[1]?.price?.odds : spreadOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[1].name.value, "Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {totalsOption?.status === 'Visible' ? (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                totalsOption?.options[1]?.status === 'Visible' && totalsOption?.options[1]?.name.value ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[1]?.price?.odds : totalsOld?.options[1]?.price?.americanOdds) : 0,
                                                                        participants[1].name.value, "Total", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, totalsOption?.options[1]?.name.value + " " + totalsOption?.options[1]?.price?.odds?.toString(), "Total", getChangeColorText((decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[1]?.price?.odds : totalsOld?.options[1]?.price?.americanOdds,
                                                                            totalsOld?.options[1]?.id) : 0)
                                                                            ,
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, totalsOption?.options[1]?.name.value + " " + (decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), "Total", totalsOption?.options[1]?.id, 1)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totalsOption?.options[1]?.name.value}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="">{`${(decimal !== 'American' ? totalsOption?.options[1]?.price?.odds.toFixed(2) : (Number(totalsOption?.options[1]?.price?.americanOdds) > 0 ? `+${totalsOption?.options[1]?.price?.americanOdds}` : totalsOption?.options[1]?.price?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[1]?.price?.odds : totalsOld?.options[1]?.price?.americanOdds) : 0)}`} />

                                                            {getCheckSelect_V3(participants[1].name.value, "Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {moneyLineOption?.status === 'Visible' ? (
                                                        <div
                                                            className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                moneyLineOption?.options[1]?.status === 'Visible' && moneyLineOption?.options[1]?.price?.odds ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds : moneyLineOption?.options[1]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[1]?.price?.odds : moneyLineOld?.options[1]?.price?.americanOdds) : 0,
                                                                        participants[1].name.value, "Money", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds?.toString() : moneyLineOption?.options[1]?.price?.americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds : moneyLineOption?.options[1]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[1]?.price?.odds : moneyLineOld?.options[1]?.price?.americanOdds,
                                                                            moneyLineOld?.options[1]?.id) : 0)
                                                                            ,
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds?.toString() : moneyLineOption?.options[1]?.price?.americanOdds?.toString()), "Money", moneyLineOption?.options[1]?.id, 1)}
                                                                    ><b className='text-[#D4B962] xs:text-xs md:xs:text-xs md:text-sm'>{(decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds.toFixed(2) : (Number(moneyLineOption?.options[1]?.price?.americanOdds) > 0 ? `+${moneyLineOption?.options[1]?.price?.americanOdds}` : moneyLineOption?.options[1]?.price?.americanOdds))}</b>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds : moneyLineOption?.options[1]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[1]?.price?.odds : moneyLineOld?.options[1]?.price?.americanOdds) : 0)}`} />

                                                            {getCheckSelect_V3(participants[1].name.value, "Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}
                                                </div>


                                                <div className={`grid xs:grid-cols-1 xl:grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                                    {(sport === "NFL" || sport === "NBA") ?
                                                        <>
                                                            {/* Hiệp 1 của NFL và NBA */}
                                                            <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                                                {FHspread?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? FHspread?.options[1]?.price?.odds : FHspread?.options[1]?.price?.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.options[1]?.price?.odds : FHspreadOld?.options[1]?.price?.americanOdds) : 0,
                                                                            participants[1].name.value, "First Half Spread", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? FHspread?.options[1]?.attr + " " + FHspread?.options[1]?.price?.odds?.toString() : FHspread?.options[1]?.attr + " " + FHspread?.options[1]?.price?.americanOdds?.toString()), "First Half Spread", getChangeColorText((decimal !== 'American' ? FHspread?.options[1]?.price?.odds : FHspread?.options[1]?.price?.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.options[1]?.price?.odds : FHspreadOld?.options[1]?.price?.americanOdds,
                                                                                FHspreadOld?.options[1]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )} ${getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            FHspread?.options[1]?.status === 'Visible' && FHspread?.options[1]?.attr ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[1].name.value, FHspread?.options[1]?.attr + " " + (decimal !== 'American' ? FHspread?.options[1]?.price?.odds : FHspread?.options[1]?.price?.americanOdds), "First Half Spread", FHspread?.options[1]?.id, 1)}
                                                                                >
                                                                                    <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${FHspread?.options[1]?.attr}`}</span>
                                                                                    <span><b className='text-[#D4B962] xs:text-xs md:text-sm '>{`${(decimal !== 'American' ? FHspread?.options[1]?.price?.odds.toFixed(2) : (Number(FHspread?.options[1]?.price?.americanOdds) > 0 ? `+${FHspread?.options[1]?.price?.americanOdds}` : FHspread?.options[1]?.price?.americanOdds))}`}</b></span>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHspread?.options[1]?.price?.odds : FHspread?.options[1]?.price?.americanOdds), FHspreadOld ? (decimal !== 'American' ? FHspreadOld?.options[1]?.price?.odds : FHspreadOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet)}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}
                                                                {FHtotals?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? FHtotals?.options[1]?.price?.odds : FHtotals?.options[1]?.price?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.options[1]?.price?.odds : FHtotalsOld?.options[1]?.price?.americanOdds) : 0,
                                                                            participants[1].name.value, "First Half Total", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? FHtotals?.options[1]?.attr + " " + FHtotals?.options[1]?.price?.odds?.toString() : FHtotals?.options[1]?.attr + " " + FHtotals?.options[1]?.price?.americanOdds?.toString()), "First Half Total", getChangeColorText((decimal !== 'American' ? FHtotals?.options[1]?.price?.odds : FHtotals?.options[1]?.price?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.options[1]?.price?.odds : FHtotalsOld?.options[1]?.price?.americanOdds,
                                                                                FHtotalsOld?.options[1]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )} ${getCheckSelect_V3(participants[1].name.value, "First Half Total", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            FHtotals?.options[1]?.status === 'Visible' && FHtotals?.options[1]?.name.value ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[1].name.value, FHtotals?.options[1]?.name.value + " " + (decimal !== 'American' ? FHtotals?.options[1]?.price.odds : FHtotals?.options[1]?.price.americanOdds), "First Half Total", FHtotals?.options[1]?.id, 1)}
                                                                                >
                                                                                    <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${FHtotals?.options[1]?.name.value}`}</span>
                                                                                    <span><b className='text-[#D4B962] xs:text-xs md:text-sm '>{`${(decimal !== 'American' ? FHtotals?.options[1]?.price.odds.toFixed(2) : (Number(FHtotals?.options[1]?.price.americanOdds) > 0 ? `+${FHtotals?.options[1]?.price.americanOdds}` : FHtotals?.options[1]?.price.americanOdds))}`}</b></span>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHtotals?.options[1]?.price?.odds : FHtotals?.options[1]?.price?.americanOdds), FHtotalsOld ? (decimal !== 'American' ? FHtotalsOld?.options[1]?.price?.odds : FHtotalsOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Total", listChooseBet)}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}

                                                                {FHmoneyLine?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? FHmoneyLine?.options[1]?.price?.odds : FHmoneyLine?.options[1]?.price?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.options[1]?.price?.odds : FHmoneyLineOld?.options[1]?.price?.americanOdds) : 0,
                                                                            participants[1].name.value, "First Half Money", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? FHmoneyLine?.options[1]?.attr + " " + FHmoneyLine?.options[1]?.price?.odds?.toString() : FHmoneyLine?.options[1]?.attr + " " + FHmoneyLine?.options[1]?.price?.americanOdds?.toString()), "First Half Money", getChangeColorText((decimal !== 'American' ? FHmoneyLine?.options[1]?.price?.odds : FHmoneyLine?.options[1]?.price?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.options[1]?.price?.odds : FHmoneyLineOld?.options[1]?.price?.americanOdds,
                                                                                FHmoneyLineOld?.options[1]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )} ${getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            FHmoneyLine?.options[1]?.status === 'Visible' && FHmoneyLine?.options[1]?.price?.odds ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? FHmoneyLine?.options[1]?.price?.odds?.toString() : FHmoneyLine?.options[1]?.price?.americanOdds?.toString()), "First Half Money", FHmoneyLine?.options[1]?.id, 1)}
                                                                                ><b className='text-[#D4B962] xs:text-xs md:text-sm '>{(decimal !== 'American' ? FHmoneyLine?.options[1]?.price?.odds.toFixed(2) : (Number(FHmoneyLine?.options[1]?.price?.americanOdds) > 0 ? `+${FHmoneyLine?.options[1]?.price?.americanOdds}` : FHmoneyLine?.options[1]?.price?.americanOdds))}</b>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? FHmoneyLine?.options[1]?.price?.odds : FHmoneyLine?.options[1]?.price?.americanOdds), FHmoneyLineOld ? (decimal !== 'American' ? FHmoneyLineOld?.options[1]?.price?.odds : FHmoneyLineOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet)}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {/* 5 Hiệp đầu của MLB */}
                                                            <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                                                {spread5Innings?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? spread5Innings?.options[1]?.price?.odds : spread5Innings?.options[1]?.price?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.options[1]?.price?.odds : spread5InningsOld?.options[1]?.price?.americanOdds) : 0,
                                                                            participants[1].name.value, "First Half Spread", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? spread5Innings?.options[1]?.attr + " " + spread5Innings?.options[1]?.price?.odds?.toString() : spread5Innings?.options[1]?.attr + " " + spread5Innings?.options[1]?.price?.americanOdds?.toString()), "First Half Spread", getChangeColorText((decimal !== 'American' ? spread5Innings?.options[1]?.price?.odds : spread5Innings?.options[1]?.price?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.options[1]?.price?.odds : spread5InningsOld?.options[1]?.price?.americanOdds,
                                                                                spread5InningsOld?.options[1]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )} ${getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            spread5Innings?.options[1]?.status === 'Visible' && spread5Innings?.options[1]?.attr ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[1].name.value, spread5Innings?.options[1]?.attr + " " + (decimal !== 'American' ? spread5Innings?.options[1]?.price?.odds : spread5Innings?.options[1]?.price?.americanOdds), "First Half Spread", spread5Innings?.options[1]?.id, 1)}
                                                                                >
                                                                                    <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${spread5Innings?.options[1]?.attr}`}</span>
                                                                                    <span><b className='text-[#D4B962] xs:text-xs md:text-sm '>{`${(decimal !== 'American' ? spread5Innings?.options[1]?.price?.odds.toFixed(2) : (Number(spread5Innings?.options[1]?.price?.americanOdds) > 0 ? `+${spread5Innings?.options[1]?.price?.americanOdds}` : spread5Innings?.options[1]?.price?.americanOdds))}`}</b></span>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread5Innings?.options[1]?.price?.odds : spread5Innings?.options[1]?.price?.americanOdds), spread5InningsOld ? (decimal !== 'American' ? spread5InningsOld?.options[1]?.price?.odds : spread5InningsOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Spread", listChooseBet)}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}
                                                                {totals5Innings?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? totals5Innings?.options[1]?.price?.odds : totals5Innings?.options[1]?.price?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.options[1]?.price?.odds : totals5InningsOld?.options[1]?.price?.americanOdds) : 0,
                                                                            participants[1].name.value, "First Half Total", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? totals5Innings?.options[1]?.attr + " " + totals5Innings?.options[1]?.price?.odds?.toString() : totals5Innings?.options[1]?.attr + " " + totals5Innings?.options[1]?.price?.americanOdds?.toString()), "First Half Total", getChangeColorText((decimal !== 'American' ? totals5Innings?.options[1]?.price?.odds : totals5Innings?.options[1]?.price?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.options[1]?.price?.odds : totals5InningsOld?.options[1]?.price?.americanOdds,
                                                                                totals5InningsOld?.options[1]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )} ${getCheckSelect_V3(participants[1].name.value, "First Half Total", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            totals5Innings?.options[1]?.status === 'Visible' && totals5Innings?.options[1]?.name.value ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[1].name.value, totals5Innings?.options[1]?.name.value + " " + (decimal !== 'American' ? totals5Innings?.options[1]?.price.odds : totals5Innings?.options[1]?.price.americanOdds), "First Half Total", totals5Innings?.options[1]?.id, 1)}
                                                                                >
                                                                                    <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${totals5Innings?.options[1]?.name.value}`}</span>
                                                                                    <span><b className='text-[#D4B962] xs:text-xs md:text-sm '>{`${(decimal !== 'American' ? totals5Innings?.options[1]?.price.odds.toFixed(2) : (Number(totals5Innings?.options[1]?.price.americanOdds) > 0 ? `+${totals5Innings?.options[1]?.price.americanOdds}` : totals5Innings?.options[1]?.price.americanOdds))}`}</b></span>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals5Innings?.options[1]?.price?.odds : totals5Innings?.options[1]?.price?.americanOdds), totals5InningsOld ? (decimal !== 'American' ? totals5InningsOld?.options[1]?.price?.odds : totals5InningsOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Total", listChooseBet)}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}

                                                                {moneyLine5Innings?.status === 'Visible' ? (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? moneyLine5Innings?.options[1]?.price?.odds : moneyLine5Innings?.options[1]?.price?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.options[1]?.price?.odds : moneyLine5InningsOld?.options[1]?.price?.americanOdds) : 0,
                                                                            participants[1].name.value, "First Half Money", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? moneyLine5Innings?.options[1]?.attr + " " + moneyLine5Innings?.options[1]?.price?.odds?.toString() : moneyLine5Innings?.options[1]?.attr + " " + moneyLine5Innings?.options[1]?.price?.americanOdds?.toString()), "First Half Money", getChangeColorText((decimal !== 'American' ? moneyLine5Innings?.options[1]?.price?.odds : moneyLine5Innings?.options[1]?.price?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.options[1]?.price?.odds : moneyLine5InningsOld?.options[1]?.price?.americanOdds,
                                                                                moneyLine5InningsOld?.options[1]?.id) : 0)
                                                                                ,
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                        )} ${getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet)} hover:!border-[#D4B962]`}
                                                                    >
                                                                        {
                                                                            moneyLine5Innings?.options[1]?.status === 'Visible' && moneyLine5Innings?.options[1]?.price?.odds ?
                                                                                <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                                                                                    onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLine5Innings?.options[1]?.price?.odds?.toString() : moneyLine5Innings?.options[1]?.price?.americanOdds?.toString()), "First Half Money", moneyLine5Innings?.options[1]?.id, 1)}
                                                                                ><b className='text-[#D4B962] xs:text-xs md:text-sm '>{(decimal !== 'American' ? moneyLine5Innings?.options[1]?.price?.odds.toFixed(2) : (Number(moneyLine5Innings?.options[1]?.price?.americanOdds) > 0 ? `+${moneyLine5Innings?.options[1]?.price?.americanOdds}` : moneyLine5Innings?.options[1]?.price?.americanOdds))}</b>
                                                                                </div> :
                                                                                <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                        }
                                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine5Innings?.options[1]?.price?.odds : moneyLine5Innings?.options[1]?.price?.americanOdds), moneyLine5InningsOld ? (decimal !== 'American' ? moneyLine5InningsOld?.options[1]?.price?.odds : moneyLine5InningsOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                                        {getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${getCheckSelect_V3(participants[1].name.value, "First Half Money", listChooseBet)}`}
                                                                    >
                                                                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'w-full' : 'hidden')}`}>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>

                                                <div className={`grid grid-cols-3 xl:w-[250px] xl3:w-[395px] gap-2 h-[40px] ${window.outerWidth >= 1365 ? "w-1/2" : (timeGame === 1 ? 'hidden' : 'w-full')}`}>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {/* Nếu mở live thì mở ở đây */}


                                    </div>
                                </div>
                            }

                        </div >
                    )}
            </div>


        </div>
    )
}

export default GameLiveDeTail_V2;