/* eslint-disable react-hooks/exhaustive-deps */
import { cup, empty, liveIcon, LogoWithoutText, soccer_2 } from "../../../components/imgExport"
import { ItemSoccer } from "./Item/ItemSoccer"
import ToastGlobal from "../../../components/ToastGlobal";
// import { Pagination } from "antd";
import './style.css'
import { formatPrice } from "../../../until";

interface ItemProp {
    billSocket?: any[]
    runningBill?: number[]
    rejectBill?: number[]
    totalRunning?: number
    listBill?: any[]
    setOffset?: (value: number) => void
}

export const Running: React.FC<ItemProp> = ({
    billSocket,
    runningBill,
    rejectBill,
    totalRunning,
    listBill,
    setOffset,
}) => {
    // const pushToast = useToast();
    // const pushLoading = useLoading();
    // const dispatch = useDispatch();
    // const [chooseGame, setChooseGame] = useState<string>('Soccer')
    // const [limit, setLimit] = useState<number>(20)
    // const [offset, setOffset] = useState<number>(0)
    // const [listBill, setListBill] = useState<any>([])
    // const [total, setTotal] = useState<number>(0)
    // const getMyBetRunning = async () => {
    //     pushLoading(true)
    //     var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    //     const ketQua = await myBetApi.myBetRunning(chooseGame, timeZone, limit, offset)
    //     if (ketQua?.success) {
    //         setTotal(ketQua.total ? ketQua.total : 0)
    //         setTotalRunning && setTotalRunning(ketQua.total ? ketQua.total : 0)
    //         setListBill(ketQua.data)
    //     } else {
    //         setTotal(0)
    //         dispatch(clearAlert())
    //         pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
    //         pushLoading(false)
    //         return
    //     }
    //     // 
    //     pushLoading(false)
    // }

    // useEffect(() => {
    //     getMyBetRunning()
    // }, [chooseGame])

    // useEffect(() => {
    //     getMyBetRunning()
    // }, [offset])
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
        <div className="w-full">
            <div className="fixed z-[9999] w-[100vw] flex justify-center top-10 xs:left-0">
                <ToastGlobal />
            </div>
            <div className='w-full border-r border-r-[#202530] border-l border-l-[#202530] border-b border-b-[#202530]'>
                <div className={`xs:px-1 xl:px-6 xl:h-[calc(100vh-140px)] xs:h-[calc(100vh-250px)]`}>
                    {
                        (listBill && listBill.length > 0) || (billSocket && billSocket.length > 0)
                            ? (
                                <div className="flex flex-col">
                                    <div className="xs:hidden xl:flex w-full bg-header rounded-tl-lg rounded-tr-lg">
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[15%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America ">Code & Time</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[55%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America">Game</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[10%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">odds</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[10%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">stake</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] w-[10%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">Status</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col xs:gap-[18px] md:gap-0 w-full xs:h-[calc(100vh-270px)] xl:h-[calc(100vh-185px)] overflow-y-scroll">
                                        {billSocket && billSocket.map((item: any, index: any) => {
                                            if (item?.type === "Parlay") {
                                                return <>
                                                    <div className="xs:hidden md:flex w-full border-b border-b-[#202531] py-5" key={index}>
                                                        <div className="flex justify-center items-center w-[15%] ">
                                                            <div className="flex flex-col items-start gap-3">
                                                                <span className="text-sm text-[#E4E7F1]">{item.billCode}</span>
                                                                <span className="text-sm text-[#E4E7F1]">
                                                                    {`${(new Date(item.data.data[0].date).toLocaleString('en', { timeZone: timeZone })).split(",")[0]}`}
                                                                </span>
                                                                <span className="text-sm text-[#E4E7F1]">
                                                                    {`${(new Date(item.data.data[0].date).toLocaleString('en', { timeZone: timeZone })).split(",")[1]}`}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col gap-2 items-start w-[55%] px-3 ">
                                                            {item.data.data.map((itemBillDetail: any, indexBillDetail: any) => (
                                                                <div className="flex items-center w-full h-full justify-between">
                                                                    <div className="flex flex-col gap-2 w-1/2">
                                                                        <div className="flex gap-3">
                                                                            <div >
                                                                                <img src={cup} alt="" className="h-5" />
                                                                            </div>
                                                                            <span className="text-sm text-[#E4E7F1]">{itemBillDetail.tournament}</span>
                                                                        </div>


                                                                        <div className="flex items-center gap-5 ">
                                                                            <div className='flex items-center gap-2'>
                                                                                <div className='w-[22px] h-[22px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                                                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-4 h-4' />
                                                                                </div>
                                                                                <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[0]}</span>
                                                                            </div>
                                                                            <span className='text-xs text-[#D4B962] pl-2'>VS</span>
                                                                            <div className='flex items-center gap-2'>
                                                                                <div className='w-[22px] h-[22px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                                                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-4 h-4' />
                                                                                </div>
                                                                                <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[1]}</span>
                                                                            </div>
                                                                        </div>

                                                                        {(itemBillDetail.stage === "1" || itemBillDetail.stage === 1 || itemBillDetail.stage === "Live") &&
                                                                            <>
                                                                                <div className="flex gap-4">
                                                                                    <img src={liveIcon} alt="" />
                                                                                    <span className="text-xs text-[#E4E7F1]">{`LIVE ${itemBillDetail.homeLiveScore ? itemBillDetail.homeLiveScore : 0} : ${itemBillDetail.awayLiveScore ? itemBillDetail.awayLiveScore : 0} `}</span>

                                                                                </div>
                                                                            </>

                                                                        }
                                                                        <>
                                                                            {itemBillDetail.sport === "Soccer" || itemBillDetail.sportName !== "Soccer"
                                                                                ?
                                                                                <span className="text-xs text-[#E4E7F1]">{`${new Date(itemBillDetail.dateEvent.slice(5, 7).toString() + "/" + itemBillDetail.dateEvent.slice(8, 10).toString() + "/" + itemBillDetail.dateEvent.slice(0, 4).toString() + " " + itemBillDetail.dateEvent.slice(11, 16) + " GMT-4").toLocaleDateString()}, ${new Date(itemBillDetail.dateEvent.slice(5, 7).toString() + "/" + itemBillDetail.dateEvent.slice(8, 10).toString() + "/" + itemBillDetail.dateEvent.slice(0, 4).toString() + " " + itemBillDetail.dateEvent.slice(11, 16) + " GMT-4").toLocaleTimeString()}`}
                                                                                </span>
                                                                                :

                                                                                <>
                                                                                    {itemBillDetail.sport === "Soccer" || itemBillDetail.sportName !== "Soccer"
                                                                                        ?
                                                                                        <span className="text-xs text-[#E4E7F1]">{`${new Date(itemBillDetail.dateEvent.slice(5, 7).toString() + "/" + itemBillDetail.dateEvent.slice(8, 10).toString() + "/" + itemBillDetail.dateEvent.slice(0, 4).toString() + " " + itemBillDetail.dateEvent.slice(11, 16) + " GMT-4").toLocaleDateString()}, ${new Date(itemBillDetail.dateEvent.slice(5, 7).toString() + "/" + itemBillDetail.dateEvent.slice(8, 10).toString() + "/" + itemBillDetail.dateEvent.slice(0, 4).toString() + " " + itemBillDetail.dateEvent.slice(11, 16) + " GMT-4").toLocaleTimeString()}`}
                                                                                        </span>
                                                                                        :
                                                                                        <span className="text-xs text-[#E4E7F1] ">{`${(new Date(itemBillDetail.dateEvent).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    </div>

                                                                    <div className="flex flex-col items-end gap-2 w-1/2">
                                                                        <div className="flex items-center  gap-2">
                                                                            <img src={soccer_2} alt="" />
                                                                            <span className="text-xs text-[#C1C9E1] font-medium">{itemBillDetail.lineBetting}</span>
                                                                        </div>
                                                                        <div className="rounded-[4px] py-1 bg-[#212531]">
                                                                            <span className="px-3  text-sm text-[#E4E7F1]">{`${itemBillDetail.lineBetting.includes("OU") || itemBillDetail.lineBetting.includes("Total") ? "" : `${item.teamChoose} @`} ${itemBillDetail.scoreChoose}`}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="flex flex-col gap-2 items-center w-[10%]">
                                                            {item.data.data.map((itemBillDetail: any, indexBillDetail: any) => (
                                                                <span className="flex items-center w-full h-full justify-center text-sm text-[#E4E7F1]">{itemBillDetail.scoreChoose.split(' ').length > 1 ? itemBillDetail.scoreChoose.split(' ')[itemBillDetail.scoreChoose.split(' ').length - 1] : itemBillDetail.scoreChoose.split(' ')[0]}</span>
                                                            ))}


                                                        </div>
                                                        <div className="flex justify-center items-center w-[10%]">
                                                            <span className="text-sm text-[#D4B962]">${formatPrice(item.data.totalStake || 0)}</span>
                                                        </div>
                                                        <div className="flex justify-center items-center w-[10%]">
                                                            <span className={`text-sm text-[#33E49A] ${(runningBill && runningBill.includes(Number(item.idBill)) && 'text-[#33E49A]') || (rejectBill && rejectBill.includes(Number(item.idBill)) && 'text-[#FF4A59]') || ('waiting text-[#D4B962]')}`}>
                                                                {runningBill && runningBill.includes(Number(item.idBill)) ? "RUNNING" : (rejectBill && rejectBill.includes(Number(item.idBill)) ? "REJECT" : ("WAITING"))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            } else {
                                                return <>
                                                    <div className="xs:hidden md:flex w-full border-b border-b-[#202531] py-5" key={index}>
                                                        <div className="flex justify-center items-center w-[15%] ">
                                                            <div className="flex flex-col items-start gap-3">
                                                                <span className="text-sm text-[#E4E7F1]">{item.billCode}</span>
                                                                <span className="text-sm text-[#E4E7F1]">
                                                                    {`${(new Date(item.date).toLocaleString('en', { timeZone: timeZone })).split(",")[0]}`}
                                                                </span>
                                                                <span className="text-sm text-[#E4E7F1]">
                                                                    {`${(new Date(item.date).toLocaleString('en', { timeZone: timeZone })).split(",")[1]}`}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col gap-2 items-start w-[55%] px-3 ">
                                                            <div className="flex items-center w-full h-full justify-between">
                                                                <div className="flex flex-col gap-2 w-1/2">
                                                                    <div className="flex gap-3">
                                                                        <div >
                                                                            <img src={cup} alt="" className="h-5" />
                                                                        </div>
                                                                        <span className="text-sm text-[#E4E7F1]">{item.tournament}</span>
                                                                    </div>


                                                                    <div className="flex items-center gap-5 ">
                                                                        <div className='flex items-center gap-2'>
                                                                            <div className='w-[22px] h-[22px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                                                                <img src={LogoWithoutText} alt="NYKnicks" className='w-4 h-4' />
                                                                            </div>
                                                                            <span className='text-[#E4E7F1] fw-[500] text-xs'>{item.game.split('/')[0]}</span>
                                                                        </div>
                                                                        <span className='text-xs text-[#D4B962] pl-2'>VS</span>
                                                                        <div className='flex items-center gap-2'>
                                                                            <div className='w-[22px] h-[22px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                                                                <img src={LogoWithoutText} alt="NYKnicks" className='w-4 h-4' />
                                                                            </div>
                                                                            <span className='text-[#E4E7F1] fw-[500] text-xs'>{item.game.split('/')[1]}</span>
                                                                        </div>
                                                                    </div>

                                                                    {item.stage === "1" || item.stage === 1 || item.stage === "Live" ?
                                                                        <>
                                                                            <div className="flex gap-4">
                                                                                <img src={liveIcon} alt="" />
                                                                                <span className="text-xs text-[#E4E7F1]">{`LIVE ${item.homeLiveScore ? item.homeLiveScore : 0} : ${item.awayLiveScore ? item.awayLiveScore : 0} ${item.sportName}`}</span>
                                                                                {item.sportName === "Soccer"
                                                                                    ?
                                                                                    <span className="text-xs text-[#E4E7F1]">{`${new Date(item.dateEvent.slice(5, 7).toString() + "/" + item.dateEvent.slice(8, 10).toString() + "/" + item.dateEvent.slice(0, 4).toString() + " " + item.dateEvent.slice(11, 16) + " GMT-4").toLocaleDateString()}, ${new Date(item.dateEvent.slice(5, 7).toString() + "/" + item.dateEvent.slice(8, 10).toString() + "/" + item.dateEvent.slice(0, 4).toString() + " " + item.dateEvent.slice(11, 16) + " GMT-4").toLocaleTimeString()}`}
                                                                                    </span>
                                                                                    :
                                                                                    <span className="text-xs text-[#E4E7F1]">{`${(new Date(item.dateEvent).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                        :

                                                                        <>
                                                                            {item.sportName === "Soccer"
                                                                                ?
                                                                                <span className="text-xs text-[#E4E7F1]">{`${new Date(item.dateEvent.slice(5, 7).toString() + "/" + item.dateEvent.slice(8, 10).toString() + "/" + item.dateEvent.slice(0, 4).toString() + " " + item.dateEvent.slice(11, 16) + " GMT-4").toLocaleDateString()}, ${new Date(item.dateEvent.slice(5, 7).toString() + "/" + item.dateEvent.slice(8, 10).toString() + "/" + item.dateEvent.slice(0, 4).toString() + " " + item.dateEvent.slice(11, 16) + " GMT-4").toLocaleTimeString()}`}
                                                                                </span>
                                                                                :
                                                                                <span className="text-xs text-[#E4E7F1]">{`${(new Date(item.dateEvent).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>

                                                                <div className="flex flex-col items-end gap-2 w-1/2">
                                                                    <div className="flex items-center  gap-2">
                                                                        <img src={soccer_2} alt="" />
                                                                        <span className="text-xs text-[#C1C9E1] font-medium">{item.lineBetting}</span>
                                                                    </div>
                                                                    <div className="rounded-[4px] py-1 bg-[#212531]">
                                                                        <span className="px-3  text-sm text-[#E4E7F1]">{`${item.lineBetting.includes("OU") || item.lineBetting.includes("Total") ? "" : `${item.teamChoose} @`} ${item.scoreChoose}`}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-2 items-center w-[10%]">
                                                            <span className="flex items-center w-full h-full justify-center text-sm text-[#E4E7F1]">{item.scoreChoose.split(' ').length > 1 ? item.scoreChoose.split(' ')[item.scoreChoose.split(' ').length - 1] : item.scoreChoose.split(' ')[0]}</span>

                                                        </div>
                                                        <div className="flex justify-center items-center w-[10%]">
                                                            <span className="text-sm text-[#D4B962]">${formatPrice(item.amount || 0)}</span>
                                                        </div>
                                                        <div className="flex justify-center items-center w-[10%]">
                                                            <span className={`text-sm text-[#33E49A] ${(runningBill && runningBill.includes(Number(item.idBill)) && 'text-[#33E49A]') || (rejectBill && rejectBill.includes(Number(item.idBill)) && 'text-[#FF4A59]') || ('waiting text-[#D4B962]')}`}>
                                                                {runningBill && runningBill.includes(Number(item.idBill)) ? "RUNNING" : (rejectBill && rejectBill.includes(Number(item.idBill)) ? "REJECT" : ("WAITING"))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                        }
                                        )}
                                        {listBill && listBill.map((item: any) => (
                                            <ItemSoccer key={item.id} status={1} item={item} />
                                        ))}
                                    </div>
                                </div>
                            )
                            : (
                                <div className="flex flex-col">
                                    <div className="pt-[120px] flex flex-col items-center gap-3">
                                        <img src={empty} alt="" className="w-[125px] h-[125px] " />
                                        <span className='text-base fw-[400] text-[#C1C9E1]'>You haven't placed any bets yet</span>
                                    </div>
                                </div>
                            )
                    }
                </div>
                {/* <div className="w-full border border-[#263859] bg-[#20273d]">
                    <Pagination align="center" defaultCurrent={1} total={Math.round(totalRunning ? totalRunning / 2 : 1)} className="custom-paginaton" onChange={(e) => setOffset && setOffset((e - 1) * 20)} />
                </div> */}
            </div>
            {/* {
                chooseGame === 1 && (<RunningSoocer />)
            }
            {
                chooseGame === 2 && (<RunningNBA />)
            }
            {
                chooseGame === 3 && (<RunningNFL />)
            }
            {
                chooseGame === 4 && (<RunningMLB />)
            } */}
        </div>
    )
}