/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-pascal-case */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Flex, Tabs, Empty, Collapse, Affix } from 'antd';
import '../page/homepage/HomePage.css'
import '../page/DetailPage/styles.css'
import { FilterOutlined } from '@ant-design/icons';

import HeadNavi from '../page/homepage/components/MainNavi/Head/HeadNavi';
import Banner from '../page/homepage/components/Content/Banner';
import { Collapse_img, Expand, Trash, banner, filter_icon, leaf_1, leaf_2, reload, start } from '../components/imgExport';
import BetTabs from '../page/homepage/components/Content/Sidebar/BetTabs';
import { useDispatch, useSelector } from 'react-redux';
import { items } from '../services/data';
import useLoading from '../hook/useLoading';
import useToast from '../hook/useToast';
import * as bettingApi from '../api/betting/bettingApi';
import * as homePageApi from '../api/homePage/homePageApi';
import { selectorUser, setUserMoney } from '../store/slices/userSlice';
import { itemMenu } from '../page/homepage/components/MainNavi/Tabs/TabsNaviV2';
import useThrottle from '../hook/useThrottle';
import { checkNoData, getOddsParlay, getPiceWinDecimal } from '../until';
import ModalWarning from '../components/Modal/ModalWarning';
import TotalStakeComponent from '../page/homepage/components/TotalStake/TotalStake';
import { useLocation } from 'react-router-dom';
import FutureTabs from '../components/FutureTabs/NFLFuture';
import MLBFuture from '../components/FutureTabs/MLBFuture';
import useDebounce from '../hook/useDebounce';
import { WarningOutlined } from '@mui/icons-material';
import * as homePage_V2Api from '../api/homePage/homePage_V2Api';
import * as loginApi from '../api/login/loginApi';
import GameDeTail_V2 from '../page/homepage/components/Content/GameList/GameDeTail_V2';
import GameLiveDeTail_V2 from '../page/homepage/components/Content/GameList/GameLiveDetail_V2';
import io from 'socket.io-client';
import { API_URL } from '../env';
import { format } from 'date-fns'
import EmtyBetSlip from '../components/EmtyBetSlip';
import DayTimeMLB from '../page/homepage/components/Content/GameList/daytimeMLB';
import { Layout } from './Layout';
import ModalBetSlip_V2 from '../page/homepage/components/Content/Sidebar/ModalBetSlip_V2';
import ModalConfirmBet from '../components/Modal/ConfirmBetModal';
import ToastGlobal from '../components/ToastGlobal';
import { ModalTourName_V2 } from '../components/Modal/ModalTourName_V2';
import { FooterMobile } from '../components/FooterMobile';
import HeadNavi_V2 from '../page/homepage/components/MainNavi/Head/HeadNavi_V2';
import { getPresentMoney, } from '../api/billAcceptor/billAcceptorApi';
import { DrawerTourNameMGMMobile } from '../components/Drawer/DrawerTourNameMGMMobile';
var intervalPrematch: any
var intervalListDate: any
var intervalTotalGamePrematch: any
export interface InfoChooseGame {
  icon: string, lable: string
}

function formatDateNow() {
  let d = new Date().toLocaleDateString()
  return d
}

function formatDate(UTC: string) {
  let d = new Date(UTC).toLocaleDateString()
  return d
}

interface DashBoardProps {
  showSpan: boolean;
  setShowSpan: React.Dispatch<React.SetStateAction<boolean>>;
  showNLF: boolean;
  setShowNLF: React.Dispatch<React.SetStateAction<boolean>>;
  showMLB: boolean;
  setShowMLB: React.Dispatch<React.SetStateAction<boolean>>;
  isHomePage: boolean;
  setIsHomePage: React.Dispatch<React.SetStateAction<boolean>>;
}
const MLBComponent_V2: React.FC<DashBoardProps> = ({ showSpan, setShowSpan, showNLF, setShowNLF, showMLB, setShowMLB, isHomePage, setIsHomePage }) => {

  const dispatch = useDispatch();
  const pushToast = useToast();
  const pushLoading = useLoading();
  const { userId, presentMoney } = useSelector(selectorUser);
  const location = useLocation();
  const [socket, setSocket] = useState<any>(null);
  const [betting, setBetting] = useState<homePageApi.infoBettings>();

  const [ipDevice, setIpDevice] = useState<any>("");
  const [locationDevice, setLocationDevice] = useState<any>("");
  const [time, setTime] = useState(0)

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(async (data) => {
        setIpDevice(String(data.ip))
        await fetch('http://www.geoplugin.net/json.gp?ip=' + String(data.ip)).then(res => res.json()).then(data => {
          setLocationDevice(data.geoplugin_city ? data.geoplugin_city : "")
        })
      })
      .catch(error =>
        console.log("Can't get IP and Location from device")
      )
  }, [])

  const [menu, setMenu] = useState<any>(items)
  const [menuSetting, setListMenuSetting] = useState<any[]>([])
  const viewSettings = async () => {
    const ketqua = await homePageApi.viewSettings()
    setMenu([])
    if (ketqua?.success) {
      if (ketqua.data.navigation.NBA) {
        setListMenuSetting((data: any) => [...data, "NBA"])
      }
      if (ketqua.data.navigation.NFL) {
        setListMenuSetting((data: any) => [...data, "NFL"])
      }
      if (ketqua.data.navigation.MLB) {
        setListMenuSetting((data: any) => [...data, "MLB"])
      }
      if (ketqua.data.navigation.NCAAF) {
        setListMenuSetting((data: any) => [...data, "NCAAF"])
      }
      // if (ketqua.data.navigation.SOC) {
      //   setMenuSoccer(true)
      // }
      setBetting(ketqua.data.bettings)
      if (ketqua.data.bettings.Straight === 1) {
        setMenu((data: any) => [...data, {
          key: '1',
          label: 'Straight',
          children:
            <>
              <Empty className="pl-11 pr-11 text-center" description='Add selections to your Betslip to get started' />
            </>,
        }])
      }
      if (ketqua.data.bettings.Parlay === 1) {
        setMenu((data: any) => [...data, {
          key: '2',
          label: 'Parlay',
          children:
            <>
              <div className="flex items-center rounded-md pr-4 pl-4 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-transparent text-[#D4B962] ">
                <WarningOutlined />
                <span className="pl-2">2+ selections needed for a Parlay.</span>
              </div>
              <Empty className="pl-2 pr-2 text-center" description='A Parlay is a bet on two or more teams or selections. You can combine different sports,points, spreads and money lines. To win the parlay all selections mustwin.' />
            </>,
        }])
      }
      if (ketqua.data.bettings.Teaser === 1) {
        setMenu((data: any) => [...data, {
          key: '3',
          label: 'Teaser',
          children:
            <>
              <div className="flex items-start rounded-md pr-4 pl-4 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-transparent text-[#D4B962] ">
                <WarningOutlined className="pt-1" />
                <span className="pl-2 text-left">2+ Football or Basketball selections needed.
                  for a Teaser bet.
                </span>
              </div>
              <Empty className="pl-2 pr-2" description='Teasers allow you to combine 2+ Football or Basketball selections from the Spread and Total Match Point bet types and allow you to alter the spread, to increase the probability of winning.' />
            </>,
        }])
      }
      if (ketqua.data.bettings.IfBet === 1) {
        // setMenu((data: any) => [...data, {
        //   key: '4',
        //   label: 'If Bet',
        //   children:
        //     <>
        //       <div className="flex items-center rounded-md pr-4 pl-4 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
        //         <WarningOutlined />
        //         <span className="pl-2">2+ Bets are needed to create an IF Bet.</span>
        //       </div>
        //       <Empty className="pl-2 pr-2 text-center" description='Wager on your chosen 1st selection, if that selecton wins, then an amount up to the original “ to win " amount will be wagered on your next selection. If your 1st selection losses. There is no action afterwards, your bet is a loser.' />
        //     </>,
        // }])
      }
      if (ketqua.data.bettings.Reverse === 1) {
        // setMenu((data: any) => [...data, {
        //   key: '5',
        //   label: 'Reverse',
        //   children:
        //     <>
        //       <div className="flex items-center rounded-md pr-3 pl-3 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
        //         <WarningOutlined />
        //         <span className="pl-1">2+ Bets are needed to create an Reverse.</span>
        //       </div>
        //       <Empty className="pl-2 pr-2 text-center" description='An" Action Reverse " bet links multiple bets: the next bet is activated regardless of the previous bets outcome, as long as the event occurs.' />
        //     </>,
        // }])
      }
    } else {
      setListMenuSetting([])
    }
  }

  useEffect(() => {
    viewSettings()
  }, [])

  useEffect(() => {
    gTournament()
    gTournamentSameSport()
    // gListOfGamesForTheHomepage()

    return (() => {
      setTotalBet(false)
      setShowTotalStake(false)
      setShowModalBets(false)
      setShowModalBill(false)
      setShowModalWarning(false)

      setIsWarningBetParlay(false)
      setTextWarningBetParlay("")
      setIsWarningBetTeaser(false)
      setArrayActiveItem([])
      setTotalStake(0)
      setTotalAmount(0)
      setTotalAmountParlay(0)
      setTotalAmountTeaser(0)
      setTotalOddsTeaser(0)
      setPointTeaser(4)
      setTotalGame(0)
      setTotalGamelive(0)

      setInfoChooseGame({ icon: "Baseball.svg", lable: "Baseball" })
      setTournaments(itemMenu[0])


      setListChooseGame([])
      setListChooseBet([])
      setListAddStrightBetsSlip([])
      setListParlayBetsSlip([])
      setListTeaserBetsSlip([])
      setListBet([])
      setListBetParlay([])
      setListBetTeaser([])
      setListBill([])
      setListBillParlay(undefined)

      setListOfGamesForTheHomepage([])
    })
  }, [])

  const [showTotalBet, setTotalBet] = useState(false)
  const [showTotalStake, setShowTotalStake] = useState(false)
  const [showTotalStake_V2, setShowTotalStake_V2] = useState(false)
  const [showModalBets, setShowModalBets] = useState(false)
  const [showModalBill, setShowModalBill] = useState(false)
  const [showModalWarning, setShowModalWarning] = useState(false)
  const [showModalTourName_V2, setShowModalTourName_V2] = useState(false)

  const [updateOdds, setUpdateOdds] = useState(false)
  const [keyBetTabs, setKeyBetTabs] = useState("1");
  const [keyGameTab, setKeyGameTab] = useState<"gameOnline" | "gameOffline">('gameOnline');
  const [typeListGame, setTypeListGame] = useState<"homepage" | "select">('homepage');
  const [selectTournament, setSelectTournament] = useState<string[]>([]);

  const [isWarningBetParlay, setIsWarningBetParlay] = useState(false);
  const [textWarningBetParlay, setTextWarningBetParlay] = useState("");
  const [isWarningBetTeaser, setIsWarningBetTeaser] = useState(false);
  const [arrayAcitveItem, setArrayActiveItem] = useState<string[]>([])
  const [totalStake, setTotalStake] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalAmountParlay, setTotalAmountParlay] = useState(0)
  const [totalOddsParlay, setTotalOddsParlay] = useState(0)
  const [totalTParlay, setTotalTParlay] = useState(1)
  const [totalAmountTeaser, setTotalAmountTeaser] = useState(0)
  const [totalOddsTeaser, setTotalOddsTeaser] = useState(0)
  const [pointTeaser, setPointTeaser] = useState(4)
  const [totalGame, setTotalGame] = useState(0)
  const [totalAllGame, setTotalAllGame] = useState(0)
  const [totalGameLive, setTotalGamelive] = useState(0)

  const [infoChooseGame, setInfoChooseGame] = useState<InfoChooseGame>({ icon: "Baseball.svg", lable: "Baseball" })
  const [tournaments, setTournaments] = useState(itemMenu[0])

  const [listChooseGame, setListChooseGame] = useState<number[]>([])
  const [listChooseBet, setListChooseBet] = useState<bettingApi.ChooseBet[]>([])
  const [listAddStrightBetsSlip, setListAddStrightBetsSlip] = useState<bettingApi.InfoStrightBetsSlipTMP[]>([])
  const [listParlayBetsSlip, setListParlayBetsSlip] = useState<bettingApi.InfoParlayBetsSlipTMP[]>([])
  const [listTeaserBetsSlip, setListTeaserBetsSlip] = useState<bettingApi.InfoBetTeaserTMP[]>([])

  const [listBet, setListBet] = useState<bettingApi.InfoBets[]>([])
  const [listBetParlay, setListBetParlay] = useState<bettingApi.InfoBetsParlay[]>([])
  const [listBetTeaser, setListBetTeaser] = useState<bettingApi.InfoBetTeaser[]>([])
  const [listBill, setListBill] = useState<bettingApi.InfoBill[]>([])
  const [listBillParlay, setListBillParlay] = useState<bettingApi.InfoBillParlay>()
  const [listBillTeaser, setListBillTeaser] = useState<bettingApi.InfoBillTeaser>()

  const [listOfGamesForTheHomepage, setListOfGamesForTheHomepage] = useState<homePageApi.InfoGame[]>([])
  const [listTournamentOfPreMatchBetMGM, setListTournamentOfPreMatchBetMGM] = useState<homePage_V2Api.InfoListTournamentBetMGM[]>([])
  const [typeGame, setTypeGame] = useState<string>('')

  const [competitions, setCompetitions] = useState<{ [key: string]: homePage_V2Api.InfoBetMGM[] }>({});
  const [competitionsLive, setCompetitionsLive] = useState<{ [key: string]: homePage_V2Api.InfoBetMGM[] }>({});
  const [competitionsLiveOld, setCompetitionsLiveOld] = useState<{ [key: string]: homePage_V2Api.InfoBetMGM[] }>({});
  const [socketInstance, setSocketInstance] = useState<any>();
  const [tournamentLive, setTournamentLive] = useState<any>();
  const [countryLive, setCountryLive] = useState<any>();

  const [listDateFilter, setListDateFilter] = useState<homePage_V2Api.infoListDate[]>([])
  const [dateFilter, setDateFilter] = useState<string>('')
  const [settingBet, setSettingBet] = useState<bettingApi.InfoSettingsBet>()
  const [decimal, setDecimal] = useState<string>("American")
  const [inputFilter, setInputFilter] = useState<string>('')
  const [dataTournament, setDataTournament] = useState<homePage_V2Api.InfoTournamentsListCountryTournamentBetBetMGM[]>([])
  const [dataTournamentSameSport, setDataTournamentSameSport] = useState<homePage_V2Api.InfoTournamentSameSport[]>([])
  const [minBetStraight, setMinBetStraight] = useState<number>(10)
  const [maxBetStraight, setMaxBetStraight] = useState<number>(10000)
  const [minBetParlay, setMinBetParlay] = useState<number>(10)
  const [maxBetParlay, setMaxBetParlay] = useState<number>(10000)
  const [showDrawerTourNameMGMMobile, setShowDrawerTourNameMGMMobile] = useState(false)

  const checkFirstTimeDateFilter = useRef(0)
  const gListDate = async () => {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const ketQua = await homePage_V2Api.listDate(timeZone, "Football", selectTournament.length ? selectTournament[0] : "", JSON.stringify(listChooseGame));
    if (ketQua?.success) {
      setListDateFilter(ketQua.data)
      if (checkFirstTimeDateFilter.current === 0) {
        setDateFilter(ketQua.data[0].value ? ketQua.data[0].value.toString() : "")
      }
      // checkFirstTimeDateFilter.current = 1
    } else {
      setListDateFilter([]);
      pushToast(ketQua?.message, "warn");
    }
  }

  const changeDateFilter = async (date: string) => {
    await setDateFilter(date)
    await handleRadioChange('gameOffline')
    if (date === "All") {
      gListAllBetMGM("allgame")
    }
  }

  const resetBet = useThrottle(async () => {
    var timeTotal = 10
    const interval = setInterval(() => {
      if (timeTotal > 0) {
        timeTotal -= 1
        setTime(timeTotal)
      } else {
        clearInterval(interval)
      }
    }, 1000);

    pushLoading(true)
    if (typeListGame === 'homepage') {
      setTotalGame(0)
      gListHomeBetBetMGM()
    } else {
      // gListOfGameByCountryAndGame()
    }
    pushLoading(false)
  }, 10000)

  useEffect(() => {
    // var dataOld: any
    setSocketInstance(io(API_URL))
    setSocket(socketInstance);

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, []);
  // const arraySocketLive = useRef<any>()
  const [arraySocketLive, setArraySocketLive] = useState<any>()
  const arraySocket = useRef<any>()
  const arraySocketPrematch = useRef<any>()
  useEffect(() => {
    if (socketInstance) {
      socketInstance.on('connect', () => {
        console.log('Connected to server NFL')
        // socketInstance.emit('clientMessage', { sportName: `${tournamentLive}`, countryName: `${countryLive}` });
      });
      socketInstance.on('message-football', (data: any) => {
        // arraySocketLive.current = data
        setArraySocketLive(data)
        checkFirstTimeDateFilter.current = 1
      });
      socketInstance.on('message-prematch', (data: any) => {
        // var filterNBA = data.filter((bet: any) => (bet.competition.name.value === "NFL"))
        var endday = new Date()
        endday.setHours(23)
        endday.setMinutes(59)
        endday.setSeconds(59)

        var arrFilter = data.filter((item: any) => (new Date(`${item.startDate}`).getTime() <= endday.getTime()))
        var arr = arrFilter.map((item: any) => ({
          ...item,
          showTime: new Date(item.startDate).getTime(),
          showTimeNow: new Date().getTime(),
          subtractTimeClient: new Date(item.startDate).getTime() - new Date().getTime()
        }))
        const sortedArray = arr.sort((a: any, b: any) => {
          return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
        });

        arraySocketPrematch.current = sortedArray
      });
    }
  }, [socketInstance])

  useEffect(() => {
    if (selectTournament) {
      // var filterNFL = arraySocketLive.current && arraySocketLive.current.filter((bet: any) => (selectTournament.includes(bet.competition.name.value)))
      var filterNFL = arraySocketLive && arraySocketLive.filter((bet: any) => (selectTournament.includes(bet.competition.name.value)))
      arraySocket.current = filterNFL
      setTotalGamelive(filterNFL ? filterNFL.length : 0)
      setCompetitionsLiveOld(competitionsLive)
      if (filterNFL && filterNFL.length > 0) {
        const dataFormat = fomatDataHomeBetMGM((filterNFL))
        setCompetitionsLive(dataFormat)
      } else {
        setCompetitionsLive({});
        if (keyGameTab === "gameOnline" && checkFirstTimeDateFilter.current === 1) {
          changeDateFilter("All")
        }
      }
    }
  }, [arraySocketLive])

  const removeEndOfGamePrematch = useThrottle((idGame: number) => {
    const updatedList = listChooseBet.filter((bet) => !(bet.id === idGame))
    setListChooseBet(updatedList)

    const updatedListStraight = listAddStrightBetsSlip.filter((bet) => !(bet.id === idGame))
    setListAddStrightBetsSlip(updatedListStraight)

    const updatedListParlay = listParlayBetsSlip.filter((bet) => !(bet.id === idGame))

    setListParlayBetsSlip(updatedListParlay)

    const updatedListTeaser = listTeaserBetsSlip.filter((bet) => !(bet.id === idGame))
    setListTeaserBetsSlip(updatedListTeaser)

    setShowModalBets(false)
  }, 50)

  useEffect(() => {
    if (listDateFilter && listDateFilter[0] && listDateFilter[0].value && dateFilter === listDateFilter[0].value) {
      if (arraySocketPrematch.current && arraySocketPrematch.current.length > 0) {
        if (tournamentLive) {
          const updatedList = arraySocketPrematch.current.filter((bet: any) => (bet.sport.name.value === tournamentLive && new Date(bet.startDate).getDate() === new Date().getDate()))
          setTotalGame(updatedList.length)
          const data = fomatDataHomeBetMGM(updatedList)
          setCompetitions(data)
        } else {
          const updatedList = arraySocketPrematch.current.filter((bet: any) => (selectTournament.includes(bet.competition.name.value) && menuSetting.includes(bet.competition.name.value && new Date(bet.startDate).getDate() === new Date().getDate())))
          setTotalGame(updatedList.length)
          const data = fomatDataHomeBetMGM(updatedList)
          setCompetitions(data)
        }
      } else {
        setTotalGame(0)
        setCompetitions({})
      }
    } else {
      if (arraySocketPrematch.current && arraySocketPrematch.current.length > 0) {
        if (tournamentLive) {
          const updatedList = arraySocketPrematch.current.filter((bet: any) => ((bet.sport.name.value === tournamentLive && selectTournament.includes(bet.competition.name.value) && new Date(bet.startDate).getDate() === new Date().getDate())))
          if (listDateFilter[0] && (listDateFilter[0].totalMatch || listDateFilter[0].totalMatch === 0)) {
            listDateFilter[0].totalMatch = updatedList.length
          }
        } else {
          const updatedList = arraySocketPrematch.current.filter((bet: any) => (selectTournament.includes(bet.competition.name.value) && menuSetting.includes(bet.competition.name.value) && new Date(bet.startDate).getDate() === new Date().getDate()))
          if (listDateFilter[0] && (listDateFilter[0].totalMatch || listDateFilter[0].totalMatch === 0)) {
            listDateFilter[0].totalMatch = updatedList.length
          }
        }
      } else {
        // listDateFilter[0].totalMatch = 0
      }
    }
    if (keyGameTab === 'gameOffline') {
      if (dateFilter === "All") {
        if (arraySocketPrematch.current && arraySocketPrematch.current.length > 0) {
          Object.keys(competitions).map((competitionName, index) => {
            competitions[competitionName].map((item, itemIndex) => {
              if (formatDateNow() === formatDate(item.startDate)) {
                var listIdGameSocket = arraySocketPrematch.current?.map((item: any) => item.id)
                if (!listIdGameSocket.includes(item.id)) {
                  competitions[competitionName].splice(itemIndex, 1)
                  setTotalAllGame(totalAllGame - 1)
                } else {
                  const updatedList = arraySocketPrematch.current.filter((bet: any) => (selectTournament.includes(bet.competition.name.value) && menuSetting.includes(bet.competition.name.value)))
                  const data = fomatDataHomeBetMGM(updatedList)
                  Object.keys(data).map((competitionNameData, indexData) => {
                    data[competitionNameData].map((itemData, itemIndexData) => {
                      if (itemData.id === item.id) {
                        item = itemData
                      }
                    })
                  })
                }
              }
            })
          })
        }
        if (listChooseBet.length > 0) {
          if (keyBetTabs === '1') {
            if (listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0) {
              listAddStrightBetsSlip.map((item, itemIndex) => {
                if (formatDateNow() === formatDate(item.dateEvent)) {
                  var listIdGameSocket = arraySocketPrematch.current.map((item: any) => item.id)
                  if (!listIdGameSocket.includes(item.id)) {
                    removeEndOfGamePrematch(Number(item.id))
                  }
                }
              })
            }
          } else if (keyBetTabs === '2') {
            if (listParlayBetsSlip && listParlayBetsSlip.length > 0) {
              listParlayBetsSlip.map((item, itemIndex) => {
                if (formatDateNow() === formatDate(item.dateEvent)) {
                  var listIdGameSocket = arraySocketPrematch.current.map((item: any) => item.id)
                  if (!listIdGameSocket.includes(item.id)) {
                    removeEndOfGamePrematch(Number(item.id))
                  }
                }
              })
            }
          } else if (keyBetTabs === '3') {
            if (listTeaserBetsSlip && listTeaserBetsSlip.length > 0) {
              listTeaserBetsSlip.map((item, itemIndex) => {
                if (formatDateNow() === formatDate(item.dateEvent)) {
                  var listIdGameSocket = arraySocketPrematch.current.map((item: any) => item.id)
                  if (!listIdGameSocket.includes(item.id)) {
                    removeEndOfGamePrematch(Number(item.id))
                  }
                }
              })
            }
          }

        }
      }
      if (listChooseBet.length > 0 && listDateFilter[0].value === dateFilter) {
        if (keyBetTabs === '1') {
          if (listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0) {
            const listGameId = arraySocketPrematch.current.map((item: any) => item.id);
            listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, index) => {
              if (!listGameId.includes(Number(item.id))) {
                removeEndOfGamePrematch(Number(item.id))
              }
            })
            arraySocketPrematch.current.map((itemSocket: any, indexSocket: number) => {
              listAddStrightBetsSlip && listAddStrightBetsSlip.map((itemStraight, indexStraight) => {
                if (itemSocket.subtractTimeClient <= 60000 && listGameId.includes(Number(itemStraight.id)) && itemSocket.id === itemStraight.id) {
                  removeEndOfGamePrematch && removeEndOfGamePrematch(Number(itemStraight.id))
                }
              })
            })
          }
        } else if (keyBetTabs === '2') {
          if (listParlayBetsSlip && listParlayBetsSlip.length > 0) {
            const listGameId = arraySocketPrematch.current.map((item: any) => item.id);
            listParlayBetsSlip && listParlayBetsSlip.map((item, index) => {
              if (!listGameId.includes(Number(item.id))) {
                removeEndOfGamePrematch(Number(item.id))
              }
            })
            arraySocketPrematch.current.map((itemSocket: any, indexSocket: number) => {
              listParlayBetsSlip && listParlayBetsSlip.map((itemParlay, indexParlay) => {
                if (itemSocket.subtractTimeClient <= 60000 && listGameId.includes(Number(itemParlay.id)) && itemSocket.id === itemParlay.id) {
                  removeEndOfGamePrematch && removeEndOfGamePrematch(Number(itemParlay.id))
                }
              })
            })
          }
        } else if (keyBetTabs === '3') {
          if (listTeaserBetsSlip && listTeaserBetsSlip.length > 0) {
            const listGameId = arraySocketPrematch.current.map((item: any) => item.id);
            listTeaserBetsSlip && listTeaserBetsSlip.map((item, index) => {
              if (!listGameId.includes(Number(item.id))) {
                removeEndOfGamePrematch(Number(item.id))
              }
            })
            arraySocketPrematch.current.map((itemSocket: any, indexSocket: number) => {
              listTeaserBetsSlip && listTeaserBetsSlip.map((itemTeaser, indexTeaser) => {
                if (itemSocket.subtractTimeClient <= 60000 && listGameId.includes(Number(itemTeaser.id)) && itemSocket.id === itemTeaser.id) {
                  removeEndOfGamePrematch && removeEndOfGamePrematch(Number(itemTeaser.id))
                }
              })
            })
          }
        }
      }
    }
  }, [arraySocketPrematch.current])

  const blockGameID = useThrottle((idGame: string | number | undefined) => {
    if (listAddStrightBetsSlip.length > 0 || listChooseBet.length > 0) {
      setShowModalBets(false)
      const updatedList = listChooseBet.filter((bet) => !(bet.id === idGame))
      setListChooseBet(updatedList)

      const updatedListStraight = listAddStrightBetsSlip.filter((bet) => !(bet.id === idGame))
      setListAddStrightBetsSlip(updatedListStraight)
    }
  }, 1000)

  const blockGame = useThrottle(() => {
    const listId = (listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0) ? listAddStrightBetsSlip.map(ItemStraight => ItemStraight.id) : [];
    arraySocket.current && arraySocket.current.map((itemGame: any) => {
      if ((itemGame?.scoreboard?.period === "9th Inning" || itemGame?.scoreboard?.period === "10th Inning" || itemGame?.scoreboard?.period === "11th Inning" || itemGame?.scoreboard?.period === "12th Inning" || itemGame?.scoreboard?.period === "13th Inning" || itemGame?.scoreboard?.period === "14th Inning" || itemGame?.scoreboard?.period === "15th Inning") && listId.includes(itemGame.id)) {
        blockGameID(itemGame.id)
      }

    })
  }, 1000)

  useEffect(() => {
    if (listChooseBet.length > 0 || (listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0)) {
      blockGame()
    }
  }, [arraySocket.current])

  useEffect(() => {
    if (listAddStrightBetsSlip.length > 0 && listAddStrightBetsSlip[0] && listAddStrightBetsSlip[0].live === true) {
      var updatedListNew: any = []
      var updatedListChooseBet: any = []

      listAddStrightBetsSlip.forEach((betSlip: any) => {
        var check = 0
        arraySocket.current.forEach((element: any) => {
          element.optionMarkets && element.optionMarkets.forEach((elementParent: any) => {
            if ((betSlip.lineBetting === elementParent.name.value) || (betSlip.lineBetting === "Spread" && elementParent.name.value === "Spread") || (betSlip.lineBetting === "Total" && elementParent.name.value === "Totals") || (betSlip.lineBetting === "Money" && elementParent.name.value === "Money Line") || (betSlip.lineBetting === "First Half Spread" && elementParent.name.value === "First half spread") || (betSlip.lineBetting === "First Half Total" && (elementParent.name.value === "First Half Total" || elementParent.name.value === "1st Half Totals")) || (betSlip.lineBetting === "First Half Money" && elementParent.name.value === "First half money line")) {
              elementParent.options && elementParent.options.forEach((elementChildren: any, index: number) => {
                // if (elementChildren.id === betSlip.optionId) {
                if (elementParent?.status === "Visible" && elementChildren?.status === "Visible" && (betSlip.option === index || betSlip.optionId === elementChildren.id)) {
                  check = 1
                }
                // }
              })
            }
          });

          if (Number(element.id) === Number(betSlip.id)) {
            element.games && element.games.forEach((elementParent: any) => {
              if ((betSlip.lineBetting === elementParent.name.value) || (betSlip.lineBetting === "Spread" && elementParent.name.value === "Spread") || (betSlip.lineBetting === "Total" && elementParent.name.value === "Totals") || (betSlip.lineBetting === "Money" && elementParent.name.value === "Money Line") || (betSlip.lineBetting === "First Half Spread" && elementParent.name.value === "First half spread") || (betSlip.lineBetting === "First Half Total" && (elementParent.name.value === "First Half Total" || elementParent.name.value === "1st Half Totals")) || (betSlip.lineBetting === "First Half Money" && elementParent.name.value === "First half money line")) {
                elementParent.results && elementParent.results.forEach((elementChildren: any, index: number) => {
                  // if (elementChildren.id === betSlip.optionId) {
                  if (elementParent?.visibility === "Visible" && elementChildren?.visibility === "Visible" && (betSlip.option === index || betSlip.optionId === elementChildren.id)) {
                    check = 1
                  }
                  // }
                })
              }

            });
          }

        });
        if (check === 1) {
          updatedListNew.push(betSlip)
        }
      })
      listChooseBet.forEach((betChoose: any) => {
        var check = 0
        arraySocket.current.forEach((element: any) => {
          element.optionMarkets && element.optionMarkets.forEach((elementParent: any) => {
            if ((betChoose.lineBetting === elementParent.name.value) || (betChoose.lineBetting === "Spread" && elementParent.name.value === "Spread") || (betChoose.lineBetting === "Total" && elementParent.name.value === "Totals") || (betChoose.lineBetting === "Money" && elementParent.name.value === "Money Line") || (betChoose.lineBetting === "First Half Spread" && elementParent.name.value === "First half spread") || (betChoose.lineBetting === "First Half Total" && (elementParent.name.value === "First Half Total" || elementParent.name.value === "1st Half Totals")) || (betChoose.lineBetting === "First Half Money" && elementParent.name.value === "First half money line")) {
              elementParent.options && elementParent.options.forEach((elementChildren: any, index: number) => {
                // if (elementChildren.id === betChoose.optionId) {
                if (elementParent?.status === "Visible" && elementChildren?.status === "Visible" && (betChoose.option === index || betChoose.optionId === elementChildren.id)) {
                  check = 1
                }
                // }
              })

            }
          });

          if (Number(element.id) === Number(betChoose.id)) {
            element.games && element.games.forEach((elementParent: any) => {
              if ((betChoose.lineBetting === elementParent.name.value) || (betChoose.lineBetting === "Spread" && elementParent.name.value === "Spread") || (betChoose.lineBetting === "Total" && elementParent.name.value === "Totals") || (betChoose.lineBetting === "Money" && elementParent.name.value === "Money Line") || (betChoose.lineBetting === "First Half Spread" && elementParent.name.value === "First half spread") || (betChoose.lineBetting === "First Half Total" && (elementParent.name.value === "First Half Total" || elementParent.name.value === "1st Half Totals")) || (betChoose.lineBetting === "First Half Money" && elementParent.name.value === "First half money line")) {
                elementParent.results && elementParent.results.forEach((elementChildren: any, index: number) => {
                  // if (elementChildren.id === betChoose.optionId) {
                  if (elementParent?.visibility === "Visible" && elementChildren?.visibility === "Visible" && (betChoose.option === index || betChoose.optionId === elementChildren.id)) {
                    check = 1
                  }
                  // }
                })
              }

            });
          }
        });
        if (check === 1) {
          updatedListChooseBet.push(betChoose)
        }
      })

      setTimeout(() => {
        if (updatedListNew.length !== listAddStrightBetsSlip.length)
          setListAddStrightBetsSlip(updatedListNew)
        if (updatedListChooseBet.length !== listChooseBet.length)
          setListChooseBet(updatedListChooseBet)
      }, 1000);
    }
  }, [arraySocket.current])

  const updateListOddsChooseBet = useDebounce(async (updatedListChooseBet: any) => {
    setListChooseBet(updatedListChooseBet)
  }, 500)

  const arrayGameSelect = useRef<any>([])

  useEffect(() => {
    intervalPrematch = setInterval(() => {
      gListHomeBetBetMGM()
    }, 60000);
    gListHomeBetBetMGM()
    gListAllLiveBetMGM()
    return (() => {
      clearInterval(intervalPrematch)
    })
  }, [])

  const gListHomeBetBetMGM = useDebounce(async () => {
    if (dateFilter && dateFilter !== "All") {
      // pushLoading(true);
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const ketQua = await homePage_V2Api.getHomeBetBetMGM("", dateFilter, timeZone);
      if (ketQua?.success) {
        const filterNBA = ketQua.data.filter((bet) => (selectTournament.includes(bet.competition.name.value)))
        var arr = filterNBA.map(item => ({
          ...item,
          showTime: new Date(item.startDate).getTime(),
          showTimeNow: new Date().getTime(),
          subtractTimeClient: new Date(item.startDate).getTime() - new Date().getTime()
        }))
        setTotalGame(filterNBA.length)
        arrayGameSelect.current = arr
        const data = fomatDataHomeBetMGM(arr)
        setCompetitions(data)
      } else {
        pushToast(ketQua?.message, "warn");
      }
      // pushLoading(false);
    }
  }, 500)

  useEffect(() => {
    clearInterval(intervalPrematch)
    intervalPrematch = setInterval(() => {
      handleMenuSelect_V2(tournamentLive, countryLive)
    }, 60000);

    clearInterval(intervalListDate)
    gListDate()
    intervalListDate = setInterval(() => {
      gListDate()
    }, 60000)
  }, [selectTournament])

  useEffect(() => {
    pushLoading(true);
    if (dateFilter !== "All") {
      handleMenuSelect_V2(tournamentLive, countryLive)
      clearInterval(intervalPrematch)
      intervalPrematch = setInterval(() => {
        handleMenuSelect_V2(tournamentLive, countryLive)
      }, 60000);
    }
    setTimeout(() => {
      pushLoading(false);
    }, 1000)
  }, [dateFilter])


  useEffect(() => {
    if (listChooseBet.length > 0 && listAddStrightBetsSlip.length > 0) {
      listAddStrightBetsSlip?.map(async (item) => {
        if (new Date(item.dateEvent).getDay() === Number(listDateFilter[0].value?.split('-')[1])) {
          arrayGameSelect.current?.map((itemGame: any) => {
            if (item.id === itemGame.id) {
              itemGame.optionMarkets?.map((itemOptionMarkets: any) => {
                itemOptionMarkets.options?.map((itemOptionDetail: any) => {
                  if (itemOptionDetail.id === item.optionId) {
                    item.scoreChoose = `${itemOptionDetail.attr} ${decimal === 'American' ? itemOptionDetail.price.americanOdds : itemOptionDetail.price.odds}`
                    // updatedList.push({ ...item, scoreChoose: `${itemOptionDetail.attr} ${decimal === 'American' ? itemOptionDetail.price.americanOdds : itemOptionDetail.price.odds}` })
                  }
                })

              })

            }
          })
        }
      });

      listChooseBet?.map(async (item) => {
        if (new Date(item.dateEvent).getDay() === Number(listDateFilter[0].value?.split('-')[1])) {
          arrayGameSelect.current?.map((itemGame: any) => {
            if (item.id === itemGame.id) {
              itemGame.optionMarkets?.map((itemOptionMarkets: any) => {
                itemOptionMarkets.options?.map((itemOptionDetail: any) => {
                  if (itemOptionDetail.id === item.optionId) {
                    item.scoreChoose = `${itemOptionDetail.attr} ${decimal === 'American' ? itemOptionDetail.price.americanOdds : itemOptionDetail.price.odds}`
                    // updatedListChooseBet.push({ ...item, scoreChoose: `${itemOptionDetail.attr} ${decimal === 'American' ? itemOptionDetail.price.americanOdds : itemOptionDetail.price.odds}` })
                  }
                })

              })

            }
          })
        }
      });
    }
  }, [arrayGameSelect.current])

  useEffect(() => {
    gListAllBetMGM()
  }, [dateFilter, selectTournament])

  useEffect(() => {
    if (dateFilter === "All" && keyGameTab === 'gameOffline') {
      gListAllBetMGM()
    }
  }, [keyGameTab])

  const gListAllBetMGM = useDebounce(async (value: string, reload?: boolean) => {
    clearInterval(intervalTotalGamePrematch)
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const ketQua = await homePage_V2Api.getAllBetMGM("All", timeZone, "All", JSON.stringify(listChooseGame));
    if (ketQua?.success) {
      if (value === "count") {
        var dataAfter = ketQua.data.filter((item) => (item.stage === "PreMatch"))
        setTotalGame(dataAfter.length)
        setTotalAllGame(dataAfter.length)
      } else {
        var dataAfter = ketQua.data.filter((item) => (item.stage === "PreMatch"))
        setTotalGame(dataAfter.length)
        setTotalAllGame(dataAfter.length)
        if (dateFilter === "All" && keyGameTab === 'gameOffline') {
          const data = fomatDataHomeBetMGM(dataAfter)
          setCompetitions(data)
        }
      }
      intervalTotalGamePrematch = setInterval(() => {
        gListAllBetMGM(undefined, true)
      }, 60000)
    } else {
      pushToast(ketQua?.message, "warn");
    }
  }, 500)

  const gListAllLiveBetMGM = useDebounce(async () => {
    pushLoading(true);
    const ketQua = await homePage_V2Api.allLiveBetBetMGM();
    if (ketQua?.success) {
      // const data = fomatDataHomeBetMGM(ketQua.data)
      const filterNFL = ketQua.data.filter((bet) => (selectTournament.includes(bet.competition.name.value)))
      setTotalGamelive(filterNFL.length)
      const data = fomatDataHomeBetMGM(filterNFL)
      setCompetitionsLive(data)
    } else {
      pushToast(ketQua?.message, "warn");
    }
    pushLoading(false);
  }, 500)

  const fomatDataHomeBetMGM = (data: homePage_V2Api.InfoBetMGM[]) => {
    var competitions: { [key: string]: homePage_V2Api.InfoBetMGM[] } = {};

    // Duyệt qua mỗi phần tử trong mảng data
    data.forEach(item => {
      // Kiểm tra xem competition có tồn tại không và có thuộc tính name không
      if (item.competition?.name) {
        const competitionName = item.competition.name.value;

        // Kiểm tra xem mảng con cho cuộc thi đã tồn tại trong đối tượng competitions chưa
        if (!competitions[competitionName]) {
          // Nếu chưa tồn tại, tạo một mảng mới
          competitions[competitionName] = [];
        }

        // Thêm phần tử vào mảng con của cuộc thi tương ứng
        competitions[competitionName].push(item);
      }
    });


    return sortCompteitions(competitions)
  };

  const sortCompteitions = (competitionsx: { [key: string]: homePage_V2Api.InfoBetMGM[] }) => {
    var sortedCompetitions: { [key: string]: homePage_V2Api.InfoBetMGM[] } = {};

    Object.keys(competitionsx).forEach((competitionName) => {
      if (selectTournament.includes(competitionName)) {
        sortedCompetitions[competitionName] = competitionsx[competitionName];
      }
    });

    Object.keys(competitionsx).forEach((competitionName) => {
      if (!selectTournament.includes(competitionName)) {
        sortedCompetitions[competitionName] = competitionsx[competitionName];
      }
    });
    return sortedCompetitions
    // setCompetitions(sortedCompetitions)
  };

  const handleCheckQuantityMoneyStraight = async (keytab: number, total: number) => {
    pushLoading(true);
    var ketQua
    if (keytab === 1) {
      ketQua = await homePageApi.checkQuantityMoneyStraight(total);
    } else if (keytab === 2) {
      ketQua = await homePageApi.checkQuantityMoneyParlay(total);
    } else {
      ketQua = await homePageApi.checkQuantityMoneyTeaser(total);
    }

    if (ketQua?.success) {
      pushLoading(false);
      return true
      // aStoreStrightBetsSlip()
    } else {
      pushToast(ketQua?.message, "warn");
      pushLoading(false);
      return false
    }

  }

  const showHideTotalStake = () => {
    setShowTotalStake(!showTotalStake)
  }
  const showHideTotalStake_V2 = () => {
    setShowTotalStake_V2(!showTotalStake_V2)
  }
  const gTournament = async () => {
    if (window.location.pathname === "/NFL") {
      // pushLoading(true);
      const ketQua = await homePage_V2Api.listCountryTournamentBetBetMGM();
      if (ketQua?.success) {
        setDataTournament([])
        if (ketQua.data) {
          ketQua.data.map(item => {
            item.tournaments && item.tournaments.map(itemtournament => {
              setDataTournament((prevList) => [
                ...prevList,
                itemtournament
              ])
            })
          })
        }
        setTimeout(() => {
          gTournament()
        }, 300000)
      } else {
        pushToast(ketQua?.message, "warn");
      }
    }
    // pushLoading(false);
  }

  var checkFirstTime = 0

  const gTournamentSameSport = async () => {
    // pushLoading(true);
    if (window.location.pathname === "/NFL") {
      const ketQua = await homePage_V2Api.tournamentsSameSport('NFL', 'Football');
      if (ketQua?.success) {
        if (ketQua.data) {
          setDataTournamentSameSport(ketQua.data)
          if (checkFirstTime === 0) {
            setTimeout(() => {
              setDateFilter("All")
              if (ketQua.data[0]) {
                setSelectTournament([ketQua.data[0].tournament]);
                setListChooseGame([ketQua.data[0].id_tournament])
                setTimeout(() => {
                  handleMenuSelect_V2(ketQua.data[0].sport, ketQua.data[0].region, undefined, "firstTime")
                }, 1000)
              }
            }, 1000)
          }
          checkFirstTime = 1
        }
        setTimeout(() => {
          gTournamentSameSport()
        }, 300000)
      } else {
        setDataTournamentSameSport([]);
        pushToast(ketQua?.message, "warn");
      }
    }
    // pushLoading(false);
  }

  const cMoneyBetting = useThrottle(async (listbetBetslip?: bettingApi.InfoBets[] | bettingApi.InfoBetsParlay[] | bettingApi.InfoBetTeaser[], keybet?: string) => {
    setShowModalBets(false)
    pushLoading(true);
    var checkQuantityMoneyStraight
    switch (keyBetTabs) {
      case "1":
        checkQuantityMoneyStraight = await handleCheckQuantityMoneyStraight(+keyBetTabs, totalAmount)
        break;
      case "2":
        checkQuantityMoneyStraight = await handleCheckQuantityMoneyStraight(+keyBetTabs, totalAmountParlay)
        break;
      case "3":
        checkQuantityMoneyStraight = await handleCheckQuantityMoneyStraight(+keyBetTabs, totalAmountTeaser)
        break;
      default:
        break;
    }

    if (!checkQuantityMoneyStraight) {
      pushLoading(false);
      return
    }

    switch (keyBetTabs) {
      case "1":
        const rs1 = await bettingApi.checkMoneyBetting(presentMoney, totalAmount, listbetBetslip ? listbetBetslip : listBet, "Straight");
        if (rs1?.success) {
          aStoreStrightBetsSlip(listbetBetslip ? listbetBetslip : listBet)
        } else {
          pushToast(rs1?.message, "warn");
          if (rs1.type === "nem") {
            setShowModalWarning(true)
          }
        }
        break;
      case "2":
        const rs2 = await bettingApi.checkMoneyBetting(presentMoney, totalAmountParlay, listbetBetslip ? listbetBetslip : listBetParlay, "Parlay");
        if (rs2?.success) {
          aStoreParlayBetsSlip(listbetBetslip ? listbetBetslip : listBetParlay, "Parlay")
        } else {
          pushToast(rs2?.message, "warn");
          if (rs2.type === "nem") {
            setShowModalWarning(true)
          }
        }
        break;
      case "3":
        const rs3 = await bettingApi.checkMoneyBetting(presentMoney, totalAmountTeaser, listbetBetslip ? listbetBetslip : listBetTeaser, "Teaser");
        if (rs3?.success) {
          aStoreTeaserBetsSlip(listbetBetslip ? listbetBetslip : listBetTeaser)
        } else {
          pushToast(rs3?.message, "warn");
          if (rs3.type === "nem") {
            setShowModalWarning(true)
          }
        }
        break;
      default:
        break;
    }
    pushLoading(false);
  }, 1000)

  const aStoreStrightBetsSlip = useThrottle(async (listbetStraight?: bettingApi.InfoBets[]) => {
    pushLoading(true)
    const ketQua = await bettingApi.addStoreStrightBetsSlip(userId, listbetStraight ? listbetStraight : listBet, ipDevice, locationDevice, decimal);
    if (ketQua?.success) {
      if (listBet.length) {
        if (listBet[0].live === true) {
          if (decimal === "American") {
            for (let index = 0; index < ketQua.data.length; index++) {
              ketQua.data[index].win = Math.round(Number(ketQua.data[index].win)).toString()
              ketQua.data[index].live = true
            }
          }
          setListBill(ketQua.data)
        } else {
          if (decimal === "American") {
            for (let index = 0; index < ketQua.data.length; index++) {
              ketQua.data[index].win = Math.round(Number(ketQua.data[index].win)).toString()
            }
          }
          setListBill(ketQua.data)
        }
      } else {
        if (decimal === "American") {
          for (let index = 0; index < ketQua.data.length; index++) {
            ketQua.data[index].win = Math.round(Number(ketQua.data[index].win)).toString()
          }
        }
        setListBill(ketQua.data)
      }
      dispatch(setUserMoney(ketQua.presentMoney || "0"))
      // setBillCode(ketQua.billCode)
      clearAllStrightBetsSlip()
      pushToast("Successfully Betting", "success")
      setShowModalBill(true)
      setListChooseBet([])
    } else {
      pushToast(ketQua?.message, "warn");
    }
    pushLoading(false);
  }, 1000)

  const aStoreParlayBetsSlip = useThrottle(async (listbet?: bettingApi.InfoBetsParlay[]) => {
    pushLoading(true);
    const ketQua = await bettingApi.addStoreParlayBetsSlip(userId, totalOddsParlay, totalAmountParlay, listbet ? listbet : listBetParlay, ipDevice, locationDevice);
    if (ketQua?.success) {
      dispatch(setUserMoney(ketQua.presentMoney || "0"))
      if (listBetParlay.length) {
        if (listBetParlay[0].live === true) {
          if (decimal === "American") {
            for (let index = 0; index < ketQua.data.data.length; index++) {
              // ketQua.data.data[index].win = Number(ketQua.data.data[index].win).toFixed(0)
              ketQua.data.data[index].live = true
            }
          }
          setListBillParlay(ketQua.data)
        } else {
          if (decimal === "American") {
            for (let index = 0; index < ketQua.data.data.length; index++) {
              // ketQua.data.data[index].win = Number(ketQua.data.data[index].win).toFixed(0)
            }
          }
          setListBillParlay(ketQua.data)
        }
      } else {
        setListBillParlay(ketQua.data)
      }
      clearAllStrightBetsSlip()
      pushToast("Successfully Betting", "success")
      setShowModalBill(true)
      setListChooseBet([])
    } else {
      pushToast(ketQua?.message, "warn");
    }
    pushLoading(false);
  }, 1000)

  const aStoreTeaserBetsSlip = useThrottle(async (listbet?: bettingApi.InfoBetTeaser[]) => {
    pushLoading(true);
    const ketQua = await bettingApi.addStoreTeaserBetsSlip(userId, pointTeaser, totalOddsTeaser, totalAmountTeaser, listbet ? listbet : listBetTeaser, ipDevice, locationDevice);
    if (ketQua?.success) {
      dispatch(setUserMoney(ketQua.presentMoney || "0"))
      setListBillTeaser(ketQua.data)
      pushToast("Successfully Betting", "success")
      setShowModalBill(true)
      setListChooseBet([])
    } else {
      pushToast(ketQua?.message, "warn");
    }
    pushLoading(false);
  }, 1000)

  const handelChooseTournaments = (value: itemMenu) => {
    const listParent = document.querySelectorAll(".ant-menu-parent-tournament.ant-menu-submenu-selected")
    if (listParent.length > 0) {
      listParent[0].classList.remove("ant-menu-submenu-selected")
    }

    const listChildren = document.querySelectorAll(".ant-menu-children-tournament.ant-menu-item-selected")
    if (listChildren.length > 0) {
      listChildren[0].classList.remove("ant-menu-item-selected")
    }
    setInfoChooseGame({ icon: "Baseball.svg", lable: "Baseball" })
    setTypeListGame('homepage')
    setTournamentLive('')
    setCountryLive('')
    setTournaments(value)
    if (value.id !== 9) {
      setTournaments(value)
    }
    if (value.id === 0) {
      // gListOfGamesForTheHomepage()
      gListHomeBetBetMGM()
    }
    window.location.href = value.href
  }
  const strighbet = useRef<any>()
  const parlaybet = useRef<any>()
  const [hiddenBetPlace, setHiddenBetPlace] = useState<boolean>(false)

  const checkUpdateStrightBetsSlip = (
    teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", color?: string, homeLiveScore?: number, awayLiveScore?: number, id?: number | string
  ) => {
    setUpdateOdds(true)
    var arr = listAddStrightBetsSlip.map(item => ({
      ...item,
      scoreChoose: (item.teamChoose === teamChoose && item.lineBetting === lineBetting) ? scoreChoose : item.scoreChoose,
      color: color,
      change: (item.teamChoose === teamChoose && item.lineBetting === lineBetting) ? true : false,
      win: getPiceWinDecimal(item.amount, item.scoreChoose, decimal),
      homeLiveScore: homeLiveScore,
      awayLiveScore: awayLiveScore,
    })).filter(item => item.scoreChoose !== undefined);

    strighbet.current = arr
    changeStright(strighbet.current)
    var arrParlay = listParlayBetsSlip.map(item => ({
      ...item,
      scoreChoose: (item.teamChoose === teamChoose && item.lineBetting === lineBetting) ? scoreChoose : item.scoreChoose,
      color: color,
      change: (item.teamChoose === teamChoose && item.lineBetting === lineBetting) ? true : false,
      odds: (item.teamChoose === teamChoose && item.lineBetting === lineBetting) ? getOddsParlay(item.scoreChoose) : item.odds,
      // win: getPiceWinDecimal(item., item.scoreChoose, decimal),
    })).filter(item => item.scoreChoose !== undefined);
    parlaybet.current = arrParlay

    var check = 0

    const updatedListChooseBet = listChooseBet?.map(item => {
      if (item.id === id) {
        check = 1
        return { ...item, scoreChoose: scoreChoose };
      }
      return item;
    });
    if (check === 1)
      updateListOddsChooseBet(updatedListChooseBet)
    // setListChooseBet(updatedListChooseBet);

    changeParlay(parlaybet.current)
    // setListParlayBetsSlip(parlaybet.current)

    var totalOddsParlayMap = 0;
    arrParlay.forEach(element => {
      totalOddsParlayMap = totalOddsParlayMap + Number(element.odds)
    });
    setTotalOddsParlay(totalOddsParlayMap)
  }
  const changeStright = useDebounce((arr: any) => {
    if (listAddStrightBetsSlip !== strighbet.current) {
      setHiddenBetPlace(true)
    }
    setListAddStrightBetsSlip(arr)
  }, 200)

  const changeParlay = useDebounce((arr: any) => {
    if (listParlayBetsSlip !== parlaybet.current) {
      setHiddenBetPlace(true)
    }
    setListParlayBetsSlip(arr)
  }, 200)

  const checkAddStrightBetsSlip = useThrottle(async (
    tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", dateEvent: string, live?: boolean, id?: number | string, idGame?: number | string, optionId?: number | string, homeLiveScore?: number, awayLiveScore?: number, dateEventServer?: string, option?: number
  ) => {
    setUpdateOdds(false)
    const isDuplicate = listChooseBet.some(
      (bet) => bet.teamChoose === teamChoose && bet.lineBetting === lineBetting && (id ? bet.id === id : true)
    );
    const updatedList = isDuplicate
      ? listChooseBet.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting))
      : [...listChooseBet, { tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, live, id, idGame, optionId, option, dateEventServer }];

    var checkMax = Math.max(...[Number(settingBet?.quantity.straight) ? Number(settingBet?.quantity.straight) : 0, Number(settingBet?.quantity.parlay) ? Number(settingBet?.quantity.parlay) : 0, Number(settingBet?.quantity.teaser) ? Number(settingBet?.quantity.teaser) : 0, 1])
    if (updatedList.length > checkMax) {
      pushToast(`Your bet amount exceeds the limit ${checkMax}`, "warn");
    } else {
      setListChooseBet(updatedList);
    }

    if (!isDuplicate) {
      if (lineBetting === 'Money' && keyBetTabs === '3') {
        pushToast(`Teaser does not allow Money Line betting`, "warn");
      }
      aStrightBetsSlip(tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, live, id, idGame, optionId, option, homeLiveScore, awayLiveScore, dateEventServer)
    }
    else
      rStrightBetsSlip(tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, live, id)

    setTimeout(() => {
      var inputStake = document.getElementById("input-stake");
      var inputStakeMobile = document.getElementById("input-stake-mobile");
      inputStake && inputStake.focus()
      inputStakeMobile && inputStakeMobile.focus()
    }, 500)
  }, 400)


  const aStrightBetsSlip = async (
    tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", dateEvent: string, live?: boolean, id?: number | string, idGame?: number | string, optionId?: number | string, option?: number, homeLiveScore?: number, awayLiveScore?: number, dateEventServer?: string
  ) => {

    pushLoading(true);
    if (settingBet?.quantity.straight && settingBet?.quantity.straight > listAddStrightBetsSlip.length || !settingBet?.quantity.straight) {
      const ketQua = await bettingApi.addStrightBetsSlip(tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent)
      if (ketQua?.success) {
        if (keyGameTab === 'gameOnline') {
          // clearAllStrightBetsSlipLive()
        }

        var dateEventUpdate: any
        if (ketQua.data.dateEvent.includes("|") && (ketQua.data.dateEvent.includes("AM") || ketQua.data.dateEvent.includes("PM"))) {
          const dateEvent = ketQua.data.dateEvent.split(/[|]/)
          var time: any
          if (dateEvent[1].includes("AM")) {
            time = (dateEvent[1].split(/[ ]/))[0]

          } else if (dateEvent[1].includes("PM")) {
            var timepm = (dateEvent[1].split(/[ ]/))[0]
            var hour = timepm.split(/[:]/)[0]
            var number
            if (Number(hour) >= 12) {
              number = Number(hour)
            } else {
              number = Number(hour) + 12
            }
            time = number.toString() + ":" + timepm.split(/[:]/)[1] + ":" + timepm.split(/[:]/)[2]
          }
          if (new Date(dateEvent[0] + " " + time)) {
            dateEventUpdate = format(new Date(dateEvent[0] + " " + time), "yyyy-MM-dd'T'HH:mm:ss")
          } else {
            dateEvent[0].split(/[/]/)
            dateEventUpdate = format(new Date(dateEvent[0].split(/[/]/)[2] + "/" + dateEvent[0].split(/[/]/)[1] + "/" + dateEvent[0].split(/[/]/)[0] + " " + time), "yyyy-MM-dd'T'HH:mm:ss")
          }
        } else {
          dateEventUpdate = ketQua.data.dateEvent
        }
        setListAddStrightBetsSlip((prevList) => [
          ...prevList,
          { ...ketQua.data, dateEvent: dateEventUpdate, isCheck: true, amount: 0, win: "0", live: live, stage: live ? 1 : 0, id: id, idGame: id, optionId, option, homeLiveScore, awayLiveScore, dateEventServer }
        ]);
        setTotalStake(0)
      } else {
        pushToast(ketQua?.message, "warn");
      }
    } else {
      if (keyBetTabs === "1") {
        pushToast(`Your straight bet amount exceeds the limit ${settingBet?.quantity?.straight ? settingBet?.quantity?.straight.toString() : ""}`, "warn");
      }
    }
    pushLoading(false);
  }

  const rStrightBetsSlip = async (
    tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", dateEvent: string, live?: boolean, id?: number | string
  ) => {

    const updatedList = listAddStrightBetsSlip.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting))
    var check = 0
    await updatedList && updatedList.map(item => {
      if (item.change) {
        check++
      }
    })
    if (check === 0) {
      setHiddenBetPlace(false)
    } else {
      setHiddenBetPlace(true)
    }
    setListAddStrightBetsSlip(updatedList)
    rParlayBetsSlip(game)
  }


  const rParlayBetsSlip = async (game: string) => {
    const updatedList = listParlayBetsSlip.filter((bet) => (bet.game === game))
    var check = 0
    await updatedList && updatedList.map(item => {
      if (item.change) {
        check++
      }
    })
    if (check === 0) {
      setHiddenBetPlace(false)
    } else {
      setHiddenBetPlace(true)
    }
    if (settingBet?.quantity.parlay && settingBet?.quantity.parlay > listParlayBetsSlip.length) {
      const updatedListParlay = listParlayBetsSlip.filter((bet) => (bet.game !== game))
      await setListParlayBetsSlip(updatedListParlay)
      aParlayBetsSlip()
    } else {
      const updatedListParlay = listParlayBetsSlip.filter((bet) => (bet.game !== game))
      setListParlayBetsSlip(updatedListParlay)
    }
    if (settingBet?.quantity.teaser && settingBet?.quantity.teaser > listTeaserBetsSlip.length) {
      const updatedListTeaser = listTeaserBetsSlip.filter((bet) => (bet.game !== game))
      await setListTeaserBetsSlip(updatedListTeaser)
      // aTeaserBetsSlip()
      aTeaserBetsSlipWithObject(updatedListTeaser)
    } else if (settingBet?.quantity.teaser && settingBet?.quantity.teaser === listTeaserBetsSlip.length) {
      const updatedListTeaser = listTeaserBetsSlip.filter((bet) => (bet.game !== game))
      // await setListTeaserBetsSlip(updatedListTeaser)
      aTeaserBetsSlipWithObject(updatedListTeaser)
    } else {
      const updatedListTeaser = listTeaserBetsSlip.filter((bet) => (bet.game !== game))
      setListTeaserBetsSlip(updatedListTeaser)
    }
  }

  const checkConditionsBetParlay = useDebounce((arr: bettingApi.InfoParlayBetsSlipTMP[]): Boolean => {
    if (arr.length < 2) {
      setTextWarningBetParlay("")
      setIsWarningBetParlay(true)
      return false; // Trường hợp 1
    }
    // Kiểm tra xem có bất kỳ cặp game nào giống nhau hay không và có game nào thuộc money line hay k
    var gameSetTest: bettingApi.InfoParlayBetsSlipTMP[] = [] // Sử dụng Set để kiểm tra các game duy nhất
    for (const item of arr) {
      for (const itemTest of gameSetTest) {
        if ((item.lineBetting === "Spread" || item.lineBetting === "Total" || item.lineBetting === "Money") && (itemTest.lineBetting.includes("First Half"))) {
          checkWarningParlay(item.id ? item.id : item.game, arr)
          setTextWarningBetParlay("Some of your Same Game Parlay picks cannot be combined")
          setIsWarningBetParlay(true)
          return false; // Trường hợp 1
        } else if ((itemTest.lineBetting === "Spread" || itemTest.lineBetting === "Total" || itemTest.lineBetting === "Money") && (item.lineBetting.includes("First Half"))) {
          checkWarningParlay(item.id ? item.id : item.game, arr)
          setTextWarningBetParlay("Some of your Same Game Parlay picks cannot be combined")
          setIsWarningBetParlay(true)
          return false; // Trường hợp 1
        }
      }
      var check = 0
      if (item.lineBetting === "Money" || item.lineBetting.includes("Money")) {
        for (const itemGame of gameSetTest) {
          if (((itemGame.lineBetting === "Spread" || itemGame.lineBetting.includes("Spread")) && itemGame.teamChoose === item.teamChoose && (itemGame.id === item.id || itemGame.id === item.game || itemGame.game === item.id || itemGame.game === item.game)) || (itemGame.id === item.id || itemGame.id === item.game || itemGame.game === item.id || itemGame.game === item.game) && itemGame.lineBetting === item.lineBetting) {
            setTextWarningBetParlay("")
            setIsWarningBetParlay(true)
            checkWarningParlay(item.id ? item.id : item.game, arr)
            check++
            return false; // Trường hợp 1
          }
        }
      } else {
        for (const itemGame of gameSetTest) {
          if (((itemGame.id === item.id || itemGame.id === item.game || itemGame.game === item.id || itemGame.game === item.game) && itemGame.lineBetting === item.lineBetting) || ((itemGame.id === item.id || itemGame.id === item.game || itemGame.game === item.id || itemGame.game === item.game) && ((item.lineBetting === "Spread" && item.teamChoose === itemGame.teamChoose && itemGame.lineBetting === "Money") || (item.lineBetting.includes("Spread") && item.teamChoose === itemGame.teamChoose && itemGame.lineBetting.includes("Money"))))) {
            setTextWarningBetParlay("Some of your Same Game Parlay picks cannot be combined")
            setIsWarningBetParlay(true)
            checkWarningParlay(item.id ? item.id : item.game, arr)
            check++
            return false; // Trường hợp 1
          }
        }
      }
      if (check === 0) {
        gameSetTest.push(item);
      }
    }
    checkWarningParlay("", arr)
    setTextWarningBetParlay("")
    setIsWarningBetParlay(false)
    // Nếu không có cặp game nào giống nhau và mảng có 2 hoặc nhiều hơn 2 phần tử
    return true; // Trường hợp 2
  }, 100)

  const checkWarningParlay = useThrottle((game: string, arr: bettingApi.InfoParlayBetsSlipTMP[]) => {
    if (game) {
      listParlayBetsSlip.map(item => {
        if (item.id === game || item.game.includes(game)) {
          item.isWarning = true
        }
      })
    } else {
      listParlayBetsSlip.map(item => {
        item.isWarning = false
      })
    }

  }, 500)

  const checkLengthChooseBetTeaser = () => {
    const newArray = listChooseBet.filter((item) => (item.lineBetting !== "Money"))
    return newArray.length > 0
  }

  const checkConditionsBetTeaser = useThrottle((listTeaserBetsSlipCheckTrue: bettingApi.InfoBetTeaserTMP[]) => {
    var listBetTeaser: bettingApi.InfoBetTeaserTMP[]

    if (listTeaserBetsSlipCheckTrue.length < 2) {
      setIsWarningBetTeaser(true)
      return false
    }
    // Kiểm tra xem có bất kỳ cặp game nào giống nhau hay không
    const gameSet: Set<string> = new Set(); // Sử dụng Set để kiểm tra các game duy nhất
    for (const item of listTeaserBetsSlipCheckTrue) {
      if (gameSet.has(item.game)) {
        listBetTeaser = checkWarningTeaser(item.game, listTeaserBetsSlipCheckTrue)
        // setListTeaserBetsSlip(listBetTeaser)
        setIsWarningBetTeaser(true)
        return false
      } else {
        gameSet.add(item.game);
      }
    }
    listBetTeaser = checkWarningTeaser("", listTeaserBetsSlipCheckTrue)
    // setListTeaserBetsSlip(listBetTeaser)
    setIsWarningBetTeaser(false)
    // Nếu không có cặp game nào giống nhau và mảng có 2 hoặc nhiều hơn 2 phần tử
    return true
  }, 500)

  const checkWarningTeaser = (game: string, listTeaserBetsSlipCheckTrue: bettingApi.InfoBetTeaserTMP[]) => {
    if (game) {
      const updatedList = listTeaserBetsSlipCheckTrue?.map(item => {
        if (item.game.includes(game)) {
          return { ...item, isWarning: true };
        }
        return item;
      });
      return updatedList
    } else {
      const updatedList = listTeaserBetsSlip.map(item => ({ ...item, isWarning: false }));
      return updatedList
    }

  }

  const settingsBet = async () => {
    const ketQua1 = await bettingApi.getSettingsBet()
    if (ketQua1?.success) {
      setSettingBet(ketQua1.data)
    }

  }

  const aParlayBetsSlip = useThrottle(async (
  ) => {

    pushLoading(true);
    if (settingBet?.quantity.parlay && (settingBet?.quantity.parlay > listParlayBetsSlip.length || (settingBet?.quantity.parlay === listParlayBetsSlip.length && settingBet?.quantity.parlay - 1 === listChooseBet.length))) {
      const ketQua = await bettingApi.addParlayBetsSlip(listChooseBet, decimal)
      if (ketQua?.success) {
        ketQua.data.data.map((bet, index) => {
          if (bet.dateEvent.includes("|") && (bet.dateEvent.includes("AM") || bet.dateEvent.includes("PM"))) {
            let dateEvent = bet.dateEvent.split(/[|]/)
            var time: any
            if (dateEvent[1].includes("AM")) {
              time = (dateEvent[1].split(/[ ]/))[0]

            } else if (dateEvent[1].includes("PM")) {
              var timepm = (dateEvent[1].split(/[ ]/))[0]
              var hour = timepm.split(/[:]/)[0]
              var number
              if (Number(hour) >= 12) {
                number = Number(hour)
              } else {
                number = Number(hour) + 12
              }
              time = number.toString() + ":" + timepm.split(/[:]/)[1] + ":" + timepm.split(/[:]/)[2]
            }
            bet.dateEvent = format(new Date(dateEvent[0] + " " + time), "yyyy-MM-dd'T'HH:mm:ss")
          }
        })
        setListParlayBetsSlip(ketQua.data.data.map((bet, index) => ({ ...bet, isCheck: true, isWarning: false, live: listChooseBet[index].live, id: listChooseBet[index].id, idGame: listChooseBet[index].id, stage: listChooseBet[index].live ? 1 : 0 })));
        setTotalAmountParlay(0)
        setTotalOddsParlay(ketQua.data.totalOdds)
        setTotalTParlay(ketQua.data.t)

      } else {
        pushToast(ketQua?.message, "warn");
      }
    } else {
      if (keyBetTabs === "2") {
        pushToast(`Your parlay bet amount exceeds the limit ${settingBet?.quantity?.parlay ? settingBet?.quantity?.parlay.toString() : ""}`, "warn");
      }
    }
    pushLoading(false);
  }, 500)

  const aTeaserBetsSlip = async () => {

    pushLoading(true);
    if (settingBet?.quantity.teaser && settingBet?.quantity.teaser > listTeaserBetsSlip.length) {
      const newArray = listChooseBet.filter((item) => (item.lineBetting !== "Money"))
      const ketQua = await bettingApi.addTeaserBetsSlip(pointTeaser.toString(), newArray, undefined, decimal)
      if (ketQua?.success) {
        ketQua.data.data.map((bet, index) => {
          if (bet.dateEvent.includes("|") && (bet.dateEvent.includes("AM") || bet.dateEvent.includes("PM"))) {
            let dateEvent = bet.dateEvent.split(/[|]/)
            var time: any
            if (dateEvent[1].includes("AM")) {
              time = (dateEvent[1].split(/[ ]/))[0]

            } else if (dateEvent[1].includes("PM")) {
              var timepm = (dateEvent[1].split(/[ ]/))[0]
              var hour = timepm.split(/[:]/)[0]
              var number
              if (Number(hour) >= 12) {
                number = Number(hour)
              } else {
                number = Number(hour) + 12
              }
              time = number.toString() + ":" + timepm.split(/[:]/)[1] + ":" + timepm.split(/[:]/)[2]
            }
            bet.dateEvent = format(new Date(dateEvent[0] + " " + time), "yyyy-MM-dd'T'HH:mm:ss")
          }
        })
        setListTeaserBetsSlip(ketQua.data.data.map((bet, index) => ({ ...bet, isCheck: true, isWarning: false, live: listChooseBet[index].live, stage: listChooseBet[index].live ? 1 : 0, id: listChooseBet[index].id, idGame: listChooseBet[index].id })));
        setTotalAmountTeaser(0)
        setTotalOddsTeaser(ketQua.data.totalOdds)

      } else {
        pushToast(ketQua?.message, "warn");
      }
    } else {
      if (keyBetTabs === "3") {
        pushToast(`Your teaser bet amount exceeds the limit ${settingBet?.quantity?.teaser ? settingBet?.quantity?.teaser.toString() : ""}`, "warn");
      }
    }
    pushLoading(false);
  }

  const aTeaserBetsSlipWithObject = async (listTeaserBetsSlipObject: bettingApi.InfoBetTeaserTMP[]) => {
    pushLoading(true);
    if (settingBet?.quantity.teaser && settingBet?.quantity.teaser > listTeaserBetsSlipObject.length) {
      const arr = listTeaserBetsSlipObject.map(item => item.game)
      const newArray = listChooseBet.filter((item) => (item.lineBetting !== "Money" && arr.includes(item.game)))
      const ketQua = await bettingApi.addTeaserBetsSlip(pointTeaser.toString(), newArray, undefined, decimal)
      if (ketQua?.success) {
        ketQua.data.data.map((bet, index) => {
          if (bet.dateEvent.includes("|") && (bet.dateEvent.includes("AM") || bet.dateEvent.includes("PM"))) {
            let dateEvent = bet.dateEvent.split(/[|]/)
            var time: any
            if (dateEvent[1].includes("AM")) {
              time = (dateEvent[1].split(/[ ]/))[0]

            } else if (dateEvent[1].includes("PM")) {
              var timepm = (dateEvent[1].split(/[ ]/))[0]
              var hour = timepm.split(/[:]/)[0]
              var number
              if (Number(hour) >= 12) {
                number = Number(hour)
              } else {
                number = Number(hour) + 12
              }
              time = number.toString() + ":" + timepm.split(/[:]/)[1] + ":" + timepm.split(/[:]/)[2]
            }
            bet.dateEvent = format(new Date(dateEvent[0] + " " + time), "yyyy-MM-dd'T'HH:mm:ss")
          }
        })
        setListTeaserBetsSlip(ketQua.data.data.map((bet, index) => ({ ...bet, isCheck: true, isWarning: false, live: listChooseBet[index].live, stage: listChooseBet[index].live ? 1 : 0, id: listChooseBet[index].id, idGame: listChooseBet[index].id })));
        setTotalAmountTeaser(0)
        setTotalOddsTeaser(ketQua.data.totalOdds)

      } else {
        pushToast(ketQua?.message, "warn");
      }
    } else {
      if (keyBetTabs === "3") {
        pushToast(`Your teaser bet amount exceeds the limit ${settingBet?.quantity?.teaser ? settingBet?.quantity?.teaser.toString() : ""}`, "warn");
      }
    }
    pushLoading(false);
  }

  const uPointTeaserBet = useDebounce(async () => {
    pushLoading(true);
    const updatedArray = listTeaserBetsSlip.map(({ isCheck, isWarning, newLine, point, ...rest }) => ({
      ...rest
    }));
    const ketQua = await bettingApi.updatePointTeaserBet(pointTeaser.toString(), updatedArray, totalAmountTeaser.toString())
    if (ketQua?.success) {
      setListTeaserBetsSlip(ketQua.data.data.map(bet => ({ ...bet, isCheck: true, isWarning: false })));
      setTotalAmountTeaser(0)
      setTotalOddsTeaser(ketQua.data.totalOdds)
    } else {
      pushToast(ketQua?.message, "warn");
    }
    pushLoading(false);
  }, 500)

  const clearAllStrightBetsSlip = () => {
    setListChooseBet([])
    setListAddStrightBetsSlip([])
    setListParlayBetsSlip([])
    setListBet([])
    setListBetParlay([])
    setListBetTeaser([])
    setTotalStake(0)
    setTotalOddsParlay(0)
    setTotalTParlay(1)
    setTotalAmountParlay(0)
    setPointTeaser(4)
    setTotalOddsTeaser(0)
    setTotalAmountTeaser(0)
    setHiddenBetPlace(false)
  };

  //bettab
  const rStrightBetsSlipByIndex = (value: number) => {
    if (listChooseBet.length) {
      var updateListChooseBet = listChooseBet.filter((item, index) => (index !== value))
      setListChooseBet(updateListChooseBet);
    }
    if (listAddStrightBetsSlip.length) {
      var updateListStraightBet = listAddStrightBetsSlip.filter((item, index) => (index !== value))
      setListAddStrightBetsSlip(updateListStraightBet);
    }
    if (listParlayBetsSlip.length) {
      if (listParlayBetsSlip.length === 1) {
        setListAddStrightBetsSlip([])
      }
      var updateListParlay = listParlayBetsSlip.filter((item, index) => (index !== value))
      setListParlayBetsSlip(updateListParlay);
    }
  }

  const getMinMaxBet = async () => {
    const ketQua = await loginApi.getMinMaxBet();
    if (ketQua?.success) {
      setMinBetStraight(ketQua?.data?.minBetStraight ? ketQua?.data?.minBetStraight : 10)
      setMaxBetStraight(ketQua?.data?.maxBetStraight ? ketQua?.data?.maxBetStraight : 10)
      setMinBetParlay(ketQua?.data?.minBetParlay ? ketQua?.data?.minBetParlay : 10)
      setMaxBetParlay(ketQua?.data?.maxBetParlay ? ketQua?.data?.maxBetParlay : 10)
    }
  }
  //Straight
  const handleAllClick = (e: number) => {
    switch (keyBetTabs) {
      case "1":
        if (totalStake !== 0) {
          if (Number(`${totalStake + e}`) <= maxBetStraight) {
            setTotalStake(Number(`${totalStake + e}`))
            uAllAmountAddStrightBetsSlip(Number(`${totalStake + e}`))
          } else {
            // pushToast(`Total bets minimum is ${minBetStraight} and maximum is ${maxBetStraight}`, "warn");
          }
        } else {
          setTotalStake(e)
          uAllAmountAddStrightBetsSlip(e)
        }
        break;
      case "2":
        if (totalAmountParlay) {
          if (Number(`${totalAmountParlay + e}`) <= maxBetParlay) {
            setTotalAmountParlay(Number(`${totalAmountParlay + e}`))
          } else {
            pushToast(`Total bets minimum is ${minBetParlay} and maximum is ${maxBetParlay}`, "warn");
          }
        } else {
          setTotalAmountParlay(e)
        }
        break;
      case "3":
        if (totalAmountTeaser) {
          if (totalAmountTeaser < 10000) {
            setTotalAmountTeaser(Number(`${totalAmountTeaser + e}`))
          } else {
            pushToast("The total bet cannot exceed $10,000", "warn");
          }
        } else {
          setTotalAmountTeaser(e)
        }
        break;
      default:
        break;
    }
  }

  const minInputStake = () => {
    switch (keyBetTabs) {
      case "1":
        setTotalStake(Number(`${minBetStraight}`))
        uAllAmountAddStrightBetsSlip(Number(`${minBetStraight}`))
        break;
      case "2":
        setTotalAmountParlay(Number(`${minBetParlay}`))
        break;
      default:
        break;
    }
  }

  const maxInputStake = async () => {
    switch (keyBetTabs) {
      case "1":
        const ketQua = await getPresentMoney();
        if (ketQua?.success) {
          if (Number(ketQua.data.currentMoney) >= Number(maxBetStraight)) {
            setTotalStake(Number(`${maxBetStraight}`))
            uAllAmountAddStrightBetsSlip(Number(`${maxBetStraight}`))
          } else {
            setTotalStake(Number(`${ketQua.data.currentMoney}`))
            uAllAmountAddStrightBetsSlip(Number(`${ketQua.data.currentMoney}`))
          }
        } else {
          setTotalStake(Number(`${maxBetStraight}`))
          uAllAmountAddStrightBetsSlip(Number(`${maxBetStraight}`))
        }
        break;
      case "2":
        setTotalAmountParlay(Number(`${maxBetParlay}`))
        break;
      default:
        break;
    }
  }

  const handleAllNumberClickNew = (e: number) => {
    switch (keyBetTabs) {
      case "1":
        if (e) {
          if (e <= maxBetStraight) {
            setTotalStake(Number(`${e}`))
            uAllAmountAddStrightBetsSlip(Number(`${e}`))
          } else {
            pushToast(`Total bets minimum is ${minBetStraight} and maximum is ${maxBetStraight}`, "warn");
          }
        } else {
          setTotalStake(e)
          uAllAmountAddStrightBetsSlip(e)
        }
        break;
      case "2":
        if (e) {
          if (e <= maxBetParlay) {
            setTotalAmountParlay(Number(`${e}`))
          } else {
            pushToast(`Total bets minimum is ${minBetParlay} and maximum is ${maxBetParlay}`, "warn");
          }
        } else {
          setTotalAmountParlay(e)
        }
        break;
      case "3":
        if (e) {
          if (e < 10000) {
            setTotalAmountTeaser(Number(`${e}`))
          } else {
            pushToast("The total bet cannot exceed $10,000", "warn");
          }
        } else {
          setTotalAmountTeaser(e)
        }
        break;
      default:
        break;
    }
  }

  const handleAllNumberClick = (e: number) => {
    switch (keyBetTabs) {
      case "1":
        if (totalStake) {
          if (Number(`${totalStake}${e}`) <= maxBetStraight) {
            setTotalStake(Number(`${totalStake}${e}`))
            uAllAmountAddStrightBetsSlip(Number(`${totalStake}${e}`))
          } else {
            // pushToast(`Total bets minimum is ${minBetStraight} and maximum is ${maxBetStraight}`, "warn");
          }
        } else {
          setTotalStake(e)
          uAllAmountAddStrightBetsSlip(e)
        }
        break;
      case "2":
        if (totalAmountParlay) {
          if (Number(`${totalAmountParlay}${e}`) <= maxBetParlay) {
            setTotalAmountParlay(Number(`${totalAmountParlay}${e}`))
          } else {
            // pushToast(`Total bets minimum is ${minBetParlay} and maximum is ${maxBetParlay}`, "warn");
          }
        } else {
          setTotalAmountParlay(e)
        }
        break;
      case "3":
        if (totalAmountTeaser) {
          if (totalAmountParlay < 10000) {
            setTotalAmountTeaser(Number(`${totalAmountTeaser}${e}`))
          } else {
            pushToast("The total bet cannot exceed $10,000", "warn");
          }
        } else {
          setTotalAmountTeaser(e)
        }
        break;
      default:
        break;
    }
  }

  const handleAllDeleteClick = (e: number) => {
    switch (keyBetTabs) {
      case "1":
        setTotalStake(0)
        uAllAmountAddStrightBetsSlip(0)
        break;
      case "2":
        setTotalAmountParlay(0)
        break;
      case "3":
        setTotalAmountTeaser(0)
        break;
      default:
        break;
    }
  }

  const uAllAmountAddStrightBetsSlip = (value: number) => {
    setListAddStrightBetsSlip((prevList) =>
      prevList.map((item) => ({ ...item, amount: value, win: getPiceWinDecimal(value, item.scoreChoose, decimal) }))
    );

    setListBet((prevList) =>
      prevList.map((item) => ({ ...item, amount: value.toString(), win: getPiceWinDecimal(value, item.scoreChoose) }))
    );
  };

  const handleCheckBet = useThrottle(async () => {
    const filteredArray = listAddStrightBetsSlip.filter((item) => (item.isCheck && item.amount !== 0));
    if (filteredArray.length === 0) {
      pushToast("Please select the bet and enter the bet amount!", "warn");
      return
    }
    const updatedArray = filteredArray.map(({ isCheck, amount, ...rest }) => ({
      ...rest,
      amount: String(amount),
    }));
    await setListBet(updatedArray)
    cMoneyBetting(updatedArray, "straight")
    // setShowModalBets(true)
  }, 1000)

  const handleCheckBetParlay = useThrottle(async () => {
    const filteredArray = listParlayBetsSlip.filter((item) => (item.isCheck));
    if (isWarningBetParlay) {
      pushToast("Parlay cannot be placed as some of your legs cannot be combined", "warn");
      return
    }
    if (filteredArray.length <= 1) {
      pushToast("Please choose 2 or more bets!", "warn");
      return
    }
    if (totalAmountParlay === 0) {
      pushToast("Please select the bet amount!", "warn");
      return
    }

    const updatedArray = filteredArray.map(({ isCheck, ...rest }) => ({
      ...rest
    }));
    await setListBetParlay(updatedArray)
    cMoneyBetting(updatedArray, "parlay")
    // setShowModalBets(true)
  }, 1000)

  const handleCheckBetTeaser = useThrottle(async () => {
    const filteredArray = listTeaserBetsSlip.filter((item) => (item.isCheck));
    if (isWarningBetTeaser) {
      pushToast("Teaser cannot be placed as some of your legs cannot be combined", "warn");
      return
    }
    if (filteredArray.length <= 1) {
      pushToast("Please choose 2 or more bets!", "warn");
      return
    }
    if (totalAmountTeaser === 0) {
      pushToast("Please select the bet amount!", "warn");
      return
    }

    const updatedArray = filteredArray.map(({ isCheck, isWarning, ...rest }) => ({
      ...rest
    }));
    await setListBetTeaser(updatedArray)
    cMoneyBetting(updatedArray, "teaser")
    // setShowModalBets(true)
  }, 1000)

  const handleMenuSelect_V2 = async (tournament: string, country: string, icon?: string, type?: string, selectTournamentHandle?: string) => {
    // pushLoading(true);
    setInfoChooseGame({ icon: icon ? icon : "", lable: tournament })
    const ketQua = await homePage_V2Api.listTournamentBetBetMGM("Football", "USA")
    if (ketQua?.success) {
      setTypeListGame('select')
      setListTournamentOfPreMatchBetMGM(ketQua.data)
      // setKeyGameTab('gameOffline');
      if (type && type === "firstTime" && selectTournamentHandle !== "") {
        getBetItemFromMenuSelect([ketQua.data[0]])
      } else {
        let array = ketQua.data.length ? ketQua.data.map((obj: any) => obj.tag.name.value) : []
        if (array.includes(selectTournamentHandle) || array.includes(selectTournament)) {
          if (selectTournamentHandle) {
            getBetItemFromMenuSelect(ketQua.data.filter((obj: any) => (obj.tag.name.value === selectTournamentHandle || selectTournament.includes(obj.tag.name.value))))
          } else {
            getBetItemFromMenuSelect(ketQua.data.filter((obj: any) => ((obj.tag.name.value === selectTournament || selectTournament.includes(obj.tag.name.value)))))
          }
        } else {
          getBetItemFromMenuSelect(ketQua.data)
        }
      }
    } else {
      setListTournamentOfPreMatchBetMGM([])
      pushToast(ketQua?.message, "warn");
    }
    // pushLoading(false);
    setTournamentLive(tournament)
    setCountryLive(country)
    // disconnectSocket(tournament, country)
  }

  const getBetItemFromMenuSelect = useDebounce(async (listTournament: homePage_V2Api.InfoListTournamentBetMGM[]) => {
    // pushLoading(true);
    await listTournament.forEach(async (itemTournament, index) => {
      if (selectTournament.includes(itemTournament?.tag?.name?.value?.toString())) {
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        var ketQua = await homePage_V2Api.prematchBetBetMGM(itemTournament?.tag?.name?.value?.toString(), dateFilter, timeZone)
        if (ketQua?.success) {
          var updatedListArray: any = []
          ketQua.data && ketQua.data.length > 0 && ketQua.data.forEach(element => {
            updatedListArray = [...updatedListArray, element]
          });
          // const updatedList = [...arrayGameSelect.current, ketQua.data]
          arrayGameSelect.current = updatedListArray
        } else {
          pushToast(ketQua?.message, "warn");
        }
      }
    });
    //   pushLoading(false);
  }, 200)

  useEffect(() => {
    if (dateFilter !== "All") {
      if (listDateFilter?.[0]?.value?.toString().slice(0, 10) === dateFilter?.toString().slice(0, 10)) {
        setTotalGame(arrayGameSelect.current.length)
        var filterNFL = arrayGameSelect.current && arrayGameSelect.current.filter((bet: any) => (selectTournament.includes(bet.competition.name.value)) && new Date(bet.startDate).getDate() === new Date().getDate())
        const data = fomatDataHomeBetMGM(filterNFL)
        setCompetitions(data)
      } else {
        setTotalGame(arrayGameSelect.current.length)
        var filterNFL = arrayGameSelect.current && arrayGameSelect.current.filter((bet: any) => (selectTournament.includes(bet.competition.name.value)))
        const data = fomatDataHomeBetMGM(filterNFL)
        setCompetitions(data)
      }
    }
  }, [arrayGameSelect.current])

  const onChange = (key: string) => {

    setKeyBetTabs(key)
  };
  const [activeTabKey, setActiveTabKey] = useState("1");

  const handleTabsChange = (key: string) => {
    setActiveTabKey(key);
  };

  const handleRadioChange = async (value: "gameOnline" | "gameOffline") => {
    setInputFilter('')
    setKeyGameTab(value);
    clearAllStrightBetsSlip()
    if (value === 'gameOnline') {
      setMenu([{
        key: '1',
        label: 'Straight',
        children:
          <>
            <Empty className="pl-11 pr-11 text-center" description='Add selections to your Betslip to get started' />
          </>,
      }])
      // setMenu((data: any) => [...data, {
      //   key: '2',
      //   label: 'Parlay',
      //   children:
      //     <>
      //       <div className="flex items-center rounded-md pr-4 pl-4 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
      //         <WarningOutlined />
      //         <span className="pl-2">2+ selections needed for a Parlay.</span>
      //       </div>
      //       <Empty className="pl-2 pr-2 text-center" description='A Parlay is a bet on two or more teams or selections. You can combine different sports,points, spreads and money lines. To win the parlay all selections mustwin.' />
      //     </>,
      // }])
    } else {
      viewSettings()
    }
  };

  const clearTournament = async () => {
    setListChooseGame([])
    setSelectTournament([])
  }

  const chooseTournamentArray = async (tournament: string, key: number) => {
    //phần web
    if (listChooseGame.indexOf(key) > -1) {
      await setListChooseGame(listChooseGame.filter((i) => (i !== key)))
      await setSelectTournament(selectTournament.filter((i) => (i !== tournament)))
    } else {
      await setListChooseGame((data: any) => [...data, key])
      await setSelectTournament((data: any) => [...data, tournament])
    }
    if (listChooseGame.length) {
      setTypeListGame('homepage')
    } else {
      setTypeListGame('select')
    }
  }


  useMemo(() => {
    const totalInfoGameCR = listOfGamesForTheHomepage.reduce((total, infoGame) => total + infoGame.data.length, 0);
    setTotalGame(totalInfoGameCR)
  }, [listOfGamesForTheHomepage])

  useEffect(() => {
    if (listAddStrightBetsSlip.length) {
      setTotalBet(false)
    }
    else
      setTotalBet(true)

    const totalAmountTmp = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
      return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
    }, 0);
    setTotalAmount(totalAmountTmp)
  }, [listAddStrightBetsSlip])

  useEffect(() => {
    settingsBet()
  }, [])

  useEffect(() => {
    // if (!showModalBill) {
    switch (true) {
      case listChooseBet.length > 0:
        if (keyGameTab === "gameOffline") {
          if (settingBet?.quantity.parlay && (settingBet?.quantity.parlay > listParlayBetsSlip.length || (settingBet?.quantity.parlay === listParlayBetsSlip.length && settingBet?.quantity.parlay - 1 === listChooseBet.length))) {
            aParlayBetsSlip()
            if (checkLengthChooseBetTeaser()) {
              aTeaserBetsSlip()
            }
          } if (listChooseBet.length > 1 && keyBetTabs !== "3" && !updateOdds)
            setKeyBetTabs("2")
        }
        break;
      case listChooseBet.length === 1:
        rParlayBetsSlip(listChooseBet[0].game)
        setTotalOddsParlay(0)
        setTotalTParlay(1)
        setTotalAmountParlay(0)
        if (!updateOdds)
          setKeyBetTabs("1")
        break;

      default:
        setListParlayBetsSlip([])
        setTotalOddsParlay(0)
        setTotalTParlay(1)
        setTotalAmountParlay(0)
        if (!updateOdds)
          setKeyBetTabs("1")
        break;
      // }
    }
  }, [listChooseBet])


  useEffect(() => {
    if (listParlayBetsSlip?.length && keyBetTabs === '2' && listChooseBet.length >= 2) {
      const arr = listParlayBetsSlip.filter(item => item.isCheck === true);
      checkConditionsBetParlay(arr)
    }
  }, [listParlayBetsSlip])

  useEffect(() => {
    if (listTeaserBetsSlip?.length) {
      const arr = listTeaserBetsSlip.filter(item => item.isCheck === true);
      checkConditionsBetTeaser(arr)
    }
  }, [listTeaserBetsSlip])

  useEffect(() => {
    if (!isWarningBetTeaser && listTeaserBetsSlip.length > 1) {
      uPointTeaserBet()
    }
  }, [pointTeaser])

  useEffect(() => {
    if (isHomePage) {
      setShowSpan(true);
      setShowNLF(true);
      setShowMLB(true);
      setIsHomePage(false);
    }
  }, [isHomePage, setIsHomePage]);

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTabKey("1");
    } else {
    }

    switch (location.pathname.replace("/", "")) {
      case "Home":
        setTournaments(itemMenu[0])
        break;
      case "NBA":
        setTournaments(itemMenu[1])
        break;
      case "NFL":
        setTournaments(itemMenu[2])
        break;
      case "MLB":
        setTournaments(itemMenu[3])
        break;
      case "Soccer":
        setTournaments(itemMenu[4])
        break;
      case "NCAAF":
        setTournaments(itemMenu[5])
        break;
      default:
        break;
    }
  }, [location]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 8;

  useEffect(() => {
    if (selectTournament && selectTournament.length !== 0) {
      if (dateFilter === "All") {
        gListAllBetMGM()
      } else {
        handleMenuSelect_V2(tournamentLive, countryLive)
        gListAllBetMGM("count")
      }
    } else {
      setTotalGame(0)
      setCompetitions({})
    }
  }, [selectTournament])

  const heightTournament = dataTournamentSameSport.slice(currentIndex, currentIndex + itemsToShow).length > 4
  const [openDrawerBestSlip, setOpenDrawerBestSlip] = useState(false)

  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    getMinMaxBet()
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        // Large screen (desktop)
        setOffsetTop(44);
      } else {
        // Small screen (mobile)
        setOffsetTop(75);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {

    const checkScreenWidth = () => {
      if (window.innerWidth <= 1365 && openDrawerBestSlip) {
        document.getElementById("body")!.style.overflow = "hidden";
      } else {
        document.getElementById("body")!.style.overflow = "auto";
      }
    };
    checkScreenWidth()
    window.addEventListener("resize", checkScreenWidth);
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, [openDrawerBestSlip])
  return (
    <>
      {
        typeGame !== "soccer" && (
          <Flex gap="middle" wrap="wrap" className='h-full'>
            <Layout >
              <div className='bg-[#131620] xs:h-full xs:w-full xs:overflow-y-auto   sm:h-full md:h-full lg:overflow-y-hidden lg:!min-h-[100vh] pt-[34px] pb-[34px] pl-1 text-center flex flex-col  relative'
              >
                <div className="fixed z-[9999] w-[100vw] flex justify-center top-10 xs:left-0">
                  <ToastGlobal />
                </div>
                {window.innerWidth < 1200 && (
                  <ModalBetSlip_V2
                    openDrawer={openDrawerBestSlip}
                    closeDrawer={() => setOpenDrawerBestSlip(false)}
                    tab='NFL'
                    menu={menu}
                    keyGameTab={keyGameTab}
                    keyBetTabs={keyBetTabs}
                    chooseElement=''
                    totalStake={totalStake}
                    pointTeaser={pointTeaser}
                    listAddStrightBetsSlip={listAddStrightBetsSlip}
                    listParlayBetsSlip={listParlayBetsSlip}
                    listTeaserBetsSlip={listTeaserBetsSlip}
                    listChooseBet={listChooseBet}

                    arrayActiveItem={arrayAcitveItem}
                    decimal={decimal}
                    betting={betting}

                    setKeyBetTabs={setKeyBetTabs}
                    rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                    clearAll={clearAllStrightBetsSlip}
                    setArrayActiveItem={setArrayActiveItem}
                    setListaddStrightBetsSlip={setListAddStrightBetsSlip}
                    setListParlayBetsSlip={setListParlayBetsSlip}
                    setListTeaserBetsSlip={setListTeaserBetsSlip}
                    setTotalStake={setTotalStake}
                    setPointTeaser={setPointTeaser}

                    showTotalBet={showTotalBet}
                    showTotalStake={showTotalStake}
                    showTotalStake_V2={showTotalStake_V2}

                    isWarningBetParlay={isWarningBetParlay}
                    totalOddsParlay={totalOddsParlay}
                    totalTParlay={totalTParlay}
                    totalAmountParlay={totalAmountParlay}
                    isWarningBetTeaser={isWarningBetTeaser}
                    totalOddsTeaser={totalOddsTeaser}
                    totalAmountTeaser={totalAmountTeaser}
                    numberStake={totalStake}

                    handleClick={handleAllClick}
                    handleNumberClick={handleAllNumberClick}
                    handleChangeInput={handleAllNumberClickNew}
                    minInputStake={minInputStake}
                    maxInputStake={maxInputStake}
                    handleDeleteClick={handleAllDeleteClick}
                    showHideTotalStake={showHideTotalStake}
                    showHideTotalStake_V2={showHideTotalStake_V2}
                    handleCheckQuantityMoneyStraight={handleCheckQuantityMoneyStraight}
                    handleCheckBet={handleCheckBet}
                    handleCheckBetParlay={handleCheckBetParlay}
                    handleCheckBetTeaser={handleCheckBetTeaser}
                    hiddenBetPlace={hiddenBetPlace}
                    minBet={keyBetTabs === "1" ? minBetStraight : minBetParlay}
                    maxBet={keyBetTabs === "1" ? maxBetStraight : maxBetParlay}
                  />
                )}

                <ModalWarning
                  showModal={showModalWarning}
                  setShowModal={setShowModalWarning}
                />
                <ModalTourName_V2
                  dataTournamentSameSport={dataTournamentSameSport}
                  showModalTourName={showModalTourName_V2}
                  listChooseTournament={listChooseGame}
                  chooseTournament={chooseTournamentArray}
                  closeModal={() => setShowModalTourName_V2(false)}
                  clearTournament={clearTournament}
                />
                <DrawerTourNameMGMMobile
                  dataTournamentSameSport={dataTournamentSameSport}
                  openDrawer={showDrawerTourNameMGMMobile}
                  listChooseTournament={listChooseGame}
                  chooseTournament={chooseTournamentArray}
                  closeDrawer={() => setShowDrawerTourNameMGMMobile(false)}
                  clearTournament={clearTournament}
                />
                <HeadNavi_V2
                  openDrawer={openDrawerBestSlip}
                  closeDrawer={() => setOpenDrawerBestSlip(false)}
                  tournaments={tournaments}
                  setTournaments={handelChooseTournaments}
                />
                <div className='flex flex-col pr-1 xs:w-full xl:w-[80%]'>
                  <div className=' bg-[#131620] xs:pt-10 xl:pt-2  w-full xs:hidden md:flex '>
                    <Banner
                      sportId={2}
                    />
                  </div>

                  <div className='w-full   xs:pt-9 md:pt-0'>
                    <div className={`xs:hidden xl:flex flex-col justify-between xl:w-[20%]  align-top bottom-0 fixed z-20 right-0 top-[44px]`}>
                      <div className='flex flex-col h-full'>
                        <div className={`flex flex-col justify-between border border-[#202531]  bg-[#131620]   w-full ${listAddStrightBetsSlip.length === 0 ? 'h-full' : ''}`}>
                          <div className={`flex flex-col  ${listAddStrightBetsSlip.length === 0 ? 'h-full' : ''}`}>
                            <span className='flex justify-between pb-1'>
                              <div className="flex items-center gap-2">
                                <img src={start} alt="Crown" />
                                <p className="font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-sm text-white uppercase">Bet Slip</p>
                              </div>
                              <div className='flex items-center gap-1 pr-3'>
                                <div className={`${listAddStrightBetsSlip.length > 0 || listAddStrightBetsSlip.length > 0 ? 'flex items-center gap-1' : 'hidden '}`}
                                  onClick={clearAllStrightBetsSlip}
                                >
                                  <img src={Trash} alt="" />
                                  <span className='text-xs text-white uppercase fw-[500]'>Remove All</span>
                                </div>

                                <span className={`h-5 w-5 rounded-[50%]   text-xs flex items-center justify-center ${listAddStrightBetsSlip.length === 0 ? 'bg-[#202531] text-white' : 'bg-[#D4B962] text-[#131620]'}`}>
                                  {keyBetTabs === '1' ? listAddStrightBetsSlip.length.toString() : listParlayBetsSlip.length.toString()}
                                </span>
                              </div>
                            </span>
                            <div className={`tab-bet-soccer tab-bet-mgm ${listAddStrightBetsSlip.length === 0 ? '' : 'hidden'}`}>
                              <Tabs items={menu}
                                onChange={onChange}
                                activeKey={keyBetTabs}
                              />
                              <EmtyBetSlip activeKey={keyBetTabs} />
                            </div>
                          </div>

                          <div className={`${listAddStrightBetsSlip.length === 0 ? 'border-t border-[#202531] flex flex-col gap-3 px-6 pb-3' : 'hidden'}`}>
                            <div className='flex justify-between pt-3'>
                              <div className='flex flex-col gap-4'>
                                <span className='lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base fw-[400] text-[#E4E7F1]'>
                                  Total stake
                                </span>
                                <span className='lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base fw-[400] text-[#E4E7F1]'>-</span>
                              </div>
                              <div className='flex flex-col gap-4'>
                                <span className='lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base fw-[400] text-[#E4E7F1]'>
                                  Total win
                                </span>
                                <span className='lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base fw-[400] text-[#E4E7F1]'>-</span>
                              </div>
                            </div>
                            <button className={`font-bold-GT-America rounded-[2px]  uppercase w-full  py-[10px] ${listAddStrightBetsSlip.length > 0 ? 'bg-[#D4B962] text-[#20273D]' : 'bg-[#E4E7F1] text-[#666E97]'}`}>
                              Place bet
                            </button>
                          </div>

                        </div>
                        <div className='flex flex-col  bg-white  betslip-mgm border-l border-l-[#202531] border-r border-r-[#202531]'>
                          <div
                            hidden={!listAddStrightBetsSlip.length}
                            className={` overflow-hidden ${listAddStrightBetsSlip.length === 1 ? "h-[calc(100vh-350px)]" : "h-[calc(100vh-320px)]"}`}
                          >
                            <BetTabs
                              tab='NFL'
                              menu={menu}
                              keyGameTab={keyGameTab}
                              keyBetTabs={keyBetTabs}
                              chooseElement=''
                              totalStake={totalStake}
                              pointTeaser={pointTeaser}
                              listAddStrightBetsSlip={listAddStrightBetsSlip}
                              listParlayBetsSlip={listParlayBetsSlip}
                              listTeaserBetsSlip={listTeaserBetsSlip}
                              listChooseBet={listChooseBet}

                              arrayActiveItem={arrayAcitveItem}
                              decimal={decimal}
                              betting={betting}

                              setKeyBetTabs={setKeyBetTabs}
                              rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                              clearAll={clearAllStrightBetsSlip}
                              setArrayActiveItem={setArrayActiveItem}
                              setListaddStrightBetsSlip={setListAddStrightBetsSlip}
                              setListParlayBetsSlip={setListParlayBetsSlip}
                              setListTeaserBetsSlip={setListTeaserBetsSlip}
                              setTotalStake={setTotalStake}
                              setPointTeaser={setPointTeaser}
                            />
                          </div>
                        </div>
                      </div>


                      <TotalStakeComponent
                        keyBetTabs={keyBetTabs}
                        showTotalBet={showTotalBet}
                        showTotalStake={showTotalStake}
                        showTotalStake_V2={showTotalStake_V2}

                        isWarningBetParlay={isWarningBetParlay}
                        totalOddsParlay={totalOddsParlay}
                        totalTParlay={totalTParlay}
                        totalAmountParlay={totalAmountParlay}
                        isWarningBetTeaser={isWarningBetTeaser}
                        totalOddsTeaser={totalOddsTeaser}
                        totalAmountTeaser={totalAmountTeaser}
                        numberStake={totalStake}
                        listAddStrightBetsSlip={listAddStrightBetsSlip}
                        listParlayBetsSlip={listParlayBetsSlip}
                        listTeaserBetsSlip={listTeaserBetsSlip}

                        handleClick={handleAllClick}
                        handleNumberClick={handleAllNumberClick}
                        handleChangeInput={handleAllNumberClickNew}
                        minInputStake={minInputStake}
                        maxInputStake={maxInputStake}
                        handleDeleteClick={handleAllDeleteClick}
                        showHideTotalStake={showHideTotalStake}
                        showHideTotalStake_V2={showHideTotalStake_V2}
                        handleCheckQuantityMoneyStraight={handleCheckQuantityMoneyStraight}
                        handleCheckBet={handleCheckBet}
                        handleCheckBetParlay={handleCheckBetParlay}
                        handleCheckBetTeaser={handleCheckBetTeaser}
                        hiddenBetPlace={hiddenBetPlace}
                        minBet={keyBetTabs === "1" ? minBetStraight : minBetParlay}
                        maxBet={keyBetTabs === "1" ? maxBetStraight : maxBetParlay}
                      />

                    </div>
                    <div className='w-full  xs:pl-0 xl:pl-[110px]'>
                      {
                        activeTabKey !== "2" && (
                          <div>
                            <Affix offsetTop={offsetTop}>
                              <div>
                                <div className='w-full'>
                                  <div className='bg-[#131620]'>
                                    <div className='flex flex-col rounded-2 rounded-[6px]'>
                                      <div className='bg-gradient-A98F34 flex justify-between items-center h-[38px] px-3'>
                                        <div className="xs:flex xl:hidden items-center gap-2" onClick={() => setShowDrawerTourNameMGMMobile(true)}>
                                          <span className='xs:text-sm  xl2:text-base  font-bold-GT-America  text-white uppercase '>Game list</span>
                                          <img src={filter_icon} alt="" className=''
                                          />
                                        </div>
                                        <span className='xs:hidden xl:block xs:text-sm  xl2:text-base  font-bold-GT-America  text-white uppercase '>Game list</span>
                                        <div className="flex items-center ">
                                          <button
                                            className=' xl:flex items-center gap-2 lg:text-xs xl2:text-sm  xs:hidden  xl:mb-1 uppercase text-[#E4E7F1] border border-[#202531] bg-[#131620] rounded-md px-4 py-1 hover:bg-[#DBBF64] hover:text-[#131620]'
                                            onClick={() => setShowModalTourName_V2(true)}
                                          >
                                            <FilterOutlined />
                                            tournament
                                          </button>
                                          <div className='flex items-center gap-[10px] pl-[10px] border-l border-[#202531] ' onClick={resetBet}>
                                            <img src={reload} alt="" className="h-[20px]" />

                                            {time && time > 0
                                              ?
                                              (<span className='text-white'>{time}</span>)
                                              :
                                              (<span className='xs:text-xs lg:text-[10px] xl:text-xs xl2:text-sm fw-[400]  text-white uppercase'>Reload</span>)}
                                          </div>
                                        </div>

                                      </div>
                                      <DayTimeMLB
                                        listDateFilterSoccer={listDateFilter}
                                        dateFilterSoccer={dateFilter}
                                        changeDateFilter={changeDateFilter}
                                        typeListGame={typeListGame}
                                        totalAllGame={totalAllGame}

                                        keyGameTab={keyGameTab}
                                        totalGameOnLine={totalGameLive}
                                        onRadioChange={handleRadioChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className='xs:hidden xl:block  rounded-lg border border-[#414961] overflow-hidden '>
                                  <div className='pr-2 flex gap-3  w-full justify-end bg-[#131620]'>
                                    <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] xs:w-1/2 xl:w-[250px] xl3:w-[400px]'>
                                      <div className='w-full border-b border-[#282D39] py-2'>
                                        <span className='font-bold-GT-America text-sm  text-[#E4E7F1] uppercase'>Full time</span>
                                      </div>
                                      <div className='grid grid-cols-3 gap-2 py-2'>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>SPREAD</span>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>TOTAL</span>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>MONEY LINE</span>
                                      </div>
                                    </div>

                                    <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] xs:w-1/2 lg:w-[250px]  xl3:w-[400px]'>
                                      <div className='w-full border-b border-[#282D39] py-2'>
                                        <span className='font-bold-GT-America text-sm  text-[#E4E7F1] uppercase'>First half</span>
                                      </div>
                                      <div className='grid grid-cols-3 gap-2 py-2'>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>SPREAD</span>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>TOTAL</span>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>MONEY LINE</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Affix>
                            <div className='w-full  h-full xs:pb-10 xl:pb-0'>
                              <div className='w-full flex flex-col  rounded-sm gap-3'>
                                {
                                  keyGameTab === "gameOffline" && (
                                    <div className='flex flex-col mt-2 rounded-[8px] w-full '>
                                      {Object.keys(competitions).map((competitionName, index) => (
                                        <div
                                          key={index}
                                        >
                                          <Collapse
                                            key={`homepage-${competitionName}`}
                                            defaultActiveKey={[index]}
                                            expandIconPosition='end'
                                            className=' w-full collapse-league_V2 '

                                            expandIcon={
                                              (expanded) => (
                                                expanded.isActive ? (
                                                  <div className='w-full flex justify-center gap-2 '>
                                                    <span className="uppercase xs:text-[10px]  xl:text-xs fw-[400] !text-[#E4E7F1]">Collapse</span>
                                                    <img src={Collapse_img} alt="" />
                                                  </div>
                                                ) : (
                                                  <div className='w-full flex justify-center gap-2 '>
                                                    <span className="uppercase xs:text-[10px]  xl:text-xs fw-[400] !text-[#E4E7F1]">expand</span>
                                                    <img src={Expand} alt="" />
                                                  </div>
                                                )
                                              )
                                            }
                                            items={[{
                                              key: index,
                                              label: <div className='text-[#D4B962] fw-[500] lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base flex items-center uppercase xs:text-[10px]'>
                                                <div className='h-[21px] bg-[#42391A] rounded-tl-md rounded-bl-md w-[10px]'>
                                                </div>
                                                {competitionName}
                                              </div>,
                                              children:
                                                <>
                                                  <div className="flex flex-col  overflow-x-hidden ">
                                                    {
                                                      competitions[competitionName].map((item, itemIndex) => (
                                                        <GameDeTail_V2
                                                          key={itemIndex}
                                                          tournament={competitionName}
                                                          item={item}
                                                          listChooseBet={listChooseBet}
                                                          chooseBet={checkAddStrightBetsSlip}
                                                          decimal={decimal}
                                                          inputFilter={inputFilter}
                                                          sport={"NFL"}
                                                        />
                                                      ))
                                                    }
                                                  </div>

                                                </>
                                            }]}
                                          />
                                        </div>

                                      ))
                                      }
                                      <div>
                                        {
                                          checkNoData(Object.keys(competitions), 'pb-4')
                                        }
                                      </div>
                                    </div>
                                  )
                                }
                                {
                                  keyGameTab === "gameOnline" && (
                                    <div className='flex flex-col mt-2 rounded-[8px] w-full '>
                                      {
                                        Object.keys(competitionsLive).map((competitionName, index) => (
                                          <div
                                            key={index}
                                          >
                                            <Collapse
                                              key={`Live-${competitionName}`}
                                              defaultActiveKey={[`Live-${index}`]}
                                              expandIconPosition='end'
                                              className=' w-full collapse-league_V2'
                                              expandIcon={
                                                (expanded) => (
                                                  expanded.isActive ? (
                                                    <div className='w-full flex justify-center gap-2 '>
                                                      <span className="uppercase xs:text-[10px] xl:text-xs fw-[400] !text-[#E4E7F1]">Collapse</span>
                                                      <img src={Collapse_img} alt="" />
                                                    </div>
                                                  ) : (
                                                    <div className='w-full flex justify-center gap-2 '>
                                                      <span className="uppercase xs:text-[10px] xl:text-xs fw-[400] !text-[#E4E7F1]">expand</span>
                                                      <img src={Expand} alt="" />
                                                    </div>
                                                  )
                                                )
                                              }
                                              items={[{
                                                key: `Live-${index}`,
                                                label: <div className='text-[#D4B962] fw-[500] text-base flex items-center uppercase'>
                                                  <div className='h-[21px] bg-[#42391A] rounded-tl-md rounded-bl-md w-[10px]'>
                                                  </div>
                                                  {competitionName}
                                                </div>,
                                                children:
                                                  <>
                                                    <div className="flex flex-col gap-2 overflow-x-hidden">
                                                      {
                                                        competitionsLive[competitionName].map((item, itemIndex) => (
                                                          <GameLiveDeTail_V2
                                                            key={itemIndex}
                                                            tournament={competitionName}
                                                            item={item}
                                                            competitionsLiveOld={competitionsLiveOld}
                                                            listChooseBet={listChooseBet}
                                                            chooseBet={checkAddStrightBetsSlip}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            decimal={decimal}
                                                            inputFilter={inputFilter}
                                                            sport="NFL"
                                                          />
                                                        ))
                                                      }
                                                    </div>

                                                  </>
                                              }]}
                                            />
                                          </div>

                                        ))
                                      }
                                      <div>
                                        {
                                          checkNoData(Object.keys(competitionsLive), 'pb-4')
                                        }
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {
                        activeTabKey !== "1" && (
                          <div>
                            {
                              showNLF && (
                                <div className='mt-4'>
                                  <div className='flex justify-between'>
                                    <div className='flex flex-col pl-4'>
                                      <span>
                                        <b>2024/2025 NFL - Futures</b>
                                      </span>
                                      <span className='text-left text-[#898989]'>
                                        2/12/24 - 6:30 AM
                                      </span>
                                    </div>
                                    <div>
                                      <span className='text-[#7EA2FF]'>All Bets</span>
                                    </div>
                                  </div>
                                  <FutureTabs />
                                </div>
                              )
                            }
                            {
                              showMLB && (
                                <div className='mt-4'>
                                  <MLBFuture />
                                </div>
                              )}
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="xs:block xl:hidden">
                  <FooterMobile
                    countBetslip={listChooseBet.length}
                    setOpenDrawerBestSlip={() => setOpenDrawerBestSlip(true)}
                  />
                </div>
              </div>
            </Layout>
          </Flex >

        )}
    </>
  )
}
export default MLBComponent_V2;